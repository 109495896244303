define("nimbus/pods/components/x-breadcrumb/parent/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {String}
     */
    tagName: "span"
  });
});