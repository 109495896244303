define("nimbus/mirage/factories/user", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    username: function username() {
      return _emberCliMirage.faker.internet.email();
    },
    first_name: function first_name() {
      return _emberCliMirage.faker.name.firstName();
    },
    last_name: function last_name() {
      return _emberCliMirage.faker.name.lastName();
    },
    last_login: function last_login() {
      return _emberCliMirage.faker.date.recent();
    },
    uuid: function uuid() {
      return _emberCliMirage.faker.random.uuid();
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.recent();
    },
    self_url: function self_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    }
  });
});