define("nimbus/tailwind/config/letter-spacing", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    tight: "-0.05em",
    normal: "0",
    wide: "0.05em"
  };
});