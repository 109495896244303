define("nimbus/pods/admin/team/invites/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    actions: {
      demoRouteReset: function demoRouteReset() {
        // Left blank
      }
    }
  });
});