define("nimbus/models/model", ["exports", "ember-data/attr", "ember-data/model"], function (exports, _attr, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The date and time the model was created on the server.
     *
     * @type {Date}
     */
    createdAt: (0, _attr.default)("date"),

    /**
     * The date and time the model was last updated on the server.
     *
     * @type {Date}
     */
    updatedAt: (0, _attr.default)("date"),

    /**
     * The location of the model on the server.
     *
     * @type {String}
     */
    selfUrl: (0, _attr.default)("string"),

    /**
     * The URL to send a `PUT` request to when updating the model.
     *
     * @type {String}
     */
    updateUrl: (0, _attr.default)("string"),

    /**
     * The URL to send a `DELETE` request to when deleting the model.
     *
     * @type {String}
     */
    deleteUrl: (0, _attr.default)("string"),

    /**
     * Indicates if the model can be refreshed.
     *
     * @type {Boolean}
     */
    canRefresh: Ember.computed.notEmpty("selfUrl"),

    /**
     * Indicates if the model can be updated.
     *
     * @type {Boolean}
     */
    canUpdate: Ember.computed.notEmpty("updateUrl"),

    /**
     * Indicates if the model can be deleted.
     *
     * @type {Boolean}
     */
    canDelete: Ember.computed.notEmpty("deleteUrl")
  });
});