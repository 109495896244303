define("nimbus/pods/components/x-popout/item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {String}
     */
    tagName: "",

    actions: {
      clickPopper: function clickPopper() {
        this.onClick();
      },
      hoverPopper: function hoverPopper() {
        this.onEnter();
      },
      leavePopper: function leavePopper() {
        this.onLeave();
      }
    }
  });
});