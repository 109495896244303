define("nimbus/mirage/factories/team", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.lorem.word();
    },
    logo: function logo() {
      return _emberCliMirage.faker.image.city();
    },
    domain: function domain() {
      return _emberCliMirage.faker.lorem.word();
    },
    uuid: function uuid() {
      return _emberCliMirage.faker.random.uuid();
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.recent();
    },
    self_url: function self_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    }
  });
});