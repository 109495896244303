define("nimbus/pods/admin/create-post/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.loadData.perform();
    },


    actions: {
      willTransition: function willTransition(transition) {
        this.controller.checkUploading(transition);
      },
      demoRouteReset: function demoRouteReset() {
        var controller = Ember.get(this, "controller");
        this.setupController(controller);
      }
    }
  });
});