define("nimbus/pods/components/x-enclosure/component", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = Ember.Component.extend({
    /**
     * The index of the current enclosure.
     *
     * @type {Number}
     */
    current: 1,

    /**
     * The enclosures to display.
     *
     * @type {Array.<Object>}
     */
    enclosures: null,

    /**
     * An action invoked when the displayed enclosure changes.
     *
     * @type {Function}
     */
    onChange: null,

    /**
     * @private
     * @type {Boolean}
     */
    isFirstLoaded: false,

    /**
     * @private
     * @type {Boolean}
     */
    showPlaceholder: Ember.computed.not("isFirstLoaded"),

    /**
     * @private
     * @type {Boolean}
     */
    hasMultiple: Ember.computed.gt("enclosures.length", 1),

    /**
     * @private
     * @type {Array.<Object>}
     */
    remainingEnclosures: Ember.computed("enclosures.[]", function () {
      return this.enclosures.slice(1);
    }),

    /**
     * @private
     * @type {String}
     */
    downloadUrl: Ember.computed("enclosures.@each.url", "current", function () {
      return HOST + "/download?url=" + this.enclosures[this.current - 1].url;
    }),

    screenUrl: Ember.computed("enclosures.@each.url", "current", function () {
      return HOST + "/proxy?url=" + this.enclosures[this.current - 1].url;
    }),

    /**
     * @private
     * @type {Object}
     */
    actions: {
      onLoad: function onLoad() {
        var enclosure = this.enclosures[0];

        Ember.set(this, "isFirstLoaded", true);
        (this.onChange || function () {})(enclosure);
      },


      /**
       * @private
       * @function
       * @returns {void}
       */
      onSlide: function onSlide(_ref) {
        var _this = this;

        var index = _ref.index;

        var enclosure = this.enclosures[index];
        var next = index + 1;

        Ember.run.later(function () {
          return (_this.onChange || function () {})(enclosure);
        }, 70);
        Ember.run.later(function () {
          return Ember.set(_this, "current", next);
        });
      }
    },

    mouseEnter: function mouseEnter() {
      Ember.set(this, "showDownload", true);
    },
    mouseLeave: function mouseLeave() {
      Ember.set(this, "showDownload", false);
    }
  });
});