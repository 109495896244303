define("nimbus/pods/components/x-guru-option/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    click: function click() {
      /* eslint-disable */
      this.sendAction("onClick", Ember.get(this, "option"));
      /* eslint-enable */
    }
  });
});