define("nimbus/pods/components/x-report/links/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Links = function (_EmberComponent) {
    _inherits(Links, _EmberComponent);

    function Links() {
      _classCallCheck(this, Links);

      return _possibleConstructorReturn(this, (Links.__proto__ || Object.getPrototypeOf(Links)).apply(this, arguments));
    }

    _createClass(Links, [{
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        var _this2 = this;

        var view = Object.keys(Ember.getWithDefault(this, "model", {})).map(function (key) {
          return {
            url: _this2.model[key].url,
            clicks: _this2.model[key].clicks,
            shares: _this2.model[key].shares,
            twitter: Ember.getWithDefault(_this2.model[key].platforms, "twitter", 0),
            linkedin: Ember.getWithDefault(_this2.model[key].platforms, "linkedin", 0),
            facebook: Ember.getWithDefault(_this2.model[key].platforms, "facebook", 0),
            instagram: Ember.getWithDefault(_this2.model[key].platforms, "instagram", 0)
          };
        });

        Ember.set(this, "_view", view);
      }
    }]);

    return Links;
  }(Ember.Component);

  exports.default = Links;
});