define("nimbus/pods/onboardingData", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = [{
    key: "created_groups",
    code: "BaA33PTTwkR",
    title: "component.x-onboarding.list.titles.groups",
    description: "component.x-onboarding.list.descriptions.groups",
    time: 3,
    completed: false
  }, {
    key: "explored_the_app",
    code: "cKjQEIazwGQ",
    title: "component.x-onboarding.list.titles.app",
    description: "component.x-onboarding.list.descriptions.app",
    time: 20,
    completed: false
  }, {
    key: "first_posted",
    code: "K4fhiajE2QX",
    title: "component.x-onboarding.list.titles.post",
    description: "component.x-onboarding.list.descriptions.post",
    time: 2,
    completed: false
  }, {
    key: "has_themes",
    code: "Rgg0spKKV7P",
    title: "component.x-onboarding.list.titles.themes",
    description: "component.x-onboarding.list.descriptions.themes",
    time: 5,
    completed: false
  }, {
    key: "invited_users",
    code: "cDhpcSk1j95",
    title: "component.x-onboarding.list.titles.users",
    description: "component.x-onboarding.list.descriptions.users",
    time: 120,
    completed: false
  }];
});