define("nimbus/models/core/team-theme", ["exports", "ember-data/attr", "ember-data/model"], function (exports, _attr, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The logo used by the theme.
     *
     * @type {String}
     */
    logo: (0, _attr.default)("string"),

    /**
     * The default text color of the theme.
     *
     * @type {String}
     */
    color: (0, _attr.default)("string"),

    /**
     * The default font stack used by the theme.
     *
     * @type {String}
     */
    fonts: (0, _attr.default)("string"),

    /**
     * The default background color used by the theme.
     *
     * @type {String}
     */
    backgroundColor: (0, _attr.default)("string"),

    /**
     * The default primary color of the theme.
     *
     * @type {String}
     */
    primaryColor: (0, _attr.default)("string"),

    /**
     * Indicates whether this theme is a whitelabel theme.
     *
     * @type {Boolean}
     */
    whitelabel: (0, _attr.default)("boolean")
  });
});