define("nimbus/models/content/source", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The name of the content type.
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    /**
     * The type of the content source.
     *
     * @type {String}
     */
    type: (0, _attr.default)("string"),

    /**
     * The member that is the content source (if any).
     *
     * @type {String}
     */
    member: (0, _relationships.belongsTo)("core/member", { async: false })
  });
});