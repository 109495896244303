define("nimbus/pods/components/x-switcher/component", ["exports", "@ember-decorators/service", "@ember-decorators/component", "@ember-decorators/object"], function (exports, _service, _component, _object) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _class, _descriptor;

  var Switcher = (_dec = (0, _component.className)("is-open"), _dec2 = (0, _object.computed)("isOpen"), _dec3 = (0, _object.computed)("memberships.@each.team"), _dec4 = (0, _object.computed)("_teams.@each.{name,domain}", "context.domain", "search"), (_class = function (_EmberComponent) {
    _inherits(Switcher, _EmberComponent);

    function Switcher() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, Switcher);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Switcher.__proto__ || Object.getPrototypeOf(Switcher)).call.apply(_ref, [this].concat(args))), _this), _initDefineProp(_this, "context", _descriptor, _this), _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Switcher, [{
      key: "willClose",
      value: function willClose() {
        if (this.onClose instanceof Function) {
          this.onClose();
        }
      }
    }, {
      key: "_isOpen",
      get: function get() {
        return this.isOpen;
      }
    }, {
      key: "_teams",
      get: function get() {
        return this.memberships.filter(function (member) {
          return !member.ghost;
        }).map(function (m) {
          return m.team;
        }).filter(function (t) {
          return t.isUnlocked;
        });
      }
    }, {
      key: "_filtered",
      get: function get() {
        var _this2 = this;

        var result = [[], []];

        var pattern = new RegExp(this.search, "gi");
        var teams = !this.search || this.search.length <= 0 ? this._teams.map(function (team) {
          return {
            team: team,
            isActive: team.domain === _this2.context.domain
          };
        }) : this._teams.filter(function (t) {
          return t.name.match(pattern) || t.domain.match(pattern);
        }).map(function (team) {
          return {
            team: team,
            isActive: team.domain === _this2.context.domain
          };
        });

        teams.forEach(function (team, index) {
          result[index % 2].pushObject(team);
        });

        return result;
      }
    }]);

    return Switcher;
  }(Ember.Component), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "context", [_service.service], {
    enumerable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "_isOpen", [_dec, _dec2], Object.getOwnPropertyDescriptor(_class.prototype, "_isOpen"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_teams", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "_teams"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "_filtered", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "_filtered"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willClose", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willClose"), _class.prototype)), _class));
  exports.default = Switcher;
});