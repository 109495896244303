define("nimbus/adapters/core/user", ["exports", "nimbus/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @type {String}
     */
    namespace: null
  });
});