define("nimbus/pods/strategywizard/role/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @private
     * @type {Number}
     */
    roles: 7,

    /**
     * @private
     * @type {Array<String>}
     */
    roleSelect: Ember.computed(function () {
      var counter = 0;
      var array = [];

      while (counter < this.roles) {
        array.push(this.intl.t("route.strategywizard.role.form.role.options." + counter));
        counter++;
      }

      array.sort();

      return array;
    }),

    /**
     * @private
     * @type {Boolean}
     */
    formIsValid: Ember.computed("model.role", function () {
      return this.model.role.length;
    })
  });
});