define("nimbus/pods/message/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {SecureMediaService}
     */
    securemedia: Ember.inject.service(),

    isVideo: Ember.computed("model.attachment.type", function () {
      return this.model.attachment.type === "video";
    }),

    isImage: Ember.computed("model.attachment.type", function () {
      return this.model.attachment.type === "image";
    }),

    isLink: Ember.computed("model.attachment.type", function () {
      return this.model.attachment.type === "link";
    }),

    host: Ember.computed("model.attachment.url", function () {
      if (this.isLink) {
        var link = document.createElement("a");
        link.href = this.model.attachment.url;

        return link.hostname;
      }
    }),

    processed: Ember.computed("model", function () {
      var processed = this.linkify(this.model.body, function (url) {
        return "<a href=\"" + url + "\" target=\"_blank\">" + url + "</a>";
      });

      /* eslint-disable */
      // prettier-ignore
      var newline = /(?:\r\n|\r|\n)/g;
      /* eslint-enable */

      return Ember.String.htmlSafe(processed.replace(newline, "<br />"));
    }),

    attachment: Ember.computed("model", function () {
      var url = this.isVideo || this.isImage ? this.model.attachment.url : this.model.attachment.image;

      var attachment = {
        url: this.model.attachment.url,
        title: this.model.attachment.title,
        media: this.securemedia.secure(url)
      };

      return attachment;
    }),

    linkify: function linkify(text, callback) {
      var protocol = "(?:(?:[a-z]+:)?//)";
      var auth = "(?:\\S+(?::\\S*)?@)?";
      var host = "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)";
      var domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";
      var tld = "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?";
      var port = "(?::\\d{2,5})?";
      var path = "(?:[/?#][^\\s']*)?";
      var regex = "(?:" + protocol + "|www\\.)" + auth + "(?:localhost|" + host + domain + tld + ")" + port + path;
      var pattern = new RegExp(regex, "ig");

      return (text || "").replace(pattern, function (match) {
        return callback(match);
      });
    }
  });
});