define("nimbus/pods/components/x-popover/component", ["exports", "popper.js"], function (exports, _popper) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Popover = function (_EmberComponent) {
    _inherits(Popover, _EmberComponent);

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */


    /**
     * @private
     * @type {Popper}
     */


    /**
     * Indicates if the popover has an arrow.
     *
     * @type {Boolean}
     */


    /**
     * The position of the popover content.
     *
     * @type {String}
     */
    function Popover() {
      _classCallCheck(this, Popover);

      return _possibleConstructorReturn(this, (Popover.__proto__ || Object.getPrototypeOf(Popover)).apply(this, arguments));
    }

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */


    /**
     * @private
     * @type {Boolean}
     */


    /**
     * Indicates if the popover has a border.
     *
     * @type {Boolean}
     */


    /**
     * Indicates if the popper is visible.
     *
     * @type {Boolean}
     */

    /**
     * Indicates the mode of the popover. If set to anything other than `hover`,
     * the popover can be controlled with the `isVisible` property.
     *
     * @type {String}
     */


    _createClass(Popover, [{
      key: "mouseEnter",
      value: function mouseEnter() {
        if (this.mode === "hover") {
          Ember.set(this, "isOpen", true);
        }
      }
    }, {
      key: "mouseLeave",
      value: function mouseLeave() {
        if (this.mode === "hover") {
          Ember.set(this, "isOpen", false);
        }
      }
    }, {
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        var _this2 = this;

        if (this.mode === null || this.mode === undefined) {
          Ember.set(this, "mode", "hover");
        }

        if (this.position === null || this.position === undefined) {
          Ember.set(this, "position", "top");
        }

        if (this.isOpen === null || this.isOpen === undefined) {
          Ember.set(this, "isOpen", false);
        }

        if (this.hasArrow === null || this.hasArrow === undefined) {
          Ember.set(this, "hasArrow", true);
        }

        if (this.hasBorder === null || this.hasBorder === undefined) {
          Ember.set(this, "hasBorder", true);
        }

        if (this.popper) {
          Ember.run(function () {
            _this2.popper.options.placement = _this2.position;

            _this2.popper.scheduleUpdate();
          });
        }
      }
    }, {
      key: "didInsertElement",
      value: function didInsertElement() {
        var _this3 = this;

        var placement = this.position;


        var popper = Ember.run(function () {
          var selector = "#" + _this3.elementId + "__content";

          var target = _this3.element.querySelector(":not(" + selector + "):first-child");
          var content = _this3.element.querySelector(selector);

          var onCreate = function onCreate(_ref) {
            var placement = _ref.placement;

            Ember.set(_this3, "hasPopper", true);
            _this3.updatePlacement(placement);
          };

          var onUpdate = function onUpdate(_ref2) {
            var placement = _ref2.placement;
            return _this3.updatePlacement(placement);
          };

          return target && content ? new _popper.default(target, content, { placement: placement, onCreate: onCreate, onUpdate: onUpdate }) : null;
        });

        Ember.set(this, "popper", popper);
      }
    }, {
      key: "didRender",
      value: function didRender() {
        if (this.popper) {
          this.popper.scheduleUpdate();
        }
      }
    }, {
      key: "willDestroyElement",
      value: function willDestroyElement() {
        if (this.popper) {
          this.popper.destroy();
        }
      }
    }, {
      key: "updatePlacement",
      value: function updatePlacement(placement) {
        Ember.set(this, "placement", placement);
      }
    }]);

    return Popover;
  }(Ember.Component);

  exports.default = Popover;
});