define("nimbus/tailwind/config/fonts", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    sans: ["system-ui", "BlinkMacSystemFont", "-apple-system", "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "sans-serif"],
    serif: ["Constantia", "Lucida Bright", "Lucidabright", "Lucida Serif", "Lucida", "DejaVu Serif", "Bitstream Vera Serif", "Liberation Serif", "Georgia", "serif"],
    mono: ["Menlo", "Monaco", "Consolas", "Liberation Mono", "Courier New", "monospace"],
    lato: ["Lato"],
    roboto: ["Roboto"]
  };
});