define("nimbus/pods/message/route", ["exports", "axios", "nimbus/config/environment"], function (exports, _axios, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @property queryParams
     * @type Object
     * @private
     */
    queryParams: {
      msg: {}
    },

    model: function model(params) {
      var id = atob(params.msg);

      /* eslint-disable */
      return _axios.default.get(_environment.default.APP.HOST + "/public-message/" + id).then(function (result) {
        return result.data.data;
      }).catch(function (error) {
        alert(error);
      });
      /* eslint-enable */
    }
  });
});