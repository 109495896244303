define("nimbus/pods/strategywizard/channels/controller", ["exports", "axios", "nimbus/config/environment"], function (exports, _axios, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @private
     * @type {Number}
     */
    channels: 9,

    /**
     * @private
     * @type {Boolean}
     */
    messagesValid: false,

    /**
     * @private
     * @type {Array<String>}
     */
    channelSelect: Ember.computed(function () {
      var counter = 0;
      var array = [];

      while (counter < this.channels) {
        array.push(this.intl.t("route.strategywizard.channels.form.channels.options." + counter));
        counter++;
      }

      return array;
    }),

    /**
     * @private
     * @type {Boolean}
     */
    formIsValid: Ember.computed("model.channels.length", "messagesValid", function () {
      return this.messagesValid && this.model.channels.length > 0;
    }),

    actions: {
      setChannel: function setChannel(channel) {
        if (this.model.channels.indexOf(channel) > -1) {
          this.model.channels.removeObject(channel);
        } else {
          this.model.channels.pushObject(channel);
        }
      },
      checkMessagesValid: function checkMessagesValid() {
        var isValid = true;

        this.model.goals.map(function (goal) {
          goal.messages.map(function (message) {
            if (message.active && !message.posts) {
              isValid = false;
            }
          });

          goal.custom.map(function (message) {
            if (!message.posts) {
              isValid = false;
            }
          });
        });

        Ember.set(this, "messagesValid", isValid);
      },
      generate: function generate() {
        var _this = this;

        _axios.default.post(_environment.default.APP.HOST + "/strategy", this.model).then(function (result) {
          _this.transitionToRoute("strategywizard.summary");
          Ember.set(_this.model, "download", result.data.data);
        }).catch(function (error) {
          alert(error);
        });
      }
    }
  });
});