define("nimbus/pods/components/x-member-select/component", ["exports", "@ember-decorators/service", "@ember-decorators/object"], function (exports, _service, _object) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _dec3, _desc, _value, _class, _descriptor;

  var MemberSelect = (_dec = (0, _object.computed)("entries.@each.isSelected"), _dec2 = (0, _object.computed)("prefix"), _dec3 = (0, _object.computed)("filter", "members.[]", "selected.[]"), (_class = function (_EmberComponent) {
    _inherits(MemberSelect, _EmberComponent);

    function MemberSelect() {
      _classCallCheck(this, MemberSelect);

      var _this = _possibleConstructorReturn(this, (MemberSelect.__proto__ || Object.getPrototypeOf(MemberSelect)).apply(this, arguments));

      _initDefineProp(_this, "store", _descriptor, _this);

      return _this;
    }

    _createClass(MemberSelect, [{
      key: "toggle",
      value: function toggle(entry) {
        var member = Ember.get(entry, (this.path || "") + "member");

        if (entry.isSelected) {
          (this.onRemove || function () {})(member);
        } else {
          (this.onAdd || function () {})(member);
        }
      }
    }, {
      key: "multiSelect",
      value: function multiSelect(shouldRemove) {
        var _this2 = this;

        this.members.forEach(function (member) {
          if (shouldRemove) {
            (_this2.onRemove || function () {})(member);
          } else {
            (_this2.onAdd || function () {})(member);
          }
        });
      }
    }, {
      key: "shouldRemove",
      get: function get() {
        return this.entries.every(function (e) {
          return e.isSelected;
        });
      }
    }, {
      key: "path",
      get: function get() {
        return this.prefix ? this.prefix + "." : null;
      }
    }, {
      key: "entries",
      get: function get() {
        var filter = this.filter ? new RegExp(this.filter, "gi") : null;
        var members = this.members || Ember.A([]);
        var selected = this.selected || Ember.A([]);

        /* eslint-disable */
        var match = function match(member) {
          return member.get("user.fullName").match(filter) || member.get("groups").any(function (g) {
            return g.get("name").match(filter);
          });
        };
        /* eslint-enable */

        return members.filter(function (member) {
          return !filter ? member : match(member);
        }).map(function (member) {
          return {
            member: member,
            isSelected: selected.includes(member)
          };
        });
      }
    }]);

    return MemberSelect;
  }(Ember.Component), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "store", [_service.service], {
    enumerable: true,
    initializer: function initializer() {
      return null;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggle", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "toggle"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "multiSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "multiSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldRemove", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "shouldRemove"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "path", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "path"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "entries", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "entries"), _class.prototype)), _class));
  exports.default = MemberSelect;
});