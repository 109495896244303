define("nimbus/services/validators/linkedin/length", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(config) {
      var body = Ember.getWithDefault(config, "variant.content.body", "");
      var error = {
        platform: "linkedin",
        type: _validator.VALIDATION_ERROR,
        message: this.intl.t("validation.linkedin.length")
      };

      return body.length <= 1300 ? [] : [error];
    }
  });
});