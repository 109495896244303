define("nimbus/pods/admin/team/pages/index/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.loadPages.perform();
    },
    resetController: function resetController(controller) {
      Ember.set(controller, "filter", "");
      Ember.set(controller, "search", "");
    },


    actions: {
      demoRouteReset: function demoRouteReset() {
        var controller = Ember.get(this, "controller");
        this.resetController(controller);
        this.setupController(controller);
      }
    }
  });
});