define("nimbus/serializers/application", ["exports", "ember-data/serializers/json-api"], function (exports, _jsonApi) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _jsonApi.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {DS.Snapshot} snapshot
     * @returns {Object}
     */
    serialize: function serialize(snapshot) {
      var serialized = {};

      snapshot.eachAttribute(function (name) {
        var value = snapshot.attr(name);

        if (value != undefined) {
          serialized[name.underscore()] = value;
        }
      });

      return serialized;
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.Model} model
     * @param {Object} payload
     * @returns {Object}
     */
    normalize: function normalize(model, payload) {
      return payload.data instanceof Array ? this.normalizeCollection(this.store, model, payload) : this.normalizeResource(this.store, model, payload);
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeQueryResponse: function normalizeQueryResponse(store, modelClass, payload) {
      return this.normalizeCollection(store, modelClass, payload);
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeFindAllResponse: function normalizeFindAllResponse(store, modelClass, payload) {
      return this.normalizeCollection(store, modelClass, payload);
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeFindRecordResponse: function normalizeFindRecordResponse(store, modelClass, payload) {
      return this.normalizeResource(store, modelClass, payload);
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeCreateRecordResponse: function normalizeCreateRecordResponse(store, modelClass, payload) {
      return this.normalizeResource(store, modelClass, payload);
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeQueryRecordResponse: function normalizeQueryRecordResponse(store, modelClass, payload) {
      return this.normalizeResource(store, modelClass, payload);
    },


    /**
     * @private
     * @function
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeResource: function normalizeResource(store, modelClass, payload) {
      var included = [];
      var data = this.normalizeRecord(store, modelClass, payload.data, included);

      return { data: data, included: included };
    },


    /**
     * @private
     * @function
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeCollection: function normalizeCollection(store, modelClass, payload) {
      var _this = this;

      var included = [];
      var meta = payload._pagination || {};
      var data = payload.data.map(function (e) {
        return _this.normalizeRecord(store, modelClass, e, included);
      });

      return { data: data, included: included, meta: meta };
    },


    /**
     * @private
     * @function
     * @param {DS.store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @param {Array} included
     * @param {Object} attributes
     * @returns {Object}
     */
    normalizeRecord: function normalizeRecord(store, modelClass, payload) {
      var included = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : [];
      var attributes = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : {};

      var normalized = {
        id: Ember.get(payload, "uuid"),
        type: modelClass.modelName,
        attributes: Ember.assign({}, attributes),
        relationships: {}
      };

      // Perform a best effor normalization of links
      this.attributesForModel(modelClass);
      this.attributesForModel(modelClass).filter(function (attr) {
        return attr.indexOf("Url") > -1;
      }).forEach(function (attr) {
        return Ember.set(normalized, "attributes." + attr, Ember.getWithDefault(payload, "_links." + attr.split("Url")[0].underscore() + ".href", null));
      });

      // Perform a best effort normalization of attributes
      this.attributesForModel(modelClass).filter(function (attr) {
        return Ember.get(payload, attr.underscore()) !== undefined;
      }).forEach(function (attr) {
        return Ember.set(normalized, "attributes." + attr, Ember.get(payload, attr.underscore()));
      });

      // Perform a best-effort normalization of relationships

      var _relationshipsForMode = this.relationshipsForModel(modelClass),
          hasMany = _relationshipsForMode.hasMany,
          belongsTo = _relationshipsForMode.belongsTo;

      hasMany.filter(function (_ref) {
        var name = _ref.name;
        return Ember.get(payload, name.underscore());
      }).forEach(function (_ref2) {
        var name = _ref2.name;

        Ember.get(payload, name.underscore()).forEach(function (e) {
          Ember.set(e, "uuid", Ember.getWithDefault(e, "uuid", normalized.id));
        });
      });

      hasMany.filter(function (_ref3) {
        var name = _ref3.name;
        return Ember.get(payload, name.underscore());
      }).forEach(function (_ref4) {
        var name = _ref4.name,
            type = _ref4.type;

        var data = Ember.get(payload, name.underscore()).map(function (e) {
          return {
            type: type,
            id: Ember.get(e, "uuid")
          };
        });

        normalized.relationships[name] = { data: data };
      });

      hasMany.filter(function (_ref5) {
        var name = _ref5.name;
        return Ember.get(payload, name.underscore());
      }).forEach(function (_ref6) {
        var name = _ref6.name,
            type = _ref6.type;

        var model = store.modelFor(type);
        var serializer = store.serializerFor(type);

        included.pushObjects(Ember.get(payload, name.underscore()).map(function (e) {
          return serializer.normalizeRecord(store, model, e, included);
        }));
      });

      belongsTo.filter(function (_ref7) {
        var name = _ref7.name;
        return Ember.get(payload, name.underscore());
      }).forEach(function (_ref8) {
        var name = _ref8.name;

        if (!Ember.get(payload, name.underscore() + ".uuid")) {
          Ember.set(payload, name.underscore() + ".uuid", Ember.get(payload, "uuid"));
        }
      });

      belongsTo.filter(function (_ref9) {
        var name = _ref9.name;
        return Ember.get(payload, name.underscore());
      }).forEach(function (_ref10) {
        var type = _ref10.type,
            name = _ref10.name;

        var id = Ember.get(payload, name.underscore() + ".uuid");

        normalized.relationships[name] = { data: { id: id, type: type } };
      });

      belongsTo.filter(function (_ref11) {
        var name = _ref11.name;
        return Ember.get(payload, name.underscore());
      }).forEach(function (_ref12) {
        var type = _ref12.type,
            name = _ref12.name;

        var model = store.modelFor(type);
        var embedded = Ember.get(payload, name.underscore());
        var serializer = store.serializerFor(type);

        included.pushObject(serializer.normalizeRecord(store, model, embedded, included));
      });

      this.applyTransforms(modelClass, normalized.attributes);

      return normalized;
    },


    /**
     * @private
     * @function
     * @param {DS.Model} modelClass
     * @returns {Array}
     */
    attributesForModel: function attributesForModel(modelClass) {
      var attributes = [];

      modelClass.attributes.forEach(function (_ref13) {
        var name = _ref13.name;
        return attributes.pushObject(name);
      });

      return attributes;
    },


    /**
     * @private
     * @function
     * @param {DS.Model} modelClass
     * @returns {Array}
     */
    relationshipsForModel: function relationshipsForModel(modelClass) {
      var relationships = {
        hasMany: [],
        belongsTo: []
      };

      Ember.get(modelClass, "relationshipNames").hasMany.forEach(function (rel) {
        /* eslint-disable */
        var name = rel;

        var _EmberGet$get = Ember.get(modelClass, "relationshipsByName").get(rel),
            type = _EmberGet$get.type;
        /* eslint-enable */

        relationships.hasMany.pushObject({ name: name, type: type });
      });

      Ember.get(modelClass, "relationshipNames").belongsTo.forEach(function (rel) {
        /* eslint-disable */
        var name = rel;

        var _EmberGet$get2 = Ember.get(modelClass, "relationshipsByName").get(rel),
            type = _EmberGet$get2.type;
        /* eslint-enable */

        relationships.belongsTo.pushObject({ name: name, type: type });
      });

      return relationships;
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} key
     * @returns {String}
     */
    keyForAttribute: function keyForAttribute(key) {
      return key;
    }
  });
});