define("nimbus/util/computed/gravatar", ["exports", "md5", "nimbus/util/computed/pure"], function (exports, _md, _pure) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  function gravatar(dependentKey) {
    var size = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 64;
    var notFound = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "mm";

    return (0, _pure.default)(dependentKey, function (value) {
      return "//gravatar.com/avatar/" + (0, _md.default)(value) + ".jpg?s=" + size + "&d=" + notFound;
    });
  }

  exports.default = gravatar;
});