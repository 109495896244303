define("nimbus/pods/components/x-time-picker/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * The date value of the time input.
     *
     * @type {Date}
     */
    value: null,

    /**
     * The action invoked when the time changes.
     *
     * @type {Function}
     */
    onChange: null,

    /**
     * @private
     * @type {String}
     */
    format: Ember.computed("intl.locale", function () {
      return this.intl.t("component.x-time-picker.display-format");
    }),

    /**
     * @private
     * @type {String}
     */
    pattern: Ember.computed("intl.locale", function () {
      return this.intl.t("component.x-time-picker.input-pattern");
    }),

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      var inputValue = this.value ? (0, _moment.default)(this.value).format(this.format) : null;

      Ember.set(this, "inputValue", inputValue);
    },


    /**
     * @private
     * @type {Object}
     */
    actions: {
      onFocus: function onFocus(event) {
        event.target.select();
      },
      onUpdate: function onUpdate(unmasked) {
        Ember.set(this, "inputValue", unmasked);
        var nextValue = (0, _moment.default)(unmasked, this.format);

        if (unmasked.match(/[0-9]{2}:[0-9]{2}/)) {
          nextValue.date(this.value.getDate()).month(this.value.getMonth()).year(this.value.getFullYear());

          (this.onChange || function () {})(nextValue.toDate());
        }
      }
    }
  });
});