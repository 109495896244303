define("nimbus/services/validators/linkedin/body-link", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var fixer = function fixer(config) {
    Ember.set(config, "variant.content.body", Ember.getWithDefault(config, "variant.content.body", "") + ".");

    return config;
  };

  exports.default = _validator.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(config) {
      /* eslint-disable */
      // prettier-ignore
      var pattern = /[-a-zA-Z0-9@:%_\+.~#?&\/\/=]{2,256}\.[a-z]{2,4}\b(\/[-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)?[^.]$/im;
      /* eslint-enable */

      var body = Ember.getWithDefault(config, "variant.content.body", "");
      var warning = {
        fixer: fixer,
        platform: "linkedin",
        type: _validator.VALIDATION_WARNING,
        message: this.intl.t("validation.linkedin.body.nolink")
      };

      return body.match(pattern) ? [warning] : [];
    }
  });
});