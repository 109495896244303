define("nimbus/util/css", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (cssObject) {
    var result = Object.keys(cssObject).map(function (property) {
      return property.dasherize() + ": " + cssObject[property];
    }).join(";");

    return Ember.String.htmlSafe(result);
  };
});