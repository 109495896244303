define("nimbus/pods/components/x-popout/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Boolean}
     */
    isDark: false,

    /**
     * @private
     * @type {Boolean}
     */
    isHidden: true,

    /**
     * Sets the default position of the popper, available values are:
     * - 'left'
     * - 'right'
     * - 'top'
     * - 'bottom'
     *
     * @private
     * @type {String}
     */
    position: "left",

    /**
     * @private
     * @type {String}
     */
    method: null,

    /**
     * Indicates if the popper is open.
     *
     * @type {Boolean}
     */
    isOpen: false,

    /**
     * @private
     * @type {String}
     */
    type: null,

    /**
     * @private
     * @type {String}
     */
    elemId: null,

    /**
     * @private
     * @type {Propper.Object}
     */
    popper: null,

    /**
     * @private
     * @type {Boolean}
     */
    isClickable: Ember.computed("method", function () {
      return this.method && this.method === "click";
    }),

    /**
     * @private
     * @type {Boolean}
     */
    isHoverable: Ember.computed("method", function () {
      return this.method && this.method === "hover";
    }),

    didReceiveAttrs: function didReceiveAttrs() {
      if (this.isOpen && this.isHidden) {
        this.openPopper();
      } else if (!this.isOpen && !this.isHidden) {
        this.removePopper();
      }
    },
    didInsertElement: function didInsertElement() {
      Ember.set(this, "elemId", this.elementId);
    },


    actions: {
      clickPopper: function clickPopper() {
        if (this.isClickable) {
          this.openPopper();
        }
      },
      closePopper: function closePopper() {
        if (this.isClickable) {
          this.removePopper();
        }
      },
      hoverPopper: function hoverPopper() {
        if (this.isHoverable) {
          this.openPopper();
        }
      },
      leavePopper: function leavePopper() {
        if (this.isHoverable) {
          this.removePopper();
        }
      }
    },

    openPopper: function openPopper() {
      var ref = document.querySelector("#" + this.elemId + "__mouseover");
      var popup = document.querySelector("#" + this.elemId + "__popper");

      var popper = new Popper(ref, popup, {
        placement: this.position,
        modifiers: {
          arrow: { enable: true }
        }
      });

      Ember.set(this, "popper", popper);
      Ember.set(this, "isHidden", false);
    },
    removePopper: function removePopper() {
      if (this.popper) {
        this.popper.destroy();
      }

      Ember.set(this, "isHidden", true);
    }
  });
});