define("nimbus/app", ["exports", "ember-load-initializers", "nimbus/resolver", "nimbus/config/environment", "promise-polyfill/src/polyfill"], function (exports, _emberLoadInitializers, _resolver, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var modulePrefix = _environment.default.modulePrefix,
      podModulePrefix = _environment.default.podModulePrefix;


  var App = Ember.Application.extend({ modulePrefix: modulePrefix, podModulePrefix: podModulePrefix, Resolver: _resolver.default });
  (0, _emberLoadInitializers.default)(App, modulePrefix);

  exports.default = App;
});