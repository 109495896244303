define("nimbus/pods/admin/messages/index/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @public
     * @function
     * @override
     * @returns {void}
     */
    setupController: function setupController(controller) {
      controller.loadSubjects.perform();
      controller.loadMetadata.perform();
    },


    /**
     * @public
     * @function
     * @override
     * @returns {void}
     */
    resetController: function resetController(controller) {
      Ember.set(controller, "search", "");
      Ember.set(controller, "showCalendar", false);
    }
  });
});