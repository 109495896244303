define("nimbus/pods/components/x-date-input/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The date to use as the input.
     *
     * @type {Date}
     */
    value: null,

    /**
     * The format of the date string.
     *
     * @type {String}
     */
    format: "YYYY/MM/DD",

    /**
     * The action invoked when the date changes.
     *
     * @type {Function}
     */
    onChange: null,

    /**
     * The action invoked when a date needs to be parsed.
     *
     * @type {Function}
     */
    onParseDate: null,

    /**
     * @private
     * @type {Date}
     */
    displayValue: null,

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      this.updateDisplayValue();
    },


    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @override
       * @param {Date} date
       * @returns {void}
       */
      change: function change(date) {
        Ember.set(this, "shouldUpdateDisplayValue", true);
        Ember.set(this, "showPopper", false);

        (this.onChange || function () {})(date);
      },


      /**
       * @private
       * @function
       * @override
       * @param {Event} event
       * @returns {void}
       */
      update: function update(event) {
        var parser = this.onParseDate || this.defaultDateParse.bind(this);
        var parsed = parser(event.target.value);

        Ember.set(this, "shouldUpdateDisplayValue", false);
        (this.onChange || function () {})(parsed);
      }
    },

    /**
     * @private
     * @function
     * @returns {void}
     */
    updateDisplayValue: function updateDisplayValue() {
      if (this.value && this.format && this.shouldUpdateDisplayValue) {
        Ember.set(this, "displayValue", (0, _moment.default)(this.value).format(this.format));
      }
    },


    /**
     * @private
     * @function
     * @param {String} string
     * @return {Date}
     */
    defaultDateParse: function defaultDateParse(string) {
      var date = (0, _moment.default)(string, this.format).toDate();

      return !isNaN(date) ? date : null;
    }
  });
});