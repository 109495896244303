define("nimbus/pods/admin/insights/member/controller", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Controller.extend({
    intl: Ember.inject.service(),

    queryParams: ["start", "end", "member"],

    defaultView: true,
    shouldShowDatePicker: false,
    tempStart: null,
    tempEnd: null,
    isLoading: false,
    sortColumnConnection: "name",
    sortColumnConnectionDirection: "asc",

    sortedConnections: Ember.computed.sort("connections", "connectionSorting"),

    shortcuts: Ember.computed("model", function () {
      var _ref;

      return _ref = {}, _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.this_week"), {
        start: (0, _moment.default)().startOf("week").toDate(),
        end: (0, _moment.default)().toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_week"), {
        start: (0, _moment.default)().startOf("week").subtract(1, "weeks").toDate(),
        end: (0, _moment.default)().endOf("week").subtract(1, "weeks").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.this_month"), {
        start: (0, _moment.default)().startOf("month").toDate(),
        end: (0, _moment.default)().toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_month"), {
        start: (0, _moment.default)().subtract(1, "months").startOf("month").toDate(),
        end: (0, _moment.default)().subtract(1, "months").endOf("month").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.this_year"), {
        start: (0, _moment.default)().startOf("year").toDate(),
        end: (0, _moment.default)().endOf("year").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_year"), {
        start: (0, _moment.default)().subtract(1, "years").startOf("year").toDate(),
        end: (0, _moment.default)().subtract(1, "years").endOf("year").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.all_time"), {
        start: (0, _moment.default)().set("year", 2016).startOf("year").toDate(),
        end: (0, _moment.default)().endOf("year").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_days"), {
        start: (0, _moment.default)().subtract(7, "days").toDate(),
        end: (0, _moment.default)().toDate()
      }), _ref;
    }),

    parentUrl: Ember.computed("__start", "__end", function () {
      return "/admin/insights?start=" + (0, _moment.default)(this.__start).format("YYYY-MM-DD") + "&end=" + (0, _moment.default)(this.__end).format("YYYY-MM-DD") + "&interval=weekly";
    }),

    connectionSorting: Ember.computed("sortColumnConnection", "sortColumnConnectionDirection", function () {
      return [this.sortColumnConnection + ":" + this.sortColumnConnectionDirection];
    }),

    range: Ember.computed("__start", "__end", function () {
      return {
        start: this.__start ? (0, _moment.default)(this.__start).toDate() : null,
        end: this.__end ? (0, _moment.default)(this.__end).toDate() : null
      };
    }),

    inputData: Ember.computed("membersWithLogin", function () {
      return {
        labels: [this.intl.t("route.admin.insights.chart.inputs.video"), this.intl.t("route.admin.insights.chart.inputs.image"), this.intl.t("route.admin.insights.chart.inputs.text")],
        datasets: [{
          data: [this.membersWithLogin[0].input.video, this.membersWithLogin[0].input.image, this.membersWithLogin[0].input.text],
          backgroundColor: ["#3490DC", "#F6993F", "#38C172"],
          borderWidth: 2
        }]
      };
    }),

    suggestionsData: Ember.computed("membersWithLogin", function () {
      return {
        labels: ["Facebook", "Twitter", "LinkedIn", "Instagram"],
        datasets: [{
          data: [this.membersWithLogin[0].suggestion.shares.facebook, this.membersWithLogin[0].suggestion.shares.twitter, this.membersWithLogin[0].suggestion.shares.linkedin, this.membersWithLogin[0].suggestion.shares.instagram],
          backgroundColor: ["#3b5998", "#1da1f2", "#0077b5", "#c13584"],
          borderWidth: 2
        }]
      };
    }),

    sortConnectionIcon: Ember.computed("sortColumnConnectionDirection", function () {
      return this.sortColumnConnectionDirection === "asc" ? "sort-down" : "sort-up";
    }),

    connections: Ember.computed("model", function () {
      return this.model.connections;
    }),

    membersWithLogin: Ember.computed("model", function () {
      var _this = this;

      var returnValue = [];

      this.model.members.forEach(function (member) {
        var loginDate = (0, _moment.default)(Ember.get(member, "last_login")).toDate();
        var newMember = member;
        newMember.last_datetime = loginDate;
        newMember.last_formatted = (0, _moment.default)(Ember.get(member, "last_login")).format("DD-MM-YYYY");

        _this.model.pages.forEach(function (page) {
          if (page.member === member.id) {
            newMember.page = page;
          }
        });

        if (!newMember.page) {
          newMember.page = {
            activity: {
              pending: 0,
              removed: 0,
              scheduled: 0,
              sent: 0
            },
            statistics: {
              total: {
                likes: 0,
                replies: 0,
                shares: 0
              },
              linkedin: {
                likes: 0,
                replies: 0,
                shares: 0
              },
              facebook: {
                likes: 0,
                replies: 0,
                shares: 0
              }
            }
          };
        }

        _this.model.personal.forEach(function (page) {
          if (page.member === member.id) {
            newMember.page.activity.pending = page.activity.pending;
            newMember.page.activity.removed = page.activity.removed;
            newMember.page.activity.scheduled = page.activity.scheduled;
            newMember.page.activity.sent = page.activity.sent;
            newMember.page.statistics.total.likes = page.statistics.total.likes;
            newMember.page.statistics.total.replies = page.statistics.total.replies;
            newMember.page.statistics.total.shares = page.statistics.total.shares;
            newMember.page.statistics.facebook.likes = page.statistics.facebook.likes;
            newMember.page.statistics.facebook.replies = page.statistics.facebook.replies;
            newMember.page.statistics.facebook.shares = page.statistics.facebook.shares;
            newMember.page.statistics.linkedin.likes = page.statistics.linkedin.likes;
            newMember.page.statistics.linkedin.replies = page.statistics.linkedin.replies;
            newMember.page.statistics.linkedin.shares = page.statistics.linkedin.shares;
          }
        });

        _this.model.suggestions.forEach(function (suggestion) {
          if (suggestion.member === member.id) {
            newMember.suggestion = suggestion;
          }
        });

        if (!newMember.suggestion) {
          newMember.suggestion = {
            suggestions: 0,
            removed: 0,
            open: 0,
            shares: {
              total: 0,
              facebook: 0,
              linkedin: 0,
              instagram: 0,
              twitter: 0
            }
          };
        }

        _this.model.inputs.forEach(function (input) {
          if (input.member === member.id) {
            newMember.input = input;
          }
        });

        if (!newMember.input) {
          newMember.input = {
            total: 0,
            image: 0,
            video: 0,
            text: 0
          };
        }

        newMember.total = {
          pending: newMember.page.activity.pending,
          scheduled: newMember.page.activity.scheduled + newMember.suggestion.open,
          removed: newMember.page.activity.removed + newMember.suggestion.removed,
          sent: newMember.page.activity.sent + newMember.suggestion.shares.total,
          turnover: 5
        };

        returnValue.push(newMember);
      });

      return returnValue;
    }),

    actions: {
      updateReport: function updateReport() {
        var _this2 = this;

        Ember.set(this, "isLoading", true);

        this.transitionToRoute({
          queryParams: {
            start: (0, _moment.default)(this.__start).format("YYYY-MM-DD"),
            end: (0, _moment.default)(this.__end).format("YYYY-MM-DD"),
            member: this.__member
          }
        }).then(function () {
          Ember.set(_this2, "isLoading", false);
        });
      },
      showDatePicker: function showDatePicker(position) {
        Ember.set(this, "tempStart", this.__start);
        Ember.set(this, "tempEnd", this.__end);

        if (position === "start") {
          Ember.set(this, "__start", null);
          Ember.set(this, "__end", null);
        } else if (position === "end") {
          Ember.set(this, "__end", null);
        }

        Ember.set(this, "shouldShowDatePicker", true);
      },
      hideDatePicker: function hideDatePicker() {
        Ember.set(this, "__start", this.tempStart);
        Ember.set(this, "__end", this.tempEnd);

        Ember.set(this, "shouldShowDatePicker", false);
      },
      onChangeRange: function onChangeRange(_ref2) {
        var start = _ref2.start,
            end = _ref2.end;

        Ember.set(this, "__start", start);
        Ember.set(this, "__end", end);

        if (start && end) {
          Ember.set(this, "shouldShowDatePicker", false);
        }
      },
      sortConnections: function sortConnections(column) {
        if (column === this.sortColumnConnection) {
          Ember.set(this, "sortColumnConnectionDirection", this.sortColumnConnectionDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnConnection", column);
          Ember.set(this, "sortColumnConnectionDirection", column === "name" || column === "platform" ? "asc" : "desc");
        }
      }
    }
  });
});