define("nimbus/pods/components/x-chart/line/component", ["exports", "chart.js"], function (exports, _chart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Line = function (_EmberComponent) {
    _inherits(Line, _EmberComponent);

    function Line() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, Line);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Line.__proto__ || Object.getPrototypeOf(Line)).call.apply(_ref, [this].concat(args))), _this), _this.chart = undefined, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Line, [{
      key: "didInsertElement",
      value: function didInsertElement() {
        var _this2 = this;

        var selector = "." + this.styleNamespace + "__chart";
        var element = this.element.querySelector(selector);
        var context = element.getContext("2d");

        Ember.run(function () {
          var chart = new _chart.default(context, _this2.buildOptions(_this2.type, _this2.data, _this2.options));

          Ember.set(_this2, "chart", chart);
        });
      }
    }, {
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        var _this3 = this;

        if (this.chart !== undefined) {
          Ember.run(function () {
            _this3.chart.data = {
              labels: _this3.transformLabels(_this3.type, _this3.data, _this3.options),
              datasets: _this3.transformData(_this3.type, _this3.data)
            };

            _this3.chart.update();
          });
        }
      }
    }, {
      key: "buildOptions",
      value: function buildOptions(type, data, options) {
        var resolved = {
          type: "line",
          data: {
            labels: this.transformLabels(type, data, options),
            datasets: this.transformData(type, data)
          },
          options: {
            responsive: true,
            tooltips: { mode: "index" },
            legend: { position: "bottom" },
            scales: {
              yAxes: [{
                ticks: { min: 0, precision: 0 }
              }]
            }
          }
        };

        return resolved;
      }
    }, {
      key: "transformLabels",
      value: function transformLabels(type, data, options) {
        return data.series.reduce(function (r, s) {
          return r.concat(s.data.map(function (v) {
            return v.x;
          }));
        }, []).uniq().map(function (l) {
          return options.axisX.labelInterpolationFnc ? options.axisX.labelInterpolationFnc(l) : l;
        });
      }
    }, {
      key: "transformData",
      value: function transformData(type, data) {
        return data.series.map(function (s) {
          return {
            fill: false,
            data: s.data.map(function (v) {
              return v.y;
            }),
            label: s.name,
            borderColor: s.color,
            backgroundColor: s.color
          };
        });
      }
    }]);

    return Line;
  }(Ember.Component);

  exports.default = Line;
});