define("nimbus/pods/review/route", ["exports", "ember-simple-auth/mixins/authenticated-route-mixin"], function (exports, _authenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend(_authenticatedRouteMixin.default, {
    /**
     * @private
     * @type {MenuService}
     */
    menu: Ember.inject.service(),

    /**
     * @property queryParams
     * @type Object
     * @private
     */
    queryParams: {
      message: {}
    },

    model: function model(params) {
      return this.store.findRecord("social/message", params.message);
    },
    activate: function activate() {
      Ember.set(this, "menu.showMenu", false);
    },
    deactivate: function deactivate() {
      Ember.set(this, "menu.showMenu", undefined);
    }
  });
});