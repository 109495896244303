define("nimbus/pods/admin/stream/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    setupController: function setupController(controller) {
      controller.loadData.perform();
    },
    resetController: function resetController(controller) {
      Ember.set(controller, "search", "");
      Ember.set(controller, "isComposerOpen", false);
    },


    actions: {
      willTransition: function willTransition(transition) {
        this.controller.checkUploading(transition);
      },
      didTransition: function didTransition() {
        this.controller.reloadTheStream();
      },
      demoRouteReset: function demoRouteReset() {
        var controller = Ember.get(this, "controller");
        this.resetController(controller);
        this.setupController(controller);
      }
    }
  });
});