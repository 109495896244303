define("nimbus/pods/components/x-image-editor/component", ["exports", "@ember-decorators/object", "nimbus/config/environment"], function (exports, _object, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _desc, _value, _class;

  if (!HTMLCanvasElement.prototype.toBlob) {
    Object.defineProperty(HTMLCanvasElement.prototype, "toBlob", {
      value: function value(callback, type, quality) {
        var dataURL = this.toDataURL(type, quality).split(",")[1];
        setTimeout(function () {
          var binStr = atob(dataURL),
              len = binStr.length,
              arr = new Uint8Array(len);

          for (var i = 0; i < len; i++) {
            arr[i] = binStr.charCodeAt(i);
          }

          callback(new Blob([arr], { type: type || "image/png" }));
        });
      }
    });
  }

  var HOST = _environment.default.APP.HOST;
  var ImageEditor = (_dec = (0, _object.computed)("url"), (_class = function (_EmberComponent) {
    _inherits(ImageEditor, _EmberComponent);

    function ImageEditor() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, ImageEditor);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = ImageEditor.__proto__ || Object.getPrototypeOf(ImageEditor)).call.apply(_ref, [this].concat(args))), _this), _this._cropper = null, _this._isEditing = false, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(ImageEditor, [{
      key: "edit",
      value: function edit() {
        Ember.set(this, "_isEditing", true);
      }
    }, {
      key: "save",
      value: function save() {
        var _this2 = this;

        var canvas = this.element.querySelector("canvas");
        var promise = new Promise(function (resolve) {
          return canvas.toBlob(function (blob) {
            return resolve(blob);
          }, "image/png");
        });

        var handler = this.onSave || function () {
          return Ember.RSVP.resolve();
        };

        promise.then(function (blob) {
          return handler(blob);
        }).then(function () {
          return Ember.set(_this2, "_isEditing", false);
        });
      }
    }, {
      key: "zoomIn",
      value: function zoomIn() {
        if (this._cropper) {
          this._cropper.zoomCanvas(0.8);
        }
      }
    }, {
      key: "zoomOut",
      value: function zoomOut() {
        if (this._cropper) {
          this._cropper.zoomCanvas(-0.8);
        }
      }
    }, {
      key: "_resource",
      get: function get() {
        return this.url ? HOST + "/proxy?url=" + this.url : null;
      }
    }]);

    return ImageEditor;
  }(Ember.Component), (_applyDecoratedDescriptor(_class.prototype, "_resource", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "_resource"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "edit", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "edit"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "save", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "save"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "zoomIn", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "zoomIn"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "zoomOut", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "zoomOut"), _class.prototype)), _class));
  exports.default = ImageEditor;
});