define("nimbus/pods/components/x-menu/item/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {RouterService}
     */
    router: Ember.inject.service(),

    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["isActive:active"],

    /**
     * @private
     * @type {Boolean}
     */
    isActive: Ember.computed("router.currentRouteName", "route", function () {
      return this.router.isActive(this.route);
    }),

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    click: function click() {
      this.router.transitionTo(this.route);
    }
  });
});