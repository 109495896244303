define("nimbus/pods/strategywizard/goals/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @private
     * @type {Boolean}
     */
    contentValid: false,

    /**
     * @private
     * @type {Number}
     */
    goals: 13,

    canAddGoal: Ember.computed("model.goals.length", function () {
      return this.model.goals.length < 5;
    }),

    /**
     * @private
     * @type {Array<String>}
     */
    goalSelect: Ember.computed(function () {
      var counter = 0;
      var array = [];

      while (counter < this.goals) {
        array.push(this.intl.t("route.strategywizard.goals.form.goal.options." + counter));
        counter++;
      }

      array.sort();

      return array;
    }),

    formIsValid: Ember.computed("model.goals.@each.{description}", "contentValid", function () {
      var isValid = true;

      this.model.goals.map(function (goal) {
        if (!goal.description.length) {
          isValid = false;
        }
      });

      return isValid && this.contentValid;
    }),

    actions: {
      addGoal: function addGoal() {
        var counter = 0;
        var messages = [];

        while (counter < 10) {
          var obj = {
            description: this.intl.t("route.strategywizard.goals.form.content.options." + counter),
            posts: null,
            active: false
          };

          messages.pushObject(obj);

          counter++;
        }

        this.model.goals.pushObject({
          description: "",
          posts: "",
          stories: [],
          messages: messages,
          custom: [],
          storyInput: "",
          customInput: ""
        });

        this.checkContentValid();
      },
      removeGoal: function removeGoal(goal) {
        if (confirm(this.intl.t("route.strategywizard.goals.form.remove"))) {
          this.model.goals.removeObject(goal);
          this.checkContentValid();
        }
      },
      setActive: function setActive(message) {
        Ember.set(message, "active", !message.active);
        this.checkContentValid();
      },
      addStory: function addStory(goal) {
        if (goal.customStory.trim().length) {
          goal.stories.pushObject(goal.customStory);
          Ember.set(goal, "customStory", "");
          this.checkContentValid();
        }
      },
      removeStory: function removeStory(goal, story) {
        goal.stories.removeObject(story);
        this.checkContentValid();
      },
      addCustomKind: function addCustomKind(goal) {
        if (goal.customInput.trim().length) {
          var obj = { description: goal.customInput, posts: null };
          goal.custom.pushObject(obj);
          Ember.set(goal, "customInput", "");
          this.checkContentValid();
        }
      },
      removeCustomKind: function removeCustomKind(goal, kind) {
        goal.custom.removeObject(kind);
        this.checkContentValid();
      },
      validate: function validate() {
        this.checkContentValid();
      }
    },

    checkContentValid: function checkContentValid() {
      var isValid = true;
      var activeMessages = false;

      this.model.goals.map(function (goal) {
        goal.messages.map(function (message) {
          if (message.active) {
            activeMessages = true;
          }
        });
        if (!activeMessages && !goal.custom.length || !goal.stories.length) {
          isValid = false;
        }
      });

      Ember.set(this, "contentValid", isValid);
    }
  });
});