define("nimbus/pods/strategywizard/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    model: function model() {
      var counter = 0;
      var messages = [];

      while (counter < 10) {
        var obj = {
          description: this.intl.t("route.strategywizard.goals.form.content.options." + counter),
          posts: null,
          active: false
        };

        messages.pushObject(obj);

        counter++;
      }

      return {
        download: "",
        name: "",
        company: "",
        email: "",
        industry: "",
        role: "",
        channels: [],
        goals: [{
          description: "",
          posts: "",
          messages: messages,
          stories: [],
          custom: [],
          storyInput: "",
          customInput: ""
        }]
      };
    }
  });
});