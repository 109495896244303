define("nimbus/models/publish/content", ["exports", "ember-data/attr", "ember-data/relationships", "ember-data/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The body of the content
     *
     * @type {String}
     */
    body: (0, _attr.default)("string"),

    /**
     * The attachment of the content.
     *
     * @type {module:app/models/publish/content-attachment}
     */
    attachment: (0, _relationships.belongsTo)("publish/content-attachment", { async: false })
  });
});