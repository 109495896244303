define("nimbus/helpers/ordered", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.ordered = ordered;
  function ordered(value) {
    return parseInt(value) + 1;
  }

  exports.default = Ember.Helper.helper(ordered);
});