define("nimbus/adapters/core/ghost", ["exports", "nimbus/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {String} modelName
     * @returns {String}
     */
    urlForQuery: function urlForQuery() {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      return url + "/members?type=ghost";
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} modelName
     * @returns {String}
     */
    urlForFindAll: function urlForFindAll() {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      return url + "/members?type=ghost";
    }
  });
});