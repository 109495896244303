define("nimbus/pods/admin/subjects/details/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return { uuid: params.id };
    },


    /**
     * @public
     * @function
     * @override
     * @returns {void}
     */
    setupController: function setupController(controller, model) {
      Ember.set(controller, "model", model);
      controller.loadSubject.perform();
    },


    actions: {
      demoRouteReset: function demoRouteReset() {
        var controller = Ember.get(this, "controller");
        var model = Ember.get(this, "controller.model");
        this.setupController(controller, model);
      }
    }
  });
});