define("nimbus/pods/connect/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {SessionService}
     */
    session: Ember.inject.service(),

    queryParams: {
      token: {}
    },

    model: function model(params) {
      var _this = this;

      return this.session.authenticate("authenticator:token", params.token).then(function () {
        _this.transitionTo("user.pages");
      });
    }
  });
});