define("nimbus/pods/components/x-menu/search/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["isActive:active"],

    /**
     * @private
     * @type {Boolean}
     */
    isActive: false,

    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @returns {void}
       */
      onBlur: function onBlur() {
        Ember.set(this, "isActive", false);
      },


      /**
       * @private
       * @function
       * @returns {void}
       */
      onFocus: function onFocus() {
        Ember.set(this, "isActive", true);
      },


      /**
       * @private
       * @function
       * @returns {void}
       */
      onSearch: function onSearch() {
        (this.onSearch || function () {})(this.query);
      }
    }
  });
});