define("nimbus/adapters/publish/adapter", ["exports", "ember-inflector", "nimbus/adapters/application"], function (exports, _emberInflector, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {Object} query
     * @param {String} modelName
     * @returns {String}
     */
    urlForQuery: function urlForQuery(query, modelName) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      return url + "/" + (0, _emberInflector.pluralize)(modelName);
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} modelName
     * @returns {String}
     */
    urlForFindAll: function urlForFindAll(modelName) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      return url + "/" + (0, _emberInflector.pluralize)(modelName) + "?limit=-1";
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} id
     * @param {String} modelName
     * @param {DS.Snapshot} snapshot
     * @returns {String}
     */
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      if (snapshot && snapshot.attr("selfUrl")) {
        return snapshot.attr("selfUrl");
      }

      return url + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id;
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} id
     * @param {String} modelName
     * @param {DS.Snapshot} snapshot
     * @returns {String}
     */
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      if (snapshot && snapshot.attr("updateUrl")) {
        return snapshot.attr("updateUrl");
      }

      return url + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id;
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} id
     * @param {String} modelName
     * @param {DS.Snapshot} snapshot
     * @returns {String}
     */
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      if (snapshot && snapshot.attr("deleteUrl")) {
        return snapshot.attr("deleteUrl");
      }

      return url + "/" + (0, _emberInflector.pluralize)(modelName) + "/" + id;
    }
  });
});