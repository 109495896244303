define("nimbus/util/computed/join", ["exports", "nimbus/util/computed/pure"], function (exports, _pure) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (seperator) {
    for (var _len = arguments.length, dependentKeys = Array(_len > 1 ? _len - 1 : 0), _key = 1; _key < _len; _key++) {
      dependentKeys[_key - 1] = arguments[_key];
    }

    return _pure.default.apply(undefined, dependentKeys.concat([function () {
      for (var _len2 = arguments.length, values = Array(_len2), _key2 = 0; _key2 < _len2; _key2++) {
        values[_key2] = arguments[_key2];
      }

      return flatten(values).compact().join(seperator);
    }]));
  };

  function flatten(array) {
    return array.reduce(function (acc, value) {
      return value instanceof Array ? acc.concat(flatten(value)) : acc.concat([value]);
    }, []);
  }
});