define("nimbus/models/core/membership", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * Indicates if the user is a team administrator.
     *
     * @type {Boolean}
     */
    isAdmin: (0, _attr.default)("boolean"),

    /**
     * Indicates if the user is a fullblown member
     *
     * @type {Boolean}
     */
    ghost: (0, _attr.default)("boolean"),

    /**
     * Indicates if page messages should be posted automatically
     *
     * @type {Boolean}
     */
    hasPagesAutoApprove: (0, _attr.default)("boolean"),

    /**
     * Indicates if the member wants to be notified of new input
     *
     * @type {Boolean}
     */
    hasNewContentNotif: (0, _attr.default)("boolean"),

    /**
     * The date and time the member has last seen the Feed.
     *
     * @type {Date}
     */
    lastFeedUpdate: (0, _attr.default)("date"),

    /**
     * The level for this user
     *
     * @type String
     */
    level: (0, _attr.default)("string"),

    /**
     * The platforms available for a member
     *
     * @type Array
     */
    platforms: (0, _attr.default)("Array"),

    /**
     * The URL where the platforms can be retrieved from
     *
     * @type String
     */
    platformsUrl: (0, _attr.default)("String"),

    /**
     * The URL to send a post request to for connecting pages for a user
     *
     * @type {String}
     */
    connectUrl: (0, _attr.default)("string"),

    /**
     * The user the membership is for.
     *
     * @type {module:app/models/core/user}
     */
    user: (0, _relationships.belongsTo)("core/user", { async: false }),

    /**
     * The team the membership is for.
     *
     * @type {module:app/models/core/team}
     */
    team: (0, _relationships.belongsTo)("core/team", { async: false }),

    /**
     * Whether or not a member has Facebook
     *
     * @type {Boolean}
     */
    hasFacebook: Ember.computed("platforms", function () {
      return this.platforms && this.platforms.indexOf("facebook") > -1;
    }),

    /**
     * Whether or not a member has Twitter
     *
     * @type {Boolean}
     */
    hasTwitter: Ember.computed("platforms", function () {
      return this.platforms && this.platforms.indexOf("twitter") > -1;
    }),

    /**
     * Whether or not a member has Linkedin
     *
     * @type {Boolean}
     */
    hasLinkedin: Ember.computed("platforms", function () {
      return this.platforms && this.platforms.indexOf("linkedin") > -1;
    }),

    /**
     * Whether or not a member has Instagram
     *
     * @type {Boolean}
     */
    hasInstagram: Ember.computed("platforms", function () {
      return this.platforms && this.platforms.indexOf("instagram") > -1;
    })
  });
});