define("nimbus/services/validators/twitter", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    length: Ember.inject.service("validators/twitter/length"),
    bodylink: Ember.inject.service("validators/twitter/bodylink"),
    imagesize: Ember.inject.service("validators/twitter/imagesize"),
    videosize: Ember.inject.service("validators/twitter/videosize"),
    videolength: Ember.inject.service("validators/twitter/videolength"),

    validate: function validate(config) {
      return [this.length, this.bodylink, this.imagesize, this.videosize, this.videolength].reduce(function (errors, validator) {
        return errors.concat(validator.validate(config));
      }, []);
    }
  });
});