define("nimbus/models/publish/subject", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model", "nimbus/config/environment"], function (exports, _attr, _relationships, _model, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  var STATE_ACTIVE = "active";
  var STATE_PENDING = "pending";
  var STATE_REJECTED = "rejected";
  var STATE_PARTIALLY = "partially";
  var STATE_SCHEDULED = "scheduled";

  var HOST = _environment.default.APP.HOST;
  exports.default = _model.default.extend({
    /**
     * The name of the publish subject.
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    totalSuggestions: (0, _attr.default)("number"),

    totalShares: (0, _attr.default)("number"),

    /**
     * The variants of the subject.
     *
     * @type {Array.<module:app/models/publish/variant>}
     */
    variants: (0, _relationships.hasMany)("publish/variant", { async: false }),

    /**
     * The variants of the subject.
     *
     * @type {Array.<module:app/models/social/message>}
     */
    messages: (0, _relationships.hasMany)("social/message", { async: false }),

    /**
     * @type {Boolean}
     */
    isActive: Ember.computed.equal("state", STATE_ACTIVE),

    /**
     * @type {Boolean}
     */
    isPending: Ember.computed.equal("state", STATE_PENDING),

    /**
     * @type {Boolean}
     */
    isRejected: Ember.computed.equal("state", STATE_REJECTED),

    /**
     * @type {Boolean}
     */
    isPartially: Ember.computed.equal("state", STATE_PARTIALLY),

    /**
     * @type {Boolean}
     */
    isScheduled: Ember.computed.equal("state", STATE_SCHEDULED),

    /**
     * The aggregated content of the variants of the subject.
     *
     * @type {Array.<module:app/models/publish/content>}
     */
    content: Ember.computed.mapBy("variants", "content"),

    /**
     * The aggregated attachments of the variants of the subject.
     *
     * @type {Array.<module:app/models/publish/attachment>}
     */
    attachment: Ember.computed.mapBy("content", "attachment"),

    /**
     * The aggregated metadata of the variants of the subject.
     *
     * @type {Array.<module:app/models/publish/metadata>}
     */
    metadata: Ember.computed.mapBy("variants", "metadata"),

    /**
     * The preview image of the attachment.
     *
     * @type {String}
     */
    image: Ember.computed("attachment.@each.{url,type}", function () {
      var url = "/images/nomedia.png";

      var match = this.variants.find(function (v) {
        return v.content.attachment.type === "image";
      });
      url = match ? HOST + "/proxy?url=" + match.content.attachment.url : url;

      if (!match) {
        match = this.variants.find(function (v) {
          return v.content.attachment.type === "video";
        });
        url = match ? HOST + "/thumbnail?url=" + match.content.attachment.url : url;
      }

      if (!match) {
        match = this.variants.find(function (v) {
          return v.content.attachment.type === "link";
        });
        url = match ? HOST + "/proxy?url=" + match.content.attachment.image : url;
      }

      return url;
    }),

    /**
     * The safe image URL (proxied with HTTPS).
     *
     * @type {String}
     */
    safeImage: Ember.computed("image", function () {
      return this.image.indexOf("http") > -1 ? HOST + "/proxy?url=" + this.image : this.image;
    }),

    /**
     * The start date of the subject.
     *
     * @type {Date}
     */
    start: Ember.computed("metadata.@each.start", function () {
      return Math.min.apply(Math, _toConsumableArray(this.variants.mapBy("metadata.start")));
    }),

    /**
     * The end date of the subject.
     *
     * @type {Date}
     */
    end: Ember.computed("metadata.@each.end", function () {
      return Math.max.apply(Math, _toConsumableArray(this.variants.mapBy("metadata.end")));
    }),

    /**
     * The state of the subject.
     *
     * @type {String}
     */
    state: Ember.computed("variants.@each.state", "metadata.@each.{start,end}", function () {
      var allPending = this.variants.every(function (v) {
        return v.isPending;
      });
      var anyPending = this.variants.any(function (v) {
        return v.isPending;
      });
      var allRejected = this.variants.every(function (v) {
        return v.isRejected;
      });
      var anyRejected = this.variants.any(function (v) {
        return v.isRejected;
      });

      var anyActive = this.variants.any(function (v) {
        return v.metadata.start < new Date() && v.metadata.end > new Date();
      });

      if (allRejected) {
        return STATE_REJECTED;
      } else if (anyRejected) {
        return STATE_PARTIALLY;
      } else if (allPending) {
        return STATE_PENDING;
      } else if (anyPending) {
        return STATE_PARTIALLY;
      } else if (anyActive) {
        return STATE_ACTIVE;
      }

      return STATE_SCHEDULED;
    }),

    /**
     * The number of shares of the subject.
     *
     * @type {Number}
     */
    shares: Ember.computed("metadata.@each.shares", function () {
      return this.variants.reduce(function (r, v) {
        return r + v.metadata.shares || 0;
      }, 0);
    }),

    /**
     * The number of suggestions of the subject.
     *
     * @type {Number}
     */
    suggestions: Ember.computed("metadata.@each.suggestions", function () {
      return this.variants.reduce(function (r, v) {
        return r + v.metadata.suggestions || 0;
      }, 0);
    }),

    /**
     * Indicates if any of the variants was active in the past.
     *
     * @type {Boolean}
     */
    wasActive: Ember.computed("metadata.@each.{start}", function () {
      return this.metadata.find(function (meta) {
        return meta.start <= new Date();
      });
    })
  });
});