define("nimbus/adapters/application", ["exports", "ember-inflector", "ember-data/adapters/rest", "ember-simple-auth/mixins/data-adapter-mixin", "nimbus/config/environment"], function (exports, _emberInflector, _rest, _dataAdapterMixin, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = _rest.default.extend(_dataAdapterMixin.default, {
    /**
     * @private
     * @type {ContextService}
     */
    context: Ember.inject.service(),

    /**
     * @private
     * @type {SessionService}
     */
    session: Ember.inject.service(),

    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @private
     * @type {String}
     */
    host: HOST,

    /**
     * @private
     * @type {String}
     */
    namespace: Ember.computed.alias("context.domain"),

    /**
     * @private
     * @param {Object} xhr
     * @returns {void}
     */
    authorize: function authorize(xhr) {
      var accessToken = Ember.get(this, "session.data.authenticated.access_token");

      if (Ember.isPresent(accessToken)) {
        xhr.setRequestHeader("Authorization", "Bearer " + accessToken);
      }
    },


    /**
     * @private
     * @function
     * @override
     * @param {Object} query
     * @param {String} modelName
     * @returns {String}
     */
    urlForQuery: function urlForQuery(query, modelName) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      return url + "/" + (0, _emberInflector.pluralize)(modelName.split("/").pop());
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} modelName
     * @returns {String}
     */
    urlForFindAll: function urlForFindAll(modelName) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      return url + "/" + (0, _emberInflector.pluralize)(modelName.split("/").pop()) + "?limit=-1";
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} id
     * @param {String} modelName
     * @param {DS.Snapshot} snapshot
     * @returns {String}
     */
    urlForFindRecord: function urlForFindRecord(id, modelName, snapshot) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      if (snapshot && snapshot.attr("selfUrl")) {
        return snapshot.attr("selfUrl");
      }

      return url + "/" + (0, _emberInflector.pluralize)(modelName.split("/").pop()) + "/" + id;
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} id
     * @param {String} modelName
     * @param {DS.Snapshot} snapshot
     * @returns {String}
     */
    urlForUpdateRecord: function urlForUpdateRecord(id, modelName, snapshot) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      if (snapshot && snapshot.attr("updateUrl")) {
        return snapshot.attr("updateUrl");
      }

      return url + "/" + (0, _emberInflector.pluralize)(modelName.split("/").pop()) + "/" + id;
    },


    /**
     * @private
     * @function
     * @override
     * @param {String} id
     * @param {String} modelName
     * @param {DS.Snapshot} snapshot
     * @returns {String}
     */
    urlForDeleteRecord: function urlForDeleteRecord(id, modelName, snapshot) {
      var url = this.namespace != undefined ? this.host + "/" + this.namespace : this.host;

      if (snapshot && snapshot.attr("deleteUrl")) {
        return snapshot.attr("deleteUrl");
      }

      return url + "/" + (0, _emberInflector.pluralize)(modelName.split("/").pop()) + "/" + id;
    }
  });
});