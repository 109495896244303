define("nimbus/models/core/group", ["exports", "ember-data/attr", "nimbus/models/model"], function (exports, _attr, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The name of the group.
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    /**
     * The slug of the group.
     *
     * @type {String}
     */
    slug: (0, _attr.default)("string")
  });
});