define("nimbus/services/validators/instagram/attachment", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    intl: Ember.inject.service(),
    detector: Ember.inject.service("link-detector"),

    validate: function validate(config) {
      var body = Ember.getWithDefault(config, "variant.content.body", "");
      var type = Ember.getWithDefault(config, "variant.content.attachment.type", "none");

      var error = {
        platform: "instagram",
        type: _validator.VALIDATION_ERROR,
        message: this.intl.t("validation.instagram.attachment")
      };

      var warning = {
        platform: "instagram",
        type: _validator.VALIDATION_WARNING,
        message: this.intl.t("validation.instagram.url")
      };

      if (body.length === 0) {
        return;
      }

      var isLink = type === "link";
      var containsLink = this.containsLink(body);

      return type === "none" ? [error] : isLink || containsLink ? [warning] : [];
    },
    containsLink: function containsLink(body) {
      return this.detector.detect(body).length > 0;
    }
  });
});