define("nimbus/pods/components/x-data-table/header/field/component", ["exports", "@ember-decorators/object", "@ember-decorators/object/computed", "@ember-decorators/component"], function (exports, _object, _computed, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _class, _desc, _value, _class2, _descriptor;

  var Field = (_dec = (0, _component.tagName)("th"), _dec2 = (0, _computed.notEmpty)("property"), _dec3 = (0, _component.className)("sortable"), _dec4 = (0, _object.computed)("shouldAlignRight"), _dec5 = (0, _component.className)("text-left"), _dec6 = (0, _object.computed)("type"), _dec7 = (0, _component.className)("text-right"), _dec8 = (0, _component.className)("sorted"), _dec9 = (0, _object.computed)("sort", "order"), _dec10 = (0, _object.computed)("isSorted", "order"), _dec11 = (0, _object.computed)("isSorted", "isAscending"), _dec(_class = (_class2 = function (_EmberComponent) {
    _inherits(Field, _EmberComponent);

    function Field() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, Field);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Field.__proto__ || Object.getPrototypeOf(Field)).call.apply(_ref, [this].concat(args))), _this), _initDefineProp(_this, "isSortable", _descriptor, _this), _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Field, [{
      key: "click",
      value: function click(event) {
        event.preventDefault();

        if (this.property && this.onSort) {
          this.onSort(this.property);
        }
      }
    }, {
      key: "shouldAlignLeft",
      get: function get() {
        return !this.shouldAlignRight;
      }
    }, {
      key: "shouldAlignRight",
      get: function get() {
        var numericTypes = ["date", "number"];

        return this.type && numericTypes.includes(this.type);
      }
    }, {
      key: "isSorted",
      get: function get() {
        return this.isSortable && this.sort === this.property;
      }
    }, {
      key: "isAscending",
      get: function get() {
        return this.isSorted && (!this.order || this.order.toLowerCase() === "asc");
      }
    }, {
      key: "isDescending",
      get: function get() {
        return this.isSorted && !this.isAscending;
      }
    }]);

    return Field;
  }(Ember.Component), (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isSortable", [_dec2, _dec3], {
    enumerable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "shouldAlignLeft", [_dec4, _dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAlignLeft"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "shouldAlignRight", [_dec6, _dec7], Object.getOwnPropertyDescriptor(_class2.prototype, "shouldAlignRight"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isSorted", [_dec8, _dec9], Object.getOwnPropertyDescriptor(_class2.prototype, "isSorted"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isAscending", [_dec10], Object.getOwnPropertyDescriptor(_class2.prototype, "isAscending"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isDescending", [_dec11], Object.getOwnPropertyDescriptor(_class2.prototype, "isDescending"), _class2.prototype)), _class2)) || _class);
  exports.default = Field;
});