define("nimbus/transforms/array", ["exports", "ember-data/transform"], function (exports, _transform) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _transform.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {Object} serialized
     * @returns {Object}
     */
    deserialize: function deserialize(serialized) {
      return serialized;
    },


    /**
     * @private
     * @function
     * @override
     * @param {Object} deserialized
     * @returns {Object}
     */
    serialize: function serialize(deserialized) {
      return deserialized;
    }
  });
});