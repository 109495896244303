define("nimbus/pods/components/x-quickstat/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    view: "default",

    /**
     * @private
     * @type {String}
     */
    displayValue: Ember.computed("intl.locale", "type", "value", function () {
      switch (this.type) {
        case "number":
          return this.intl.formatNumber(this.value);
        case "money":
        case "currency":
          return this.intl.formatNumber(this.value, {
            style: "currency",
            currency: this.intl.t("global.currency")
          });
        case "percentage":
          return this.value + "%";
        default:
          return this.value;
      }
    })
  });
});