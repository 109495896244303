define("nimbus/pods/components/x-initials/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    name: null,

    color: null,

    short: Ember.computed("name", function () {
      var names = this.name.split(" ");
      var short = names.length === 1 ? names[0].substring(0, 1) : names[0].substring(0, 1) + names[names.length - 1].substring(0, 1);

      return short.toUpperCase();
    })
  });
});