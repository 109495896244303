define("nimbus/pods/components/x-composer/preview/component", ["exports", "lodash"], function (exports, _lodash) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["hasContent:is-visible"],

    /**
     * The body to preview.
     *
     * @type {String}
     */
    body: null,

    /**
     * The attachment to preview.
     *
     * @type {Object}
     */
    attachment: null,

    /**
     * @private
     * @type {String}
     */
    platform: "linkedin",

    /**
     * @private
     * @type {Object}
     */
    isActive: Ember.computed("platform", function () {
      return {
        twitter: this.platform === "twitter",
        facebook: this.platform === "facebook",
        linkedin: this.platform === "linkedin",
        instagram: this.platform === "instagram"
      };
    }),

    /**
     * @private
     * @type {Object}
     */
    errors: Ember.computed("validations.@each.{platform,type}", function () {
      var errors = Ember.getWithDefault(this, "validations", []).filter(function (validation) {
        return validation && validation.type === "error";
      });

      return (0, _lodash.groupBy)(errors, "platform");
    }),

    /**
     * @private
     * @type {Object}
     */
    warnings: Ember.computed("validations.@each.{platform,type}", function () {
      var warnings = Ember.getWithDefault(this, "validations", []).filter(function (validation) {
        return validation && validation.type === "warning";
      });

      return (0, _lodash.groupBy)(warnings, "platform");
    }),

    /**
     * @private
     * @type {Object}
     */
    isValid: Ember.computed("validations.@each.platform", function () {
      var defaults = {
        twitter: true,
        facebook: true,
        linkedin: true,
        instagram: true
      };

      return Ember.getWithDefault(this, "validations", []).filter(function (issue) {
        return issue != null;
      }).reduce(function (issues, issue) {
        return Ember.merge(issues, _defineProperty({}, issue.platform, false));
      }, defaults);
    }),

    /**
     * @private
     * @type {Object}
     */
    hasErrors: Ember.computed("validations.@each.platform", function () {
      var defaults = {
        twitter: false,
        facebook: false,
        linkedin: false,
        instagram: false
      };

      return Ember.getWithDefault(this, "validations", []).filter(function (issue) {
        return issue != null;
      }).filter(function (issue) {
        return issue.type === "error";
      }).reduce(function (issues, issue) {
        return Ember.merge(issues, _defineProperty({}, issue.platform, true));
      }, defaults);
    }),

    /**
     * @private
     * @type {Object}
     */
    hasWarnings: Ember.computed("validations.@each.platform", function () {
      var defaults = {
        twitter: false,
        facebook: false,
        linkedin: false,
        instagram: false
      };

      return Ember.getWithDefault(this, "validations", []).filter(function (issue) {
        return issue != null;
      }).filter(function (issue) {
        return issue.type === "warning";
      }).reduce(function (issues, issue) {
        return Ember.merge(issues, _defineProperty({}, issue.platform, true));
      }, defaults);
    }),

    /**
     * @private
     * @type {Boolean}
     */
    hasContent: Ember.computed("body.length", "attachment.type", function () {
      return Ember.getWithDefault(this, "body.length", 0) > 0 || Ember.getWithDefault(this, "attachment.type", "none") !== "none";
    }),

    /**
     * @private
     * @type {Array}
     */
    activeErrors: Ember.computed("platform", "validations.@each.platform", function () {
      var _this = this;

      return Ember.getWithDefault(this, "validations").filter(function (issue) {
        return issue != null;
      }).filter(function (issue) {
        return issue.platform == _this.platform;
      }).filter(function (issue) {
        return issue.type == "error";
      });
    }),

    /**
     * @private
     * @type {Boolean}
     */
    shouldShowPreview: Ember.computed("platform", "validations.@each.platform", function () {
      var _this2 = this;

      var issues = Ember.getWithDefault(this, "validations", []).filter(function (issue) {
        return issue != null;
      }).filter(function (issue) {
        return issue.platform === _this2.platform;
      }).filter(function (issue) {
        return issue.type === "error";
      });

      return issues.length == 0;
    }),

    /**
     * @private
     * @type {Object}
     */
    popperOptions: Ember.computed(function () {
      return {
        modifiers: {
          preventOverflow: {
            escapeWithReference: false
          }
        }
      };
    })
  });
});