define("nimbus/pods/components/x-preview/instagram/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    securemedia: Ember.inject.service(),

    isEditing: false,
    name: null,
    avatar: null,
    hasLink: Ember.computed.equal("attachment.type", "link"),
    hasVideo: Ember.computed.equal("attachment.type", "video"),
    hasImage: Ember.computed.equal("attachment.type", "image"),

    image: Ember.computed("attachment.{url,type,image}", function () {
      switch (this.attachment.type) {
        case "link":
          return this.attachment.image;
        case "image":
          return this.attachment.url;
        default:
          return null;
      }
    }),

    distance: Ember.computed(function () {
      return Math.round(Math.random() * 40);
    }),

    processed: Ember.computed("body", function () {
      /* eslint-disable */
      // prettier-ignore
      var newline = /(?:\r\n|\r|\n)/g;
      /* eslint-enable */

      return Ember.String.htmlSafe(this.body.replace(newline, "<br>"));
    }),

    mediaUrl: Ember.computed("attachment.url", function () {
      return this.securemedia.secure(this.image);
    }),

    mediaUrlLink: Ember.computed("attachment.image", function () {
      return this.securemedia.secure(this.attachment.image);
    })
  });
});