define('nimbus/components/one-way-ssn-mask', ['exports', 'ember-inputmask/components/one-way-ssn-mask'], function (exports, _oneWaySsnMask) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _oneWaySsnMask.default;
    }
  });
});