define("nimbus/pods/components/x-date-picker/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["isStandalone:standalone"],

    /**
     * The currently selected date.
     *
     * @type {Date}
     */
    value: null,

    /**
     * Indicates if the date picker is a stand-alone component.
     *
     * @type {Boolean}
     */
    isStandalone: true,

    /**
     * The action invoked when the selected value changes.
     *
     * @type {Function}
     */
    onChange: null,

    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @param {Date} date
       * @returns {void}
       */
      onSelectDate: function onSelectDate(date) {
        var value = new Date((this.value || new Date()).getTime());
        value.setDate(date.getDate());
        value.setMonth(date.getMonth());
        value.setFullYear(date.getFullYear());

        (this.onChange || function () {})(value);
      },


      /**
       * @private
       * @function
       * @param {Date} date
       * @returns {void}
       */
      onSelectTime: function onSelectTime(date) {
        var value = new Date((this.value || new Date()).getTime());
        value.setHours(date.getHours());
        value.setMinutes(date.getMinutes());
        value.setSeconds(date.getSeconds());

        (this.onChange || function () {})(value);
      }
    }
  });
});