define("nimbus/services/validators/linkedin", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    body: Ember.inject.service("validators/linkedin/body-link"),
    length: Ember.inject.service("validators/linkedin/length"),

    validate: function validate(config) {
      return [this.body, this.length].reduce(function (errors, validator) {
        return errors.concat(validator.validate(config));
      }, []);
    }
  });
});