define("nimbus/mirage/factories/pending-message", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    uuid: function uuid() {
      return _emberCliMirage.faker.random.uuid();
    },
    link: function link() {
      return _emberCliMirage.faker.internet.url();
    },
    body: function body() {
      return _emberCliMirage.faker.lorem.sentences();
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.recent();
    },
    title: function title() {
      return _emberCliMirage.faker.lorem.words();
    },
    image_url: function image_url() {
      return _emberCliMirage.faker.image.image();
    },
    mime_type: function mime_type() {
      return "image/jpeg";
    },
    scheduled_at: function scheduled_at() {
      return _emberCliMirage.faker.date.future();
    },


    state: "pending",

    self_url: function self_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    },
    update_url: function update_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    },
    delete_url: function delete_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    }
  });
});