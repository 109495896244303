define("nimbus/models/core/user", ["exports", "ember-data/attr", "nimbus/models/model", "nimbus/util/computed/join"], function (exports, _attr, _model, _join) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The username of the user.
     *
     * @type {String}
     */
    username: (0, _attr.default)("string"),

    /**
     * The first (or given) name of the user.
     *
     * @type {String}
     */
    firstName: (0, _attr.default)("string"),

    /**
     * The last (or family) name of the user.
     *
     * @type {String}
     */
    lastName: (0, _attr.default)("string"),

    /**
     * The URL of the avatar of the user.
     *
     * @type {String}
     */
    avatarUrl: (0, _attr.default)("string"),

    /**
     * The date and time the user was last seen by the server.
     *
     * @type {Date}
     */
    lastLogin: (0, _attr.default)("date"),

    /**
     * The locale for this user
     *
     * @type {String}
     */
    locale: (0, _attr.default)("string"),

    /**
     * The full name of the user.
     *
     * @type {String}
     */
    fullName: (0, _join.default)(" ", "{firstName,lastName}")
  });
});