define("nimbus/pods/components/x-avatar/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * Sets the size of the container, possible values:
     * - 'small'
     * - 'large'
     * - 'largest'
     *
     * @private
     * @type {String}
     */
    size: null,

    /**
     * Sets the padding of the container and image, possible values:
     * - 'small'
     * - 'large'
     *
     * @private
     * @type {String}
     */
    padding: null,

    /**
     * @private
     * @type {String}
     * @computed
     */
    imageClass: Ember.computed("padding", function () {
      return this.padding ? "p-" + this.padding : "medium";
    }),

    /**
     * @private
     * @type {String}
     * @computed
     */
    containerClass: Ember.computed("padding", "size", function () {
      var classes = [];

      if (this.padding) {
        classes.push("p-" + this.padding);
      }

      if (this.size) {
        classes.push("s-" + this.size);
      }

      return classes.length ? classes.join(" ") : "medium";
    })
  });
});