define("nimbus/models/publish/review", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The state of the review
     *
     * @type {String}
     */
    state: (0, _attr.default)("string"),

    /**
     * The member the review is for
     *
     * @type {module:app/models/core/member}
     */
    member: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * The variant the review is for
     *
     * @type {module:app/models/publish/variant}
     */
    variant: (0, _relationships.belongsTo)("publish/variant", { async: false })
  });
});