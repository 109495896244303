define("nimbus/services/event-tracker", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var GOOGLEANALYTICS = _environment.default.APP.GOOGLEANALYTICS;
  exports.default = Ember.Service.extend({
    /**
     * @private
     * @type {GoogleAnalyticsService}
     */
    googleAnalytics: Ember.inject.service(),

    /**
     * @private
     * @type {FeatureCheckService}
     */
    feature: Ember.inject.service(),

    /**
     * @public
     * @function
     * @param {String} text
     */
    track: function track(event) {
      var metadata = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      if (GOOGLEANALYTICS) {
        this.googleAnalytics.event("web", event, "", 0, metadata);
      }
    }
  });
});