define("nimbus/models/content/item-enclosure", ["exports", "ember-data/attr", "ember-data/model", "nimbus/config/environment", "nimbus/util/computed"], function (exports, _attr, _model, _environment, _computed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = _model.default.extend({
    /**
     * The URL of the enclosed content.
     *
     * @type {String}
     */
    url: (0, _attr.default)("string"),

    /**
     * The MIME-type of the enclosed content.
     *
     * @type {String}
     */
    type: (0, _attr.default)("string"),

    /**
     * The dimensions of the enclosure.
     *
     * @type {Object}
     */
    dimensions: (0, _attr.default)("object"),

    /**
     * Indicates if the enclosure is an image.
     *
     * @type {Boolean}
     */
    isImage: (0, _computed.pure)("type", function (type) {
      return (type || "").indexOf("image") > -1;
    }),

    /**
     * Indicates if the enclosure is a video.
     *
     * @type {Boolean}
     */
    isVideo: (0, _computed.pure)("type", function (type) {
      return (type || "").indexOf("video") > -1;
    }),

    /**
     * @type {String}
     */
    proxyUrl: (0, _computed.pure)("url", function (url) {
      return HOST + "/proxy?url=" + url;
    })
  });
});