define("nimbus/pods/components/x-cropper/context-decorator", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  var decorate = function decorate(context) {
    context.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
    context.xform = context.svg.createSVGMatrix();
    context.point = null;
    context.savedTransforms = [];

    var originalSave = context.save;
    context.save = function () {
      context.savedTransforms.push(context.xform.translate(0, 0));

      return originalSave.call(context);
    };

    var originalScale = context.scale;
    context.scale = function (sx, sy) {
      context.xform = context.xform.scaleNonUniform(sx, sy);

      return originalScale.call(context, sx, sy);
    };

    var originalTransform = context.transform;
    context.transform = function (a, b, c, d, e, f) {
      var m2 = context.svgElement.createSVGMatrix();
      m2.a = a;
      m2.b = b;
      m2.c = c;
      m2.d = d;
      m2.e = e;
      m2.f = f;
      context.xform = context.xform.multiply(m2);

      return originalTransform.call(context, a, b, c, d, e, f);
    };

    var originalRestore = context.restore;
    context.restore = function () {
      context.xform = context.savedTransforms.pop();

      return originalRestore.call(context);
    };

    var originalRotate = context.rotate;
    context.rotate = function (radians) {
      context.xform = context.xform.rotate(radians * 180 / Math.PI);

      return originalRotate.call(context, radians);
    };

    var originalTranslate = context.translate;
    context.translate = function (dx, dy) {
      context.xform = context.xform.translate(dx, dy);

      return originalTranslate.call(context, dx, dy);
    };

    var originalSetTransform = context.setTransform;
    context.setTransform = function (a, b, c, d, e, f) {
      context.xform.a = a;
      context.xform.b = b;
      context.xform.c = c;
      context.xform.d = d;
      context.xform.e = e;
      context.xform.f = f;

      return originalSetTransform.call(context, a, b, c, d, e, f);
    };

    context.transformedPoint = function (x, y) {
      context.point = context.point || context.svg.createSVGPoint();
      context.point.x = x;
      context.point.y = y;

      return context.point.matrixTransform(context.xform.inverse());
    };

    return context;
  };

  var Handler = function () {
    function Handler() {
      _classCallCheck(this, Handler);

      this.svg = null;
      this.xform = null;
      this.point = null;
      this.savedTransforms = [];

      this.svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      this.xform = this.svg.createSVGMatrix();
    }

    _createClass(Handler, [{
      key: "get",
      value: function get(target, key) {
        return this[key] ? this[key](target) : target[key].bind(target);
      }
    }, {
      key: "save",
      value: function save(target) {
        var _this = this;

        return function () {
          _this.savedTransforms.push(_this.xform.translate(0, 0));

          return target.save();
        };
      }
    }, {
      key: "scale",
      value: function scale(target) {
        var _this2 = this;

        return function (sx, sy) {
          _this2.xform = _this2.xform.scaleNonUniform(sx, sy);

          return target.scale(sx, sy);
        };
      }
    }, {
      key: "transform",
      value: function transform(target) {
        var _this3 = this;

        return function (a, b, c, d, e, f) {
          var m2 = _this3.svgElement.createSVGMatrix();
          m2.a = a;
          m2.b = b;
          m2.c = c;
          m2.d = d;
          m2.e = e;
          m2.f = f;
          _this3.xform = _this3.xform.multiply(m2);

          return target.transform(a, b, c, d, e, f);
        };
      }
    }, {
      key: "restore",
      value: function restore(target) {
        var _this4 = this;

        return function () {
          _this4.xform = _this4.savedTransforms.pop();

          return target.restore();
        };
      }
    }, {
      key: "rotate",
      value: function rotate(target) {
        var _this5 = this;

        return function (radians) {
          _this5.xform = _this5.xform.rotate(radians * 180 / Math.PI);

          return target.rotate(radians);
        };
      }
    }, {
      key: "translate",
      value: function translate(target) {
        var _this6 = this;

        return function (dx, dy) {
          _this6.xform = _this6.xform.translate(dx, dy);

          return target.translate(dx, dy);
        };
      }
    }, {
      key: "setTransform",
      value: function setTransform(target) {
        var _this7 = this;

        return function (a, b, c, d, e, f) {
          _this7.xform.a = a;
          _this7.xform.b = b;
          _this7.xform.c = c;
          _this7.xform.d = d;
          _this7.xform.e = e;
          _this7.xform.f = f;

          return target.setTransform(a, b, c, d, e, f);
        };
      }
    }, {
      key: "transformedPoint",
      value: function transformedPoint() {
        var _this8 = this;

        return function (x, y) {
          _this8.point = _this8.point || _this8.svg.createSVGPoint();
          _this8.point.x = x;
          _this8.point.y = y;

          return _this8.point.matrixTransform(_this8.xform.inverse());
        };
      }
    }]);

    return Handler;
  }();

  exports.default = function (context) {
    return window.Proxy !== undefined ? new window.Proxy(context, new Handler()) : decorate(context);
  };
});