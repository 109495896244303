define("nimbus/mirage/config", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    this.urlPrefix = _environment.default["ember-cli-mirage"].urlPrefix;
    this.namespace = location.hostname.split(".").shift().split("-").shift();

    this.passthrough("https://api.hubspot.com/**");
    this.passthrough("https://forms.hubspot.com/**");
    this.passthrough("https://api.apostleconnect.nl/**");

    this.passthrough(this.urlPrefix + "/assets/**", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/info");
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/features");
    this.passthrough(this.urlPrefix + "/oauth/v2/gateway", ["post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/members/resolve", ["post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/stream");
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/members", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace, ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/reset", ["post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/reset/**", ["post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/users/me", ["get"]);
    this.passthrough(this.urlPrefix + "/users/me", ["get"]);
    this.passthrough(this.urlPrefix + "/users/**", ["put"]);
    this.passthrough(this.urlPrefix + "/invite-token/**", ["get"]);
    this.passthrough(this.urlPrefix + "/teams", ["post"]);
    this.passthrough(this.urlPrefix + "/public-message/**", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/permalink/**", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/members/**", ["get", "delete", "put", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/invites", ["get", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/invites/**", ["get", "delete", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/groups", ["get", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/groups/**", ["get", "delete", "post", "put"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/items", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/items/**", ["get", "post", "delete"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/announcements", ["get", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/announcements/**", ["get", "put", "delete"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/connections", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/connections/**", ["delete", "get", "post"]);
    this.passthrough(this.urlPrefix + "/memberships", ["get"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/subjects", ["get", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/activesubjects", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/subjects/**", ["get", "delete", "put"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/variants", ["get", "post"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/variants/**", ["get", "delete", "put", "post"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/uploads", ["post"]);

    this.passthrough(this.urlPrefix + "/content/metadata", ["get"]);
    this.passthrough(this.urlPrefix + "/" + this.namespace + "/content/push", ["post"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/shares", ["get"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/suggestions", ["get"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/publish/suggestions/**", ["delete", "put", "get"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/statistics/**", ["get"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/messages", ["get", "post"]);

    this.passthrough(this.urlPrefix + "/strategy", ["post"]);

    this.passthrough(this.urlPrefix + "/" + this.namespace + "/messages/**", ["get", "put", "delete", "post"]);
  };
});