define("nimbus/pods/components/x-preview/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The name to display with the preview.
     *
     * @type {String}
     */
    name: null,

    /**
     * The time to display in the preview.
     *
     * @type {Date}
     */
    time: null,

    /**
     * The platform to create the preview for.
     *
     * @type {String}
     */
    platform: null,

    /**
     * The image of the connection.
     *
     * @type {String}
     */
    avatar: null,

    /**
     * Whether or not the preview should be in edit mode
     *
     * @type {Boolean}
     */
    isEditing: false,

    /**
     * @private
     * @type {Boolean}
     */
    isTwitter: Ember.computed.equal("platform", "twitter"),

    /**
     * @private
     * @type {Boolean}
     */
    isFacebook: Ember.computed.equal("platform", "facebook"),

    /**
     * @private
     * @type {Boolean}
     */
    isLinkedin: Ember.computed.equal("platform", "linkedin"),

    /**
     * @private
     * @type {Boolean}
     */
    isInstagram: Ember.computed.equal("platform", "instagram"),

    /**
     * @private
     * @type {Boolean}
     */
    isNative: Ember.computed.equal("platform", "native")
  });
});