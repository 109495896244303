define("nimbus/serializers/publish/share", ["exports", "nimbus/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {DS.Store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeRecord: function normalizeRecord(store, modelClass, payload) {
      var components = [payload["type"], payload["platform"], payload["created_at"], Ember.getWithDefault(payload, "member.uuid", ""), payload["variant"]["uuid"]];

      payload.uuid = btoa(components.join(":"));

      return this._super.apply(this, arguments);
    }
  });
});