define("nimbus/pods/admin/team/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {AjaxService}
     */
    ajax: Ember.inject.service(),

    /**
     * @public
     * @function
     * @override
     * @returns {Promise}
     */
    model: function model() {
      return {
        members: [],
        ghosts: []
      };
    }
  });
});