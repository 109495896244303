define("nimbus/pods/components/x-variant/attachment/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "",

    attachment: null,

    isLink: Ember.computed.equal("attachment.type", "link"),
    isImage: Ember.computed.equal("attachment.type", "image"),
    isVideo: Ember.computed.equal("attachment.type", "video")
  });
});