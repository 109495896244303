define("nimbus/models/social/connection", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model", "nimbus/config/environment"], function (exports, _attr, _relationships, _model, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = _model.default.extend({
    /**
     * The name of the connection.
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    /**
     * The state of the connection.
     *
     * @type {String}
     */
    state: (0, _attr.default)("string"),

    /**
     * The URL of the image of the connection.
     *
     * @type {String}
     */
    imageUrl: (0, _attr.default)("string"),

    /**
     * The platform of the connection.
     *
     * @type {String}
     */
    platform: (0, _attr.default)("string"),

    /**
     * The type of the connection.
     *
     * @type {String}
     */
    type: (0, _attr.default)("string"),

    /**
     * The URL to retrieve the pages for a given connection
     *
     * @type {String}
     */
    pagesUrl: (0, _attr.default)("string"),

    /**
     * The owner of the connection.
     *
     * @type {module:app/models/core/member}
     */
    owner: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * Indicates if the connection is in the `ready` state.
     *
     * @type {Boolean}
     */
    isReady: Ember.computed.equal("state", "ready"),

    /**
     * The safe image URL (proxied with HTTPS).
     *
     * @type {String}
     */
    safeImage: Ember.computed("imageUrl", function () {
      return HOST + "/proxy?url=" + this.imageUrl;
    })
  });
});