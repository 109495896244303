define("nimbus/pods/components/x-skeleton/component", ["exports", "nimbus/util/computed"], function (exports, _computed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    attributeBindings: ["style"],

    /**
     * @private
     * @type {String}
     */
    style: (0, _computed.css)("width", "height", function () {
      var padding = 1 / (this.width / this.height) * 100;

      return { paddingBottom: padding + "%" };
    })
  });
});