define("nimbus/pods/accept/route", ["exports", "nimbus/config/environment", "axios"], function (exports, _environment, _axios) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {SessionService}
     */
    session: Ember.inject.service(),

    /**
     * @private
     * @type {AjaxService}
     */
    ajax: Ember.inject.service(),

    queryParams: {
      uuid: {},
      token: {}
    },

    model: function model(params) {
      var _this = this;

      var url = _environment.default.APP.HOST + "/invite-token/" + params.token;

      /* eslint-disable */
      _axios.default.get(url).then(function (response) {
        if (response.data.data.state === "accepted") {
          _this.transitionTo("accepted");
        } else {
          Ember.set(_this, "session.inviteUuid", params.uuid);
          Ember.set(_this, "session.inviteToken", params.token);
          Ember.set(_this, "session.currentAcceptUser", response.data.data.email);
          _this.transitionTo("setup");
        }
      });
      /* eslint-enable */
    }
  });
});