define("nimbus/pods/components/x-popout/content/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {String}
     */
    tagName: "",

    actions: {
      closePopper: function closePopper() {
        this.onClose();
      }
    }
  });
});