define("nimbus/util/computed/pure", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    for (var _len = arguments.length, dependentKeys = Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }

    var handler = dependentKeys.pop();
    var resultingKeys = [];

    dependentKeys.forEach(function (key) {
      return Ember.expandProperties(key, function (key) {
        resultingKeys.push(key);
      });
    });

    return Ember.computed.apply(undefined, resultingKeys.concat([function () {
      var _this = this;

      var values = resultingKeys.map(function (key) {
        if (key.match(/@each/)) {
          var segments = key.split(".@each.");

          return Ember.get(_this, segments[0]).map(function (value) {
            return Ember.get(value, segments[1]);
          });
        } else if (key.match(/\[\]/)) {
          return Ember.get(_this, key.split(".[]")[0]);
        } else {
          return Ember.get(_this, key);
        }
      });

      return handler.apply(undefined, _toConsumableArray(values));
    }]));
  };

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }
});