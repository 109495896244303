define("nimbus/pods/strategywizard/start/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @private
     * @type {Number}
     */
    industries: 11,

    /**
     * @private
     * @type {Boolean}
     */
    formIsValid: Ember.computed("model.{name,company,industry,email}", function () {
      return this.model.name.length && this.model.company.length && this.model.industry.length && this.validateEmail(this.model.email);
    }),

    /**
     * @private
     * @type {Array<String>}
     */
    industrySelect: Ember.computed(function () {
      var counter = 0;
      var array = [];

      while (counter < this.industries) {
        array.push(this.intl.t("route.strategywizard.start.form.industry.options." + counter));
        counter++;
      }

      array.sort();

      return array;
    }),

    validateEmail: function validateEmail(emailCheck) {
      var emailTest = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      return emailTest.test(emailCheck);
    }
  });
});