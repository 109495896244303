define("nimbus/pods/components/x-data-table/filter/component", ["exports", "@ember-decorators/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var _dec, _class;

  var escape = function escape(s) {
    return s.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  };

  var Filter = (_dec = (0, _component.tagName)(""), _dec(_class = function (_EmberComponent) {
    _inherits(Filter, _EmberComponent);

    function Filter() {
      _classCallCheck(this, Filter);

      return _possibleConstructorReturn(this, (Filter.__proto__ || Object.getPrototypeOf(Filter)).apply(this, arguments));
    }

    _createClass(Filter, [{
      key: "applyFilter",
      value: function applyFilter(model, properties, search) {
        var _this2 = this;

        if ((search || "").length <= 0) {
          return model;
        }

        return model.filter(function (object) {
          return _this2.mode === "all" ? properties.every(function (prop) {
            return _this2.match(object, prop, search);
          }) : properties.any(function (prop) {
            return _this2.match(object, prop, search);
          });
        });
      }
    }, {
      key: "match",
      value: function match(object, property, search) {
        var _this3 = this;

        var value = this.extract(object, property);

        if (value === null || value === undefined) {
          return false;
        }

        return Ember.isArray(value) ? value.any(function (v) {
          return _this3.matchProperty(v, search);
        }) : this.matchProperty(value, search);
      }
    }, {
      key: "extract",
      value: function extract(object, property) {
        return property.split(".").reduce(function (object, p) {
          return Ember.isArray(object) ? object.map(function (o) {
            return Ember.getWithDefault(o, p, undefined);
          }) : Ember.getWithDefault(object, p, undefined);
        }, object);
      }
    }, {
      key: "matchNumber",
      value: function matchNumber(value, search) {
        if (!isNaN(Number.parseInt(value)) && !isNaN(Number.parseInt(search))) {
          return Number.parseInt(value) == Number.parseInt(search);
        }

        return false;
      }
    }, {
      key: "matchString",
      value: function matchString(value, search) {
        var pattern = new RegExp(escape(search || ""), "gi");

        return value.match(pattern);
      }
    }, {
      key: "matchProperty",
      value: function matchProperty(value, search) {
        return this.matchNumber(value, search) || this.matchString(value, search);
      }
    }, {
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        if (!this.properties) {
          Ember.set(this, "filtered", this.model);
          return;
        }

        var properties = this.properties.split(",");

        if (!this.shouldRebuildProperties(properties)) {
          return;
        }

        var observed = this.buildRootObservedProperties(properties);
        var handler = Ember.computed.apply(undefined, _toConsumableArray(observed).concat(["filter", "mode", function () {
          return this.applyFilter(Ember.getWithDefault(this, "model", []), properties, Ember.getWithDefault(this, "filter", undefined));
        }]));

        Ember.defineProperty(this, "filtered", handler);
        this.notifyPropertyChange("filtered");

        Ember.set(this, "observedProperties", this.properties);
      }
    }, {
      key: "buildRootObservedProperties",
      value: function buildRootObservedProperties(paths) {
        var _this4 = this;

        var defined = [];

        return paths.map(function (path) {
          var subkeys = path.split(".");
          var observed = _this4.rebuildIntermediateProperties(subkeys, defined);

          return observed;
        });
      }
    }, {
      key: "shouldRebuildProperties",
      value: function shouldRebuildProperties(paths) {
        return !this.observedProperties || paths !== this.observedProperties;
      }
    }, {
      key: "rebuildIntermediateProperties",
      value: function rebuildIntermediateProperties(keys, defined) {
        var _this5 = this;

        return keys.reduce(function (parent, key) {
          var property = parent + "__" + key;

          if (defined.includes(property)) {
            return property;
          }

          var observed = parent + ".@each." + key;

          var handler = Ember.computed(observed, function () {
            return Ember.getWithDefault(this, parent, []).map(function (element) {
              var result = Ember.isArray(element) ? element.map(function (child) {
                return Ember.getWithDefault(child, key, undefined);
              }) : Ember.getWithDefault(element, key, undefined);

              return result;
            });
          });

          Ember.defineProperty(_this5, property, handler);
          defined.push(property);
          _this5.notifyPropertyChange(property);

          return property;
        }, "model");
      }
    }]);

    return Filter;
  }(Ember.Component)) || _class);
  exports.default = Filter;
});