define("nimbus/pods/admin/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @returns {void}
       */
      onSearch: function onSearch(query) {
        this.transitionToRoute("admin.search", { queryParams: { query: query } });
      }
    }
  });
});