define("nimbus/services/feature", ["exports", "@ember-decorators/service"], function (exports, _service) {
    "use strict";

    Object.defineProperty(exports, "__esModule", {
        value: true
    });
    exports.default = undefined;

    function _initDefineProp(target, property, descriptor, context) {
        if (!descriptor) return;
        Object.defineProperty(target, property, {
            enumerable: descriptor.enumerable,
            configurable: descriptor.configurable,
            writable: descriptor.writable,
            value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
        });
    }

    function _classCallCheck(instance, Constructor) {
        if (!(instance instanceof Constructor)) {
            throw new TypeError("Cannot call a class as a function");
        }
    }

    var _createClass = function () {
        function defineProperties(target, props) {
            for (var i = 0; i < props.length; i++) {
                var descriptor = props[i];
                descriptor.enumerable = descriptor.enumerable || false;
                descriptor.configurable = true;
                if ("value" in descriptor) descriptor.writable = true;
                Object.defineProperty(target, descriptor.key, descriptor);
            }
        }

        return function (Constructor, protoProps, staticProps) {
            if (protoProps) defineProperties(Constructor.prototype, protoProps);
            if (staticProps) defineProperties(Constructor, staticProps);
            return Constructor;
        };
    }();

    function _possibleConstructorReturn(self, call) {
        if (!self) {
            throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
        }

        return call && (typeof call === "object" || typeof call === "function") ? call : self;
    }

    function _inherits(subClass, superClass) {
        if (typeof superClass !== "function" && superClass !== null) {
            throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
        }

        subClass.prototype = Object.create(superClass && superClass.prototype, {
            constructor: {
                value: subClass,
                enumerable: false,
                writable: true,
                configurable: true
            }
        });
        if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
    }

    function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
        var desc = {};
        Object['ke' + 'ys'](descriptor).forEach(function (key) {
            desc[key] = descriptor[key];
        });
        desc.enumerable = !!desc.enumerable;
        desc.configurable = !!desc.configurable;

        if ('value' in desc || desc.initializer) {
            desc.writable = true;
        }

        desc = decorators.slice().reverse().reduce(function (desc, decorator) {
            return decorator(target, property, desc) || desc;
        }, desc);

        if (context && desc.initializer !== void 0) {
            desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
            desc.initializer = undefined;
        }

        if (desc.initializer === void 0) {
            Object['define' + 'Property'](target, property, desc);
            desc = null;
        }

        return desc;
    }

    function _initializerWarningHelper(descriptor, context) {
        throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
    }

    var _desc, _value, _class, _descriptor, _descriptor2;

    var FeatureChecker = (_class = function (_EmberService) {
        _inherits(FeatureChecker, _EmberService);

        function FeatureChecker() {
            var _ref;

            var _temp, _this, _ret;

            _classCallCheck(this, FeatureChecker);

            for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
                args[_key] = arguments[_key];
            }

            return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = FeatureChecker.__proto__ || Object.getPrototypeOf(FeatureChecker)).call.apply(_ref, [this].concat(args))), _this), _initDefineProp(_this, "ajax", _descriptor, _this), _initDefineProp(_this, "context", _descriptor2, _this), _this.promises = {}, _temp), _possibleConstructorReturn(_this, _ret);
        }

        _createClass(FeatureChecker, [{
            key: "check",
            value: function check(feature) {
                var _default = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

                if (this.promises[feature]) {
                    return this.promises[feature];
                }

                var path = "/" + this.context.domain + "/features?feature[]=" + feature;
                this.promises[feature] = this.ajax.request(path).then(function (response) {
                    return response.data[feature] || _default;
                });

                return this.promises[feature];
            }
        }, {
            key: "checkWithToken",
            value: function checkWithToken(feature, token) {
                var _default = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : false;

                if (this.promises[feature]) {
                    return this.promises[feature];
                }

                var path = "/" + this.context.domain + "/features?feature[]=" + feature + "&access_token=" + token;
                this.promises[feature] = this.ajax.request(path).then(function (response) {
                    return response.data[feature] || _default;
                });

                return this.promises[feature];
            }
        }]);

        return FeatureChecker;
    }(Ember.Service), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "ajax", [_service.service], {
        enumerable: true,
        initializer: null
    }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "context", [_service.service], {
        enumerable: true,
        initializer: null
    })), _class);
    exports.default = FeatureChecker;
});