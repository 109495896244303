define("nimbus/serializers/publish/variant", ["exports", "nimbus/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {DS.Snapshot} snapshot
     * @returns {Object}
     */
    serialize: function serialize(snapshot) {
      var serialized = {};

      snapshot.eachAttribute(function (name) {
        var value = snapshot.attr(name);

        if (value != undefined) {
          serialized[name.underscore()] = value;
        }
      });

      serialized["platforms"] = snapshot.attr("platforms");
      serialized["subject"] = snapshot.belongsTo("subject").id;

      serialized["content"] = {};
      serialized["metadata"] = {};
      serialized["content"]["attachment"] = {};

      var content = snapshot.belongsTo("content");
      content.eachAttribute(function (name) {
        var value = content.attr(name);

        if (value != undefined) {
          serialized["content"][name.underscore()] = value;
        }
      });

      var metadata = snapshot.belongsTo("metadata");
      metadata.eachAttribute(function (name) {
        var value = metadata.attr(name);

        if (value != undefined) {
          serialized["metadata"][name.underscore()] = value;
        }
      });

      var attachment = content.belongsTo("attachment");
      attachment.eachAttribute(function (name) {
        var value = attachment.attr(name);

        if (value != undefined) {
          serialized["content"]["attachment"][name.underscore()] = value;
        }
      });

      var suggestions = snapshot.hasMany("suggestions");

      if (suggestions) {
        serialized["suggestions"] = {};

        suggestions.map(function (suggestion) {
          serialized["suggestions"][suggestion.belongsTo("member").id] = [];
          serialized["suggestions"][suggestion.belongsTo("member").id] = {
            priority: suggestion.attr("priority")
          };
        });
      }

      var topics = snapshot.hasMany("topics");

      if (topics) {
        serialized["topics"] = topics.map(function (topic) {
          return topic.id;
        });
      }

      return serialized;
    }
  });
});