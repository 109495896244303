define("nimbus/tailwind/config/tailwind", ["exports", "tailwindcss/plugins/container", "nimbus/tailwind/config/colors", "nimbus/tailwind/config/screens", "nimbus/tailwind/config/fonts", "nimbus/tailwind/config/text-sizes", "nimbus/tailwind/config/font-weights", "nimbus/tailwind/config/line-height", "nimbus/tailwind/config/letter-spacing", "nimbus/tailwind/config/text-colors", "nimbus/tailwind/config/background-colors", "nimbus/tailwind/config/background-size", "nimbus/tailwind/config/border-widths", "nimbus/tailwind/config/border-colors", "nimbus/tailwind/config/border-radius", "nimbus/tailwind/config/width", "nimbus/tailwind/config/height", "nimbus/tailwind/config/min-width", "nimbus/tailwind/config/min-height", "nimbus/tailwind/config/max-width", "nimbus/tailwind/config/max-height", "nimbus/tailwind/config/padding", "nimbus/tailwind/config/margin", "nimbus/tailwind/config/negative-margin", "nimbus/tailwind/config/shadows", "nimbus/tailwind/config/z-index", "nimbus/tailwind/config/opacity", "nimbus/tailwind/config/svg-fill", "nimbus/tailwind/config/svg-stroke"], function (exports, _container, _colors, _screens, _fonts, _textSizes, _fontWeights, _lineHeight, _letterSpacing, _textColors, _backgroundColors, _backgroundSize, _borderWidths, _borderColors, _borderRadius, _width, _height, _minWidth, _minHeight, _maxWidth, _maxHeight, _padding, _margin, _negativeMargin, _shadows, _zIndex, _opacity, _svgFill, _svgStroke) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    colors: _colors.default,
    screens: _screens.default,
    fonts: _fonts.default,
    textSizes: _textSizes.default,
    fontWeights: _fontWeights.default,
    leading: _lineHeight.default,
    tracking: _letterSpacing.default,
    textColors: _textColors.default,
    backgroundColors: _backgroundColors.default,
    backgroundSize: _backgroundSize.default,
    borderWidths: _borderWidths.default,
    borderColors: _borderColors.default,
    borderRadius: _borderRadius.default,
    width: _width.default,
    height: _height.default,
    minWidth: _minWidth.default,
    minHeight: _minHeight.default,
    maxWidth: _maxWidth.default,
    maxHeight: _maxHeight.default,
    padding: _padding.default,
    margin: _margin.default,
    negativeMargin: _negativeMargin.default,
    shadows: _shadows.default,
    zIndex: _zIndex.default,
    opacity: _opacity.default,
    svgFill: _svgFill.default,
    svgStroke: _svgStroke.default,

    modules: {
      appearance: ["responsive"],
      backgroundAttachment: ["responsive"],
      backgroundColors: ["responsive", "hover"],
      backgroundPosition: ["responsive"],
      backgroundRepeat: ["responsive"],
      backgroundSize: ["responsive"],
      borderColors: ["responsive", "hover"],
      borderRadius: ["responsive"],
      borderStyle: ["responsive"],
      borderWidths: ["responsive"],
      cursor: ["responsive"],
      display: ["responsive"],
      flexbox: ["responsive"],
      float: ["responsive"],
      fonts: ["responsive"],
      fontWeights: ["responsive", "hover"],
      height: ["responsive"],
      leading: ["responsive"],
      lists: ["responsive"],
      margin: ["responsive"],
      maxHeight: ["responsive"],
      maxWidth: ["responsive"],
      minHeight: ["responsive"],
      minWidth: ["responsive"],
      negativeMargin: ["responsive"],
      opacity: ["responsive"],
      overflow: ["responsive"],
      padding: ["responsive"],
      pointerEvents: ["responsive"],
      position: ["responsive"],
      resize: ["responsive"],
      shadows: ["responsive"],
      svgFill: [],
      svgStroke: [],
      textAlign: ["responsive"],
      textColors: ["responsive", "hover"],
      textSizes: ["responsive"],
      textStyle: ["responsive", "hover"],
      tracking: ["responsive"],
      userSelect: ["responsive"],
      verticalAlign: ["responsive"],
      visibility: ["responsive"],
      whitespace: ["responsive"],
      width: ["responsive"],
      zIndex: ["responsive"]
    },

    /*
    |-----------------------------------------------------------------------------
    | Plugins                                https://tailwindcss.com/docs/plugins
    |-----------------------------------------------------------------------------
    |
    | Here is where you can register any plugins you'd like to use in your
    | project. Tailwind's built-in `container` plugin is enabled by default to
    | give you a Bootstrap-style responsive container component out of the box.
    |
    | Be sure to view the complete plugin documentation to learn more about how
    | the plugin system works.
    |
    */

    plugins: [(0, _container.default)({
      // center: true,
      // padding: '1rem',
    })],

    /*
    |-----------------------------------------------------------------------------
    | Advanced Options         https://tailwindcss.com/docs/configuration#options
    |-----------------------------------------------------------------------------
    |
    | Here is where you can tweak advanced configuration options. We recommend
    | leaving these options alone unless you absolutely need to change them.
    |
    */

    options: {
      prefix: "",
      important: false,
      separator: ":"
    }
  };
});