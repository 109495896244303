define("nimbus/services/processors/link", ["exports", "nimbus/services/processors/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _base.default.extend({
    /**
     * @private
     * @type {MetadataService}
     */
    metadata: Ember.inject.service(),

    /**
     * @property regex
     * @type RegExp
     */
    regex: Ember.computed(function () {
      var protocol = "(?:(?:[a-z]+:)?//)";
      var auth = "(?:\\S+(?::\\S*)?@)?";
      var host = "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)";
      var domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";
      var tld = "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?";
      var port = "(?::\\d{2,5})?";
      var path = "(?:[/?#][^\\s']*)?";
      var regex = "(?:" + protocol + "|www\\.)" + auth + "(?:localhost|" + host + domain + tld + ")" + port + path;

      return new RegExp(regex, "ig");
    }),

    /**
     * @property httpMatch
     * @type RegExp
     */
    httpMatch: Ember.computed(function () {
      return new RegExp("^(http|https)://", "i");
    }),

    /**
     * @private
     * @param {Module:app/Models/publish/subject}
     */
    process: function process(config) {
      var urls = [];

      if (config.variant.content.body) {
        urls = config.variant.content.body.match(this.regex) || [];
      }

      return this.processMetadata(config, urls);
    },


    /**
     * @method processMetadata
     * @param {Object} config
     * @param {Array} urls
     * @returns {Promise}
     */
    processMetadata: function processMetadata(config, urls) {
      if (urls.length > 0 && config.variant.content.body && !config.variant.content.attachment.url && !config.variant.content.attachment.type) {
        if (urls[0].search(this.httpMatch) === -1) {
          urls[0] = "http://" + urls[0];
        }

        return this.metadata.fetch(urls[0]).then(function (metadata) {
          Ember.set(config, "variant.content.attachment.title", metadata.title);
          Ember.set(config, "variant.content.attachment.url", urls[0]);
          Ember.set(config, "variant.content.attachment.originalUrl", urls[0]);
          Ember.set(config, "variant.content.attachment.image", metadata.thumbnailUrl);
          Ember.set(config, "variant.content.attachment.description", metadata.description);
          Ember.set(config, "variant.content.attachment.type", "link");

          Ember.set(config, "originalMeta.title", metadata.title);
          Ember.set(config, "originalMeta.image", metadata.thumbnailUrl);
          Ember.set(config, "originalMeta.description", metadata.description);

          Ember.set(config, "linkShouldProcess", false);
        });
      }

      return Ember.RSVP.resolve();
    }
  });
});