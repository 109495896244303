define("nimbus/services/validators/facebook", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    metadata: Ember.inject.service("validators/facebook/metadata"),
    attachment: Ember.inject.service("validators/facebook/attachment"),

    validate: function validate(config) {
      return [this.metadata, this.attachment].reduce(function (errors, validator) {
        return errors.concat(validator.validate(config));
      }, []);
    }
  });
});