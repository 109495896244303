define("nimbus/pods/appsetup/done/controller", ["exports", "nimbus/util/platform"], function (exports, _platform) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {Boolean}
     */
    device: Ember.computed(function () {
      return (0, _platform.ios)() ? "ios" : (0, _platform.android)() ? "android" : "other";
    }),

    /**
     * @private
     * @type {Boolean}
     */
    ios: Ember.computed("device", function () {
      return this.device === "ios";
    }),

    /**
     * @private
     * @type {Boolean}
     */
    android: Ember.computed("device", function () {
      return this.device === "android";
    })
  });
});