define("nimbus/pods/admin/insights/index/controller", ["exports", "moment", "nimbus/config/environment"], function (exports, _moment, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var HOST = _environment.default.APP.HOST;
  exports.default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    session: Ember.inject.service(),
    trial: Ember.inject.service(),
    _context: Ember.inject.service("context"),
    notifications: Ember.inject.service("notification-messages"),

    queryParams: ["start", "end", "interval", "subject", "groups"],
    currentView: "activity",
    displayView: "users",
    shouldShowDatePicker: false,
    tempStart: null,
    tempEnd: null,
    isLoading: false,
    compact: true,
    sortColumnMember: "full_name",
    sortColumnDirection: "asc",
    sortColumnConnection: "name",
    sortColumnConnectionDirection: "asc",
    sortColumnLink: "url",
    sortColumnLinkDirection: "asc",

    sortedGroups: Ember.computed.sort("filteredGroups", "dataSorting"),

    sortedSubjects: Ember.computed.sort("filteredSubjects", "dataSorting"),

    sortedMembers: Ember.computed.sort("membersWithLogin", "memberSorting"),

    sortedConnections: Ember.computed.sort("connections", "connectionSorting"),

    sortedLinks: Ember.computed.sort("links", "linkSorting"),

    shortcuts: Ember.computed("model", function () {
      var _ref;

      return _ref = {}, _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.this_week"), {
        start: (0, _moment.default)().startOf("week").toDate(),
        end: (0, _moment.default)().toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_week"), {
        start: (0, _moment.default)().startOf("week").subtract(1, "weeks").toDate(),
        end: (0, _moment.default)().endOf("week").subtract(1, "weeks").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.this_month"), {
        start: (0, _moment.default)().startOf("month").toDate(),
        end: (0, _moment.default)().toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_month"), {
        start: (0, _moment.default)().subtract(1, "months").startOf("month").toDate(),
        end: (0, _moment.default)().subtract(1, "months").endOf("month").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.this_year"), {
        start: (0, _moment.default)().startOf("year").toDate(),
        end: (0, _moment.default)().endOf("year").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_year"), {
        start: (0, _moment.default)().subtract(1, "years").startOf("year").toDate(),
        end: (0, _moment.default)().subtract(1, "years").endOf("year").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.all_time"), {
        start: (0, _moment.default)().set("year", 2016).startOf("year").toDate(),
        end: (0, _moment.default)().endOf("year").toDate()
      }), _defineProperty(_ref, this.intl.t("route.admin.insights.filter.range_presets.last_days"), {
        start: (0, _moment.default)().subtract(7, "days").toDate(),
        end: (0, _moment.default)().toDate()
      }), _ref;
    }),

    activityView: Ember.computed("currentView", function () {
      return this.currentView === "activity";
    }),

    inputView: Ember.computed("currentView", function () {
      return this.currentView === "input";
    }),

    socialView: Ember.computed("currentView", function () {
      return this.currentView === "social";
    }),

    userView: Ember.computed("displayView", function () {
      return this.displayView === "users";
    }),

    pageView: Ember.computed("displayView", function () {
      return this.displayView === "pages";
    }),

    linkView: Ember.computed("displayView", function () {
      return this.displayView === "links";
    }),

    topicView: Ember.computed("displayView", function () {
      return this.displayView === "topics";
    }),

    range: Ember.computed("__start", "__end", function () {
      return {
        start: this.__start ? (0, _moment.default)(this.__start).toDate() : null,
        end: this.__end ? (0, _moment.default)(this.__end).toDate() : null
      };
    }),

    isSubjected: Ember.computed("__original", function () {
      return this.__original ? true : false;
    }),

    isDaily: Ember.computed("__interval", function () {
      return this.__interval === "daily";
    }),

    isWeekly: Ember.computed("__interval", function () {
      return this.__interval === "weekly";
    }),

    isMonthly: Ember.computed("__interval", function () {
      return this.__interval === "monthly";
    }),

    isQuarterly: Ember.computed("__interval", function () {
      return this.__interval === "quarterly";
    }),

    dataSorting: Ember.computed("sortColumn", "sortAscending", function () {
      return ["name:asc"];
    }),

    memberSorting: Ember.computed("sortColumnMember", "sortColumnDirection", function () {
      return [this.sortColumnMember + ":" + this.sortColumnDirection];
    }),

    connectionSorting: Ember.computed("sortColumnConnection", "sortColumnConnectionDirection", function () {
      return [this.sortColumnConnection + ":" + this.sortColumnConnectionDirection];
    }),

    linkSorting: Ember.computed("sortColumnLink", "sortColumnLinkDirection", function () {
      return [this.sortColumnLink + ":" + this.sortColumnLinkDirection];
    }),

    parentsOnly: Ember.computed("topics.@each.{id,parent}", function () {
      return this.topics.filter(function (topic) {
        return topic.parent == "";
      });
    }),

    parentsWithChildren: Ember.computed("parentsOnly.@each", function () {
      var _this = this;

      var populated = new Array();

      this.parentsOnly.forEach(function (parent) {
        // eslint-disable-next-line ember/no-side-effects
        Ember.set(parent, "children", []);

        _this.topics.forEach(function (topic) {
          if (topic.parent === parent.uuid) {
            parent.children.pushObject(topic);
          }
        });

        populated.pushObject(parent);
      });

      return populated;
    }),

    filteredGroups: Ember.computed("model.teamGroups.@each", function () {
      return this.model.teamGroups;
    }),

    filteredSubjects: Ember.computed("model", function () {
      return this.model.subjects;
    }),

    sortIcon: Ember.computed("sortColumnDirection", function () {
      return this.sortColumnDirection === "asc" ? "sort-down" : "sort-up";
    }),

    sortConnectionIcon: Ember.computed("sortColumnConnectionDirection", function () {
      return this.sortColumnConnectionDirection === "asc" ? "sort-down" : "sort-up";
    }),

    sortLinkIcon: Ember.computed("sortColumnLinkDirection", function () {
      return this.sortColumnLinkDirection === "asc" ? "sort-down" : "sort-up";
    }),

    connections: Ember.computed("model", function () {
      return this.model.connections;
    }),

    links: Ember.computed("model", function () {
      return this.model.links;
    }),

    topics: Ember.computed("model", function () {
      return this.model.topics;
    }),

    groupSelectValue: Ember.computed("model", "__groups", function () {
      var _this2 = this;

      var currentGroup = null;

      this.model.teamGroups.forEach(function (group) {
        if (_this2.__groups === group.id) {
          currentGroup = group;
        }
      });

      return currentGroup;
    }),

    subjectSelectValue: Ember.computed("model", "__subject", function () {
      var _this3 = this;

      var currentSubject = null;

      this.model.subjects.forEach(function (subject) {
        if (_this3.__subject === subject.uuid) {
          currentSubject = subject;
        }
      });

      return currentSubject;
    }),

    inputsData: Ember.computed("model", function () {
      var dataText = [];
      var dataVideo = [];
      var dataImage = [];
      var dataLabels = [];

      this.model.series.inputs.forEach(function (input) {
        dataText.push(input.text);
        dataImage.push(input.image);
        dataVideo.push(input.video);
        dataLabels.push(input.label);
      });

      return {
        labels: dataLabels,
        datasets: [{
          label: this.intl.t("route.admin.insights.chart.inputs.image"),
          data: dataImage,
          borderColor: "#F6993F",
          backgroundColor: "#F6993F",
          borderWidth: 2,
          fill: false
        }, {
          label: this.intl.t("route.admin.insights.chart.inputs.video"),
          data: dataVideo,
          borderColor: "#F5245F",
          backgroundColor: "#F5245F",
          borderWidth: 2,
          fill: false
        }, {
          label: this.intl.t("route.admin.insights.chart.inputs.text"),
          data: dataText,
          borderColor: "#784396",
          backgroundColor: "#784396",
          borderWidth: 2,
          fill: false
        }]
      };
    }),

    messagesData: Ember.computed("model", function () {
      var dataShare = [];
      var dataSuggest = [];
      var dataLabels = [];

      this.model.series.inputs.forEach(function (input) {
        dataLabels.push(input.label);
      });

      this.model.series.shares.forEach(function (share) {
        dataShare.push(share.total);
      });

      this.model.series.suggestions.forEach(function (suggestion) {
        dataSuggest.push(suggestion.total);
      });

      return {
        labels: dataLabels,
        datasets: [{
          label: this.intl.t("route.admin.insights.chart.messages.shared"),
          data: dataShare,
          borderColor: "#38C172",
          backgroundColor: "#38C172",
          borderWidth: 2,
          fill: false
        }, {
          label: this.intl.t("route.admin.insights.chart.messages.suggested"),
          data: dataSuggest,
          borderColor: "#3490DC",
          backgroundColor: "#3490DC",
          borderWidth: 2,
          fill: false
        }]
      };
    }),

    shareUrl: Ember.computed("__start", "__end", "__groups", "__subject", "__interval", "session.data.authenticated.access_token", function () {
      if (this.trial.isActive() || this.trial.isDemo()) {
        return " ";
      } else {
        var start = (0, _moment.default)(this.__start).format("YYYY-MM-DD");
        var end = (0, _moment.default)(this.__end).format("YYYY-MM-DD");
        var interval = this.interval;
        var groups = this.__groups;
        var subject = this.__subject;
        var token = Ember.getWithDefault(this, "session.data.authenticated.access_token", null);

        var id = btoa(start + "." + end + "." + interval + "." + groups + "." + subject + "." + token);

        return "https://" + location.hostname + "/insights/" + id;
      }
    }),

    membersWithLogin: Ember.computed("model", function () {
      var _this4 = this;

      var returnValue = [];

      this.model.members.forEach(function (member) {
        var loginDate = (0, _moment.default)(Ember.get(member, "last_login")).toDate();
        var newMember = member;
        newMember.last_datetime = loginDate;
        newMember.last_formatted = (0, _moment.default)(Ember.get(member, "last_login")).format("DD-MM-YYYY");

        _this4.model.pages.forEach(function (page) {
          if (page.member === member.id) {
            newMember.page = page;
          }
        });

        if (!newMember.page) {
          newMember.page = {
            activity: {
              pending: 0,
              removed: 0,
              scheduled: 0,
              sent: 0
            },
            statistics: {
              total: {
                likes: 0,
                replies: 0,
                shares: 0
              },
              linkedin: {
                likes: 0,
                replies: 0,
                shares: 0
              },
              facebook: {
                likes: 0,
                replies: 0,
                shares: 0
              }
            }
          };
        }

        _this4.model.personal.forEach(function (page) {
          if (page.member === member.id) {
            newMember.page.statistics.total.likes += page.statistics.total.likes;
            newMember.page.statistics.total.replies += page.statistics.total.replies;
            newMember.page.statistics.total.shares += page.statistics.total.shares;
            newMember.page.statistics.facebook.likes += page.statistics.facebook.likes;
            newMember.page.statistics.facebook.replies += page.statistics.facebook.replies;
            newMember.page.statistics.facebook.shares += page.statistics.facebook.shares;
            newMember.page.statistics.linkedin.likes += page.statistics.linkedin.likes;
            newMember.page.statistics.linkedin.replies += page.statistics.linkedin.replies;
            newMember.page.statistics.linkedin.shares += page.statistics.linkedin.shares;
          }
        });

        _this4.model.suggestions.forEach(function (suggestion) {
          if (suggestion.member === member.id) {
            newMember.suggestion = suggestion;
          }
        });

        if (!newMember.suggestion) {
          newMember.suggestion = {
            suggestions: 0,
            removed: 0,
            open: 0,
            shares: {
              total: 0,
              facebook: 0,
              linkedin: 0,
              instagram: 0,
              twitter: 0
            }
          };
        }

        _this4.model.inputs.forEach(function (input) {
          if (input.member === member.id) {
            newMember.input = input;
          }
        });

        if (!newMember.input) {
          newMember.input = {
            total: 0,
            image: 0,
            video: 0,
            text: 0
          };
        }

        newMember.page.statistics.total.likes += member.overall.shareMeta.total_likes;
        newMember.page.statistics.total.replies += member.overall.shareMeta.total_comments;

        returnValue.push(newMember);
      });

      return returnValue;
    }),

    actions: {
      setGroup: function setGroup(value) {
        Ember.set(this, "__groups", value === null ? null : value.id);
      },
      setSubject: function setSubject(value) {
        Ember.set(this, "__subject", value === null ? null : value.uuid);
      },
      setView: function setView(view) {
        Ember.set(this, "currentView", view);
      },
      setDisplay: function setDisplay(display) {
        Ember.set(this, "displayView", display);
      },
      setCompact: function setCompact(compact) {
        Ember.set(this, "compact", compact);
      },
      updateReport: function updateReport() {
        var _this5 = this;

        Ember.set(this, "isLoading", true);

        this.transitionToRoute({
          queryParams: {
            start: (0, _moment.default)(this.__start).format("YYYY-MM-DD"),
            end: (0, _moment.default)(this.__end).format("YYYY-MM-DD"),
            interval: this.__interval,
            subject: this.__subject ? this.__subject : null,
            groups: this.__groups ? this.__groups : null
          }
        }).then(function () {
          Ember.set(_this5, "isLoading", false);
          Ember.set(_this5, "currentView", "activity");
        });
      },
      showDatePicker: function showDatePicker(position) {
        Ember.set(this, "tempStart", this.__start);
        Ember.set(this, "tempEnd", this.__end);

        if (position === "start") {
          Ember.set(this, "__start", null);
          Ember.set(this, "__end", null);
        } else if (position === "end") {
          Ember.set(this, "__end", null);
        }

        Ember.set(this, "shouldShowDatePicker", true);
      },
      hideDatePicker: function hideDatePicker() {
        Ember.set(this, "__start", this.tempStart);
        Ember.set(this, "__end", this.tempEnd);

        Ember.set(this, "shouldShowDatePicker", false);
      },
      onChangeRange: function onChangeRange(_ref2) {
        var start = _ref2.start,
            end = _ref2.end;

        Ember.set(this, "__start", start);
        Ember.set(this, "__end", end);

        if (start && end) {
          Ember.set(this, "shouldShowDatePicker", false);

          Ember.set(this, "__interval", this.resolveDefaultInterval(start, end));
        }
      },
      showDetail: function showDetail(member) {
        this.transitionToRoute("/admin/insights/member?member=" + member + "&start=" + (0, _moment.default)(this.__start).format("YYYY-MM-DD") + "&end=" + (0, _moment.default)(this.__end).format("YYYY-MM-DD"));
      },
      sortList: function sortList(column) {
        if (column === this.sortColumnMember) {
          Ember.set(this, "sortColumnDirection", this.sortColumnDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnMember", column);
          Ember.set(this, "sortColumnDirection", column === "full_name" ? "asc" : "desc");
        }
      },
      sortConnections: function sortConnections(column) {
        if (column === this.sortColumnConnection) {
          Ember.set(this, "sortColumnConnectionDirection", this.sortColumnConnectionDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnConnection", column);
          Ember.set(this, "sortColumnConnectionDirection", column === "name" || column === "platform" ? "asc" : "desc");
        }
      },
      sortLinks: function sortLinks(column) {
        if (column === this.sortColumnLink) {
          Ember.set(this, "sortColumnLinkDirection", this.sortColumnLinkDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnLink", column);
          Ember.set(this, "sortColumnLinkDirection", column === "url" ? "asc" : "desc");
        }
      },
      excelExport: function excelExport() {
        if (this.trial.isActive() || this.trial.isDemo()) {
          this.trial.message();
        } else {
          var start = (0, _moment.default)(this.__start).format("YYYY-MM-DD");
          var end = (0, _moment.default)(this.__end).format("YYYY-MM-DD");
          var interval = this.__interval;
          var subject = this.__subject;
          var groups = this.__groups;
          var token = Ember.getWithDefault(this, "session.data.authenticated.access_token", null);

          var url = HOST + "/" + this._context.domain + "/statistics/report?access_token=" + token + "&start=" + start + "&end=" + end + "&interval=" + interval;
          url += subject ? "&subject=" + subject : "";
          url += groups ? "&groups=" + groups : "";

          window.open(url);
        }
      },
      copied: function copied() {
        if (this.trial.isActive() || this.trial.isDemo()) {
          this.trial.message();
        } else {
          this.notifications.success(this.intl.t("route.admin.insights.notification.share"), {
            autoClear: true,
            clearDuration: 1000
          });
        }
      }
    },
    resolveDefaultInterval: function resolveDefaultInterval(start, end) {
      var duration = _moment.default.duration((0, _moment.default)(end).diff((0, _moment.default)(start)));

      if (duration.asMonths() > 6) {
        return "quarterly";
      }

      if (duration.asWeeks() > 8) {
        return "monthly";
      }

      if (duration.asDays() > 21) {
        return "weekly";
      }

      return "daily";
    }
  });
});