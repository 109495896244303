define("nimbus/adapters/core/announcement", ["exports", "nimbus/adapters/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    urlForFindAll: function urlForFindAll() {
      return this._super.apply(this, arguments) + "&all=true";
    },
    pathForType: function pathForType(type) {
      return type.split("/")[1] + "s";
    }
  });
});