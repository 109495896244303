define("nimbus/pods/components/x-variant/collapse/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["isOpen:is-open"],

    /**
     * @private
     * @type {Boolean}
     */
    isOpen: false
  });
});