define("nimbus/pods/components/x-preview/twitter/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    securemedia: Ember.inject.service(),

    isEditing: false,
    name: null,
    avatar: null,
    hasLink: Ember.computed.equal("attachment.type", "link"),
    hasVideo: Ember.computed.equal("attachment.type", "video"),
    hasImage: Ember.computed.equal("attachment.type", "image"),

    image: Ember.computed("attachment.{url,type,image}", function () {
      switch (this.attachment.type) {
        case "link":
          return this.hasYoutube ? null : this.attachment.image;
        case "image":
          return this.attachment.url;
        default:
          return null;
      }
    }),

    mediaUrl: Ember.computed("attachment.url", function () {
      return this.securemedia.secure(this.attachment.url);
    }),

    mediaUrlLink: Ember.computed("attachment.image", function () {
      return this.securemedia.secure(this.attachment.image);
    }),

    youtubeCode: Ember.computed("attachment.url", function () {
      var regex = new RegExp(/^.*(youtu.be\/|v\/|u\/\w\/watch\?v=|\\&v=|\?v=)([^#\\&\\?]*).*/, "i");

      var youtubeObject = Ember.getWithDefault(this, "attachment.url", "").match(regex);

      return youtubeObject ? youtubeObject[2] : "";
    }),

    hasYoutube: Ember.computed("attachment.url", function () {
      var regex = new RegExp(/^.*(youtu.be\/|v\/|u\/\w\/watch\?v=|\\&v=|\?v=)([^#\\&\\?]*).*/, "i");

      return Ember.getWithDefault(this, "attachment.url", "").match(regex) ? true : false;
    }),

    distance: Ember.computed(function () {
      return Math.round(Math.random() * 40);
    }),

    badgeType: Ember.computed("validations.@each.type", function () {
      var hasError = Ember.getWithDefault(this, "validations", []).any(function (validation) {
        return validation.type == "error";
      });

      var hasWarning = Ember.getWithDefault(this, "validations", []).any(function (validation) {
        return validation.type == "warning";
      });

      return hasError ? "error" : hasWarning ? "warning" : null;
    }),

    processed: Ember.computed("body", function () {
      var link = function link() {
        return "t.co/" + (Math.random() + 1).toString(36).substring(2, 21);
      };

      var processed = this.linkify(this.body, function (url) {
        return "<a href=\"" + url + "\" target=\"_blank\">" + link() + "</a>";
      });

      /* eslint-disable */
      // prettier-ignore
      var newline = /(?:\r\n|\r|\n)/g;
      /* eslint-enable */

      return Ember.String.htmlSafe(processed.replace(newline, "<br>"));
    }),

    linkify: function linkify(text, callback) {
      var protocol = "(?:(?:[a-z]+:)?//)";
      var auth = "(?:\\S+(?::\\S*)?@)?";
      var host = "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)";
      var domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";
      var tld = "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?";
      var port = "(?::\\d{2,5})?";
      var path = "(?:[/?#][^\\s']*)?";
      var regex = "(?:" + protocol + "|www\\.)" + auth + "(?:localhost|" + host + domain + tld + ")" + port + path;
      var pattern = new RegExp(regex, "ig");

      return (text || "").replace(pattern, function (match) {
        return callback(match);
      });
    }
  });
});