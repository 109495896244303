define('nimbus/components/notification-container', ['exports', 'ember-cli-notifications/components/notification-container'], function (exports, _notificationContainer) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _notificationContainer.default;
    }
  });
});