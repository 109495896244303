define("nimbus/pods/components/x-reflow/component", ["exports", "bricks.js"], function (exports, _bricks) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The various sizes used to reflow the content.
     *
     * See: https://github.com/callmecavs/bricks.js#sizes
     *
     * @type {Array.<Object>}
     */
    sizes: null,

    /**
     * The models that should be reflowed.
     *
     * @type {Array.<Object>}
     */
    models: null,

    /**
     * The Bricks instance responsible for the reflowing of content.
     *
     * @private
     * @type {Bricks}
     */
    bricks: null,

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    reflow: Ember.observer("models.[]", function () {
      var _this = this;

      Ember.run.schedule("afterRender", function () {
        if (_this.bricks) {
          _this.bricks.pack();
        }
      });
    }),

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      if (this.bricks) {
        this.bricks.pack();
      }
    },


    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    didInsertElement: function didInsertElement() {
      var _this2 = this;

      var sizes = this.sizes || [{ columns: 1, gutter: 10 }, { mq: "940px", columns: 2, gutter: 10 }];

      Ember.set(this, "bricks", new _bricks.default({
        sizes: sizes,
        packed: "reflowed",
        container: this.element
      }));

      Ember.run.schedule("afterRender", function () {
        if (_this2.bricks) {
          _this2.bricks.pack();
        }
      });
    },


    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @override
       * @returns {void}
       */
      pack: function pack() {
        var _this3 = this;

        Ember.run.schedule("afterRender", function () {
          if (_this3.bricks) {
            _this3.bricks.pack();
          }
        });
      }
    }
  });
});