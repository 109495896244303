define("nimbus/tailwind/config/line-height", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    none: 1,
    tight: 1.25,
    normal: 1.5,
    loose: 2,
    avatar: 2.25
  };
});