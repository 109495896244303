define("nimbus/serializers/core/member", ["exports", "nimbus/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {DS.Snapshot} snapshot
     * @returns {Object}
     */
    serialize: function serialize(snapshot) {
      var serialized = {};

      snapshot.eachAttribute(function (name) {
        var value = snapshot.attr(name);

        if (value != undefined) {
          serialized[name.underscore()] = value;
        }
      });

      serialized["groups"] = snapshot.hasMany("groups").map(function (group) {
        return group.id;
      });

      return serialized;
    }
  });
});