define("nimbus/services/shorten", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * @private
     * @type {AjaxService}
     */
    ajax: Ember.inject.service(),

    /**
     * @public
     * @function
     * @param {String} text
     * @returns {Promise}
     */
    shorten: function shorten(text) {
      var data = { text: text };
      var method = "POST";

      return this.ajax.request("/shorten", { data: data, method: method }).then(function (response) {
        return response.data.text;
      });
    }
  });
});