define("nimbus/util/computed/css", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function () {
    for (var _len = arguments.length, dependentKeys = Array(_len), _key = 0; _key < _len; _key++) {
      dependentKeys[_key] = arguments[_key];
    }

    var handler = dependentKeys.pop();

    return Ember.computed.apply(undefined, dependentKeys.concat([function () {
      var cssObject = handler.call(this);

      var result = Object.keys(cssObject).map(function (property) {
        return property.dasherize() + ": " + cssObject[property];
      }).join(";");

      return Ember.String.htmlSafe(result);
    }]));
  };
});