define("nimbus/services/validators/instagram", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    video: Ember.inject.service("validators/instagram/video"),
    attachment: Ember.inject.service("validators/instagram/attachment"),

    validate: function validate(config) {
      return [this.attachment, this.video].reduce(function (errors, validator) {
        return errors.concat(validator.validate(config));
      }, []);
    }
  });
});