define("nimbus/transforms/object", ["exports", "ember-data/transform"], function (exports, _transform) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var mapKeys = function mapKeys(object, map) {
    return Object.keys(object).reduce(function (result, key) {
      return Ember.merge(result, _defineProperty({}, map(object[key], key, object), object[key]));
    }, {});
  };

  var mapValues = function mapValues(object, map) {
    return Object.keys(object).reduce(function (result, key) {
      return Ember.merge(result, _defineProperty({}, key, map(object[key], key, object)));
    }, {});
  };

  /**
   * @module
   * @augments ember-data/transform
   */
  exports.default = _transform.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {Object} serialized
     * @returns {Object}
     */
    deserialize: function deserialize(serialized) {
      var _this = this;

      if (!(serialized instanceof Array) && !(serialized instanceof Object)) {
        return serialized;
      }

      if (serialized instanceof Array) {
        return serialized.map(function (e) {
          return _this.deserialize(e);
        });
      }

      return mapValues(mapKeys(serialized, function (_, key) {
        return Ember.String.camelize(key);
      }), function (value) {
        return _this.deserialize(value);
      });
    },


    /**
     * @private
     * @function
     * @override
     * @param {Object} deserialized
     * @returns {Object}
     */
    serialize: function serialize(deserialized) {
      var _this2 = this;

      if (!(deserialized instanceof Array) && !(deserialized instanceof Object)) {
        return deserialized;
      }

      if (deserialized instanceof Array) {
        return deserialized.map(function (e) {
          return _this2.serialize(e);
        });
      }

      return mapValues(mapKeys(deserialized, function (_, key) {
        return Ember.String.underscore(key);
      }), function (value) {
        return _this2.serialize(value);
      });
    }
  });
});