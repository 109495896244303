define("nimbus/pods/components/x-cropper/component", ["exports", "nimbus/pods/components/x-cropper/context-decorator"], function (exports, _contextDecorator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Cropper = function (_EmberComponent) {
    _inherits(Cropper, _EmberComponent);

    function Cropper() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, Cropper);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Cropper.__proto__ || Object.getPrototypeOf(Cropper)).call.apply(_ref, [this].concat(args))), _this), _this.image = null, _this.canvas = null, _this.context = null, _this.lastX = null, _this.lastY = null, _this.dragStart = null, _this.isDragged = false, _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Cropper, [{
      key: "didInsertElement",
      value: function didInsertElement() {
        var _this2 = this;

        Ember.run(function () {
          _this2.setupCanvas();
          _this2.setupCanvasInteractions();

          if (_this2.onCropperCreated) {
            _this2.onCropperCreated(_this2);
          }
        });
      }
    }, {
      key: "willDestroyElement",
      value: function willDestroyElement() {
        var _this3 = this;

        Ember.run(function () {
          if (_this3.onCropperRemoved) {
            _this3.onCropperRemoved();
          }
        });
      }
    }, {
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        if (!this.image) {
          this.configureImage(this.url);
        } else if (this.image && this.image.src !== this.url) {
          this.configureImage(this.url);
        }
      }
    }, {
      key: "configureImage",
      value: function configureImage(url) {
        var _this4 = this;

        var image = new Image();
        image.crossOrigin = "anonymous";
        image.onload = function () {
          return _this4.redrawCanvas();
        };
        image.src = url;

        Ember.set(this, "image", image);
      }
    }, {
      key: "setupCanvas",
      value: function setupCanvas() {
        var canvas = this.element.querySelector("canvas");
        var context = (0, _contextDecorator.default)(canvas.getContext("2d"));

        Ember.set(this, "canvas", canvas);
        Ember.set(this, "context", context);
      }
    }, {
      key: "setupCanvasInteractions",
      value: function setupCanvasInteractions() {
        var _this5 = this;

        var handlers = _defineProperty({
          mouseup: this.onCanvasMouseUp,
          mouseout: this.onCanvasMouseOut,
          mousedown: this.onCanvasMouseDown,
          mousemove: this.onCanvasMouseMove,
          mousewheel: this.onCanvasScroll
        }, "DOMMouseScroll", this.onCanvasScroll);

        Object.keys(handlers).forEach(function (event) {
          var handler = function handler(e) {
            Ember.run(function () {
              return handlers[event].bind(_this5)(e);
            });
          };

          _this5.canvas.addEventListener(event, handler, false);
        });
      }
    }, {
      key: "zoomCanvas",
      value: function zoomCanvas(clicks) {
        var point = this.context.transformedPoint(this.lastX, this.lastY);
        this.context.translate(point.x, point.y);

        var factor = Math.pow(1.1, clicks);
        this.context.scale(factor, factor);
        this.context.translate(point.x * -1, point.y * -1);
        this.redrawCanvas();
      }
    }, {
      key: "redrawCanvas",
      value: function redrawCanvas() {
        var point0 = this.context.transformedPoint(0, 0);
        var point1 = this.context.transformedPoint(this.canvas.width, this.canvas.height);

        this.context.clearRect(point0.x, point0.y, point1.x - point0.x, point1.y - point0.y);

        this.context.save();
        this.context.setTransform(1, 0, 0, 1, 0, 0);
        this.context.clearRect(0, 0, this.canvas.width, this.canvas.height);
        this.context.restore();

        if (this.image != null) {
          this.context.drawImage(this.image, 0, 0);
        }
      }
    }, {
      key: "onCanvasScroll",
      value: function onCanvasScroll(event) {
        var delta = event.wheelDelta ? event.wheelDelta / 40 : event.detail ? event.detail * -1 : 0;

        if (delta) {
          this.zoomCanvas(delta);
        }

        event.preventDefault();
      }
    }, {
      key: "onCanvasMouseUp",
      value: function onCanvasMouseUp() {
        Ember.set(this, "dragStart", null);
      }
    }, {
      key: "onCanvasMouseOut",
      value: function onCanvasMouseOut() {
        Ember.set(this, "dragStart", null);
      }
    }, {
      key: "onCanvasMouseDown",
      value: function onCanvasMouseDown(event) {
        document.body.style.userSelect = "none";
        document.body.style.mozUserSelect = "none";
        document.body.style.webkitUserSelect = "none";

        var lastY = event.offsetY || event.pageY - this.canvas.offsetTop;
        var lastX = event.offsetX || event.pageX - this.canvas.offsetLeft;
        var dragStart = this.context.transformedPoint(lastX, lastY);

        Ember.setProperties(this, { lastX: lastX, lastY: lastY, dragStart: dragStart, isDragged: false });
      }
    }, {
      key: "onCanvasMouseMove",
      value: function onCanvasMouseMove(event) {
        var lastY = event.offsetY || event.pageY - this.canvas.offsetTop;
        var lastX = event.offsetX || event.pageX - this.canvas.offsetLeft;
        Ember.setProperties(this, { lastX: lastX, lastY: lastY });

        if (this.dragStart != null) {
          Ember.set(this, "isDragged", true);

          var point = this.context.transformedPoint(lastX, lastY);

          this.context.translate(point.x - this.dragStart.x, point.y - this.dragStart.y);

          this.redrawCanvas();
        }
      }
    }]);

    return Cropper;
  }(Ember.Component);

  exports.default = Cropper;
});