define("nimbus/pods/components/x-guru-value/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    tagName: "li",
    classNames: "multi-value__option",
    actions: {
      onRemoveClick: function onRemoveClick() {
        /* eslint-disable */
        this.sendAction("onRemoveClick", Ember.get(this, "value"));
        /* eslint-enable */
      }
    }
  });
});