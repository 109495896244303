define("nimbus/models/core/member", ["exports", "ember-data/relationships", "nimbus/models/core/membership"], function (exports, _relationships, _membership) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _membership.default.extend({
    /**
     * The groups the member belongs to.
     *
     * @type {Array.<module:app/models/core/group>}
     */
    groups: (0, _relationships.hasMany)("core/group", { async: false })
  });
});