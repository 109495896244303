define("nimbus/pods/components/x-paging/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    total: null,

    itemsPerPage: 0,

    currentPage: 0,

    pagesVisible: 5,

    isFetching: false,

    resultStart: Ember.computed("currentPage", "itemsPerPage", function () {
      return (this.currentPage - 1) * this.itemsPerPage + 1;
    }),

    resultEnd: Ember.computed("currentPage", "itemsPerPage", "totalPages", function () {
      return this.currentPage === this.totalPages ? this.total : this.currentPage * this.itemsPerPage;
    }),

    totalPages: Ember.computed("total", function () {
      return Math.ceil(this.total / this.itemsPerPage);
    }),

    nextPage: Ember.computed("currentPage", function () {
      return this.currentPage === this.totalPages ? this.totalPages : this.currentPage + 1;
    }),

    previousPage: Ember.computed("currentPage", function () {
      return this.currentPage <= 1 ? 1 : this.currentPage - 1;
    }),

    nextDisabled: Ember.computed("currentPage", "totalPages", function () {
      return this.currentPage === this.totalPages;
    }),

    previousDisabled: Ember.computed("currentPage", function () {
      return this.currentPage === 1;
    }),

    pageChoices: Ember.computed("currentPage", "totalPages", function () {
      var pageDiff = 2;
      var pageArray = [];

      if (this.totalPages <= this.pagesVisible) {
        for (var i = 1; i <= this.totalPages; i++) {
          pageArray.push(i);
        }

        return pageArray;
      }

      var start = this.currentPage - pageDiff;
      var end = this.currentPage + pageDiff;

      if (start <= 1) {
        start = 1;
      }

      if (end > this.totalPages) {
        var over = end - this.totalPages;
        var helper = over == pageDiff ? over - 1 : over;

        start = this.totalPages + helper - this.pagesVisible;
      }

      for (var _i = 0; _i < this.pagesVisible; _i++) {
        pageArray.push(start + _i);
      }

      return pageArray;
    }),

    actions: {
      setPage: function setPage(page) {
        this.onSetPage(page);
      }
    }
  });
});