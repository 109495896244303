define("nimbus/pods/admin/subjects/details/index/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @public
     * @function
     * @override
     * @returns {void}
     */
    setupController: function setupController(controller, model) {
      this._super.apply(this, arguments);
      Ember.set(controller, "model", model);
      Ember.set(this, "controller.search", "");
      controller.loadSubject.perform();
    },


    actions: {
      demoRouteReset: function demoRouteReset() {
        this.transitionTo("admin.subjects");
      }
    }
  });
});