define("nimbus/pods/user/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {ContextService}
     */
    context: Ember.inject.service(),

    /**
     * @private
     * @type {Boolean}
     */
    isAdmin: Ember.computed.alias("context.membership.isAdmin"),

    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @returns {void}
       */
      onSearch: function onSearch(query) {
        this.transitionToRoute("user.search", { queryParams: { query: query } });
      }
    }
  });
});