define("nimbus/pods/components/x-list/table/entry/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {String}
     */
    tagName: "tr",

    /**
     * @private
     * @type {Array.String}
     */
    classNameBindings: ["viewmode"],

    /**
     * The action invoked when the entry is clicked.
     *
     * @type {Function}
     */
    onClick: null,

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    click: function click() {
      (this.onClick || function () {})();
    }
  });
});