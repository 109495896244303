define("nimbus/mirage/factories/connection", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    name: function name() {
      return _emberCliMirage.faker.name.firstName() + " " + _emberCliMirage.faker.name.lastName();
    },
    uuid: function uuid() {
      return _emberCliMirage.faker.random.uuid();
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.recent();
    },
    platform: function platform() {
      var platforms = ["facebook", "linkedin", "twitter"];

      return platforms[Math.floor(Math.random() * platforms.length)];
    },
    type: function type() {
      var types = ["profile", "page"];

      return types[Math.floor(Math.random() * types.length)];
    },


    state: "ready",

    image_url: function image_url() {
      return _emberCliMirage.faker.internet.avatar();
    },
    self_url: function self_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    },
    delete_url: function delete_url() {
      return "https://api.apostleconnect.nl/localhost/messages/" + this.uuid;
    }
  });
});