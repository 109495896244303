define('nimbus/services/-observer-admin', ['exports', 'ember-in-viewport/services/-observer-admin'], function (exports, _observerAdmin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _observerAdmin.default;
    }
  });
});