define("nimbus/helpers/emojify", ["exports", "nimbus/util/emoji"], function (exports, _emoji) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.emojify = emojify;

  function _toConsumableArray(arr) {
    if (Array.isArray(arr)) {
      for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) {
        arr2[i] = arr[i];
      }

      return arr2;
    } else {
      return Array.from(arr);
    }
  }

  function emojify(params) {
    return params[0].replace(/:(.+?):/gi, function (original, match) {
      return _emoji.emojiMap[match] != null ? String.fromCodePoint.apply(String, _toConsumableArray(_emoji.emojiMap[match])) : original;
    });
  }

  exports.default = Ember.Helper.helper(emojify);
});