define("nimbus/services/composer", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var Composer = function (_EmberService) {
    _inherits(Composer, _EmberService);

    function Composer() {
      _classCallCheck(this, Composer);

      var _this = _possibleConstructorReturn(this, (Composer.__proto__ || Object.getPrototypeOf(Composer)).apply(this, arguments));

      _this.isOpen = false;


      _this.composers = Ember.A([]);
      _this.resetCache();
      return _this;
    }

    _createClass(Composer, [{
      key: "claim",
      value: function claim() {
        this.composers.firstObject.claim();
      }
    }, {
      key: "setCache",
      value: function setCache(body, attachment, subject, topics) {
        Ember.set(this, "cache", { body: body, attachment: attachment, subject: subject, topics: topics });
      }
    }, {
      key: "resetCache",
      value: function resetCache() {
        Ember.set(this, "cache", null);
      }
    }, {
      key: "setContent",
      value: function setContent(body) {
        var attachment = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : undefined;
        var subject = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : undefined;
        var topics = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : undefined;

        if (this.composers.length === 0) {
          this.setCache(body, attachment, subject, topics);
          return;
        }

        this.composers.filter(function (composer) {
          return !composer.isDestroyed && !composer.isDestroying;
        }).forEach(function (composer) {
          Ember.set(composer, "config.variant.content.body", body);

          if (topics) {
            Ember.set(composer, "selectedTopics", topics.toArray());
          }

          if (attachment) {
            Ember.set(composer, "config.variant.content.body", body.replace(attachment.url, attachment.originalUrl));

            var resolved = {
              url: attachment.originalUrl || attachment.url,
              type: attachment.type,
              title: attachment.title || undefined,
              image: attachment.image || undefined,
              originalUrl: attachment.originalUrl || undefined,
              utmSource: attachment.utmSource || undefined,
              utmMedium: attachment.utmMedium || undefined,
              utmName: attachment.utmName || undefined,
              utmTerm: attachment.utmTerm || undefined,
              utmContent: attachment.utmContent || undefined
            };

            Object.keys(resolved).forEach(function (key) {
              Ember.set(composer, "config.variant.content.attachment." + key, resolved[key]);
            });

            Ember.set(composer, "config.originalMeta", resolved);

            if (subject) {
              Ember.set(composer, "config.subject", {
                id: null,
                original: null,
                name: null
              });

              Ember.set(composer, "config.subject.id", subject.id);
              Ember.set(composer, "config.subject.original", subject.id);
              Ember.set(composer, "config.subject.name", subject.name);
            }
          }

          composer.process();
          composer.validate();
        });
      }
    }, {
      key: "registerComposer",
      value: function registerComposer(composer) {
        this.composers.pushObject(composer);

        if (this.cache) {
          this.setContent(this.cache.body, this.cache.attachment, this.cache.subject, this.cache.topics);
          this.resetCache();
        }
      }
    }, {
      key: "unregisterComposer",
      value: function unregisterComposer(composer) {
        this.composers.removeObject(composer);
      }
    }]);

    return Composer;
  }(Ember.Service);

  exports.default = Composer;
});