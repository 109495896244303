define("nimbus/services/trial", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isTrialFeature = _environment.default.isTrialFeature;
  exports.default = Ember.Service.extend({
    notifications: Ember.inject.service("notification-messages"),
    intl: Ember.inject.service(),
    isActive: function isActive() {
      return isTrialFeature;
    },
    isDemo: function isDemo() {
      var demoActive = window.localStorage.getItem("demo:active") ? window.localStorage.getItem("demo:active") : false;

      return demoActive == "true";
    },


    /**
     * @public
     * @function
     * @param {String} text
     * @returns {Promise}
     */
    message: function message() {
      var demoActive = window.localStorage.getItem("demo:active") ? window.localStorage.getItem("demo:active") : false;

      if (demoActive == "true") {
        this.notifications.info(this.intl.t("service.trial.notification.demo"), {
          autoClear: true,
          clearDuration: 1000
        });
      } else {
        this.notifications.info(this.intl.t("service.trial.notification.trial"), {
          autoClear: true,
          clearDuration: 1000
        });
      }
    }
  });
});