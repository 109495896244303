define("nimbus/authenticators/password", ["exports", "ember-simple-auth/authenticators/oauth2-password-grant", "nimbus/config/environment"], function (exports, _oauth2PasswordGrant, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = _oauth2PasswordGrant.default.extend({
    /**
     * @private
     * @type {String}
     */
    serverTokenEndpoint: HOST + "/oauth/v2/gateway"
  });
});