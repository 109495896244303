define("nimbus/services/validators/facebook/metadata", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(config) {
      var metadata_title = Ember.getWithDefault(config, "variant.content.attachment.title", "");
      var metadata_image = Ember.getWithDefault(config, "variant.content.attachment.image", "");

      var original_title = Ember.getWithDefault(config, "originalMeta.title", "");
      var original_image = Ember.getWithDefault(config, "originalMeta.image", "");

      var warning = {
        platform: "facebook",
        type: _validator.VALIDATION_WARNING,
        message: this.intl.t("validation.facebook.metadata")
      };

      return metadata_title === original_title && metadata_image === original_image ? [] : [warning];
    }
  });
});