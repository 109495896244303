define("nimbus/models/strategy/topic", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The name of the topic
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    /**
     * The hex color code for the topic
     *
     * @type {String}
     */
    color: (0, _attr.default)("string"),

    /**
     * The number of posts should be made for a topic in a month
     *
     * @type {Numeric}
     */
    posts: (0, _attr.default)("number"),

    /**
     * Whether members in the groups should be automatically subscribed to the topic
     *
     * @type {String}
     */
    autoSubscribe: (0, _attr.default)("boolean"),

    /**
     * The parent of this topic
     *
     * @type {String}
     */
    parent_id: (0, _attr.default)("string"),

    reach: (0, _attr.default)("number"),

    reachList: (0, _attr.default)("string"),

    /**
     * The groups subscribed to the topic
     *
     * @type {module:app/models/core/group}
     */
    groups: (0, _relationships.hasMany)("core/group", { async: false }),

    /**
     * The members subscribed to the topic
     *
     * @type {module:app/models/core/member}
     */
    members: (0, _relationships.hasMany)("core/member", { async: false }),

    /**
     * Whether or not a member has Facebook
     *
     * @type {Boolean}
     */
    isParent: Ember.computed("parent_id", function () {
      return !this.parent_id || this.parent_id.length === 0;
    }),

    groupList: Ember.computed("groups", function () {
      var value = "";

      this.groups.forEach(function (group) {
        value += group.name;
      });

      return value;
    }),

    // eslint-disable-next-line ember/avoid-leaking-state-in-ember-objects
    children: []
  });
});