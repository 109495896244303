define("nimbus/models/publish/suggestion", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * If the suggestion should has a priority
     *
     * @type {Boolean}
     */
    priority: (0, _attr.default)("boolean"),

    /**
     * The date and time suggestion is scheduled.
     *
     * @type {Date}
     */
    scheduledAt: (0, _attr.default)("date"),

    /**
     * The custom body for a suggestion
     *
     * @type {String}
     */
    body: (0, _attr.default)("string"),

    /**
     * If the suggestion is removed or not
     *
     * @type {Boolean}
     */
    removed: (0, _attr.default)("boolean"),

    /**
     * The member the suggestion is for
     *
     * @type {module:app/models/core/member}
     */
    member: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * The variant the suggestion is for
     *
     * @type {module:app/models/publish/variant}
     */
    variant: (0, _relationships.belongsTo)("publish/variant", { async: false }),

    /**
     * Indicates if the suggestion is shared.
     *
     * @type {Boolean}
     */
    isShared: Ember.computed.gt("shares.length", 0),

    /**
     * The shares of the given suggestion.
     *
     * @type {Array.<module:app/models/publish/share>}
     */
    shares: Ember.computed("member", "variant.shares.length", function () {
      var _this = this;

      return this.variant.shares.filter(function (share) {
        return share.member != null && share.member.id === _this.member.id;
      });
    }),

    /**
     * The platforms the suggestion was shared on.
     *
     * @type {Array.<String>}
     */
    platforms: Ember.computed("shares.@each.platform", function () {
      return this.shares.reduce(function (shares, share) {
        if (!shares[share.platform]) {
          shares[share.platform] = [];
        }

        shares[share.platform] = shares[share.platform].concat(share.createdAt);

        return shares;
      }, {});
    })
  });
});