define("nimbus/pods/components/x-calendar/events/component", ["exports", "moment", "@ember-decorators/object"], function (exports, _moment, _object) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _desc, _value, _class;

  var CalendarEvents = (_dec = (0, _object.computed)("startOfMonth"), _dec2 = (0, _object.computed)("center"), (_class = function (_EmberComponent) {
    _inherits(CalendarEvents, _EmberComponent);

    function CalendarEvents() {
      _classCallCheck(this, CalendarEvents);

      return _possibleConstructorReturn(this, (CalendarEvents.__proto__ || Object.getPrototypeOf(CalendarEvents)).apply(this, arguments));
    }

    _createClass(CalendarEvents, [{
      key: "collectEvents",
      value: function collectEvents(date) {
        var events = this.events.filter(function (event) {
          return (0, _moment.default)(event.start).isSame(date, "day");
        }).reduce(function (result, event) {
          if (result[event.key]) {
            result[event.key].push(event);
          } else {
            result[event.key] = [event];
          }

          return result;
        }, {});

        return Object.keys(events).reduce(function (result, key) {
          return result.concat({
            key: key,
            title: events[key].firstObject.title,
            start: events[key].firstObject.start,
            end: events[key].firstObject.end,
            badge: events[key].map(function (e) {
              return e.badge;
            }).reduce(function (r, b) {
              return r + b;
            }, 0)
          });
        }, []);
      }
    }, {
      key: "displayDays",
      get: function get() {
        var start = this.startOfMonth;

        start = start.isoWeekday() > 1 ? start.subtract(start.isoWeekday() - 1, "days") : start;

        var weeks = [];
        var current = (0, _moment.default)(start);

        for (var i = 0; i < 6; i++) {
          weeks.push([]);

          for (var k = 0; k < 7; k++) {
            var date = current.toDate();
            var events = this.collectEvents(date);
            var hasMore = events.slice(1).length;
            var isMonth = current.isSame(this.center, "month");
            var isToday = current.isSame(new Date(), "day");

            weeks[i].push({
              date: date,
              events: events,
              isMonth: isMonth,
              isToday: isToday,
              hasMore: hasMore
            });

            current.add(1, "days");
          }
        }

        return weeks;
      }
    }, {
      key: "startOfMonth",
      get: function get() {
        return (0, _moment.default)(this.center || new Date()).startOf("month");
      }
    }]);

    return CalendarEvents;
  }(Ember.Component), (_applyDecoratedDescriptor(_class.prototype, "displayDays", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "displayDays"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "startOfMonth", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "startOfMonth"), _class.prototype)), _class));
  exports.default = CalendarEvents;
});