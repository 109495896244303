define("nimbus/services/emitter", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend(Ember.Evented, {
    /**
     * Initialize the service and register the global event handler.
     */
    init: function init() {
      this._super.apply(this, arguments);

      Ember.$(window).bind("storage", Ember.run.bind(this, this._handleEvent));
    },


    /**
     * Emit an event.
     *
     * @param {String} event The name of the event to emit.
     * @param {Object} data  The data to emit for the event.
     */
    emit: function emit(eventName) {
      var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};

      localStorage.setItem("" + eventName, JSON.stringify(data));

      this.trigger(eventName, data);
    },


    /**
     * Handle the given event.
     *
     * @param {jQuery.Event} event
     */
    _handleEvent: function _handleEvent(event) {
      var _event$originalEvent = event.originalEvent,
          key = _event$originalEvent.key,
          newValue = _event$originalEvent.newValue;


      if (key.split(":").shift() !== "event") {
        return;
      }

      localStorage.removeItem(key);

      this.trigger(key.split(":").pop(), JSON.parse(newValue));
    }
  });
});