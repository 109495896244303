define("nimbus/pods/components/x-rolelist/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {String}
     */
    tagName: "",

    /**
     * @private
     * @type {String}
     */
    promoteLevel: "",

    /**
     * @private
     * @type {String}
     */
    demoteLevel: "",

    /**
     * @private
     * @type {Array}
     */
    batchSelection: Ember.computed(function () {
      return [];
    }),

    /**
     * @private
     * @type {Boolean}
     */
    isPromotable: Ember.computed("promoteLevel", function () {
      return this.promoteLevel && this.promoteLevel.length > 0;
    }),

    /**
     * @private
     * @type {Boolean}
     */
    isBatchPromotable: Ember.computed("isPromotable", "batchSelection.@each", function () {
      return this.isPromotable && this.batchSelection.length > 0;
    }),

    /**
     * @private
     * @type {Boolean}
     */
    isDemotable: Ember.computed("demoteLevel", function () {
      return this.demoteLevel && this.demoteLevel.length > 0;
    }),

    /**
     * @private
     * @type {Boolean}
     */
    isBatchDemotable: Ember.computed("isDemotable", "batchSelection.@each", function () {
      return this.isDemotable && this.batchSelection.length > 0;
    }),

    /**
     * @private
     * @type {Array}
     */
    entries: Ember.computed("members.@each", "batchSelection.@each", function () {
      var _this = this;

      return this.members.map(function (member) {
        return {
          member: member,
          isSelected: _this.batchSelection.includes(member)
        };
      });
    }),

    actions: {
      setSelection: function setSelection(member) {
        if (this.batchSelection.includes(member)) {
          this.batchSelection.removeObject(member);
        } else {
          this.batchSelection.pushObject(member);
        }
      },
      batchAction: function batchAction(level) {
        var _this2 = this;

        this.batchSelection.map(function (item) {
          _this2.onChange(item, level);
        });

        Ember.set(this, "batchSelection", []);
      },


      /**
       * @private
       * @param {module:app/models/core/member} member
       * @param {String} level
       */
      change: function change(member, level) {
        this.onChange(member, level);
      }
    }
  });
});