define("nimbus/pods/admin/messages/details/pages/controller", ["exports", "@ember-decorators/object", "@ember-decorators/service"], function (exports, _object, _service) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _desc, _value, _class, _descriptor, _descriptor2, _descriptor3;

  var Pages = (_dec = (0, _object.computed)("selected.length", "model.length"), _dec2 = (0, _object.computed)("model.@each.{state,isDestroyed}"), (_class = function (_EmberController) {
    _inherits(Pages, _EmberController);

    function Pages() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, Pages);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Pages.__proto__ || Object.getPrototypeOf(Pages)).call.apply(_ref, [this].concat(args))), _this), _initDefineProp(_this, "router", _descriptor, _this), _initDefineProp(_this, "composer", _descriptor2, _this), _initDefineProp(_this, "intl", _descriptor3, _this), _this.selected = [], _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Pages, [{
      key: "delete",
      value: function _delete(message) {
        var _this2 = this;

        if (confirm(this.intl.t("route.admin.stream.action.remove.confirm"))) {
          Ember.set(message, "isDeleting", true);
          message.destroyRecord();

          Ember.run.later(function () {
            _this2.model.removeObject(message);
          }, 300);
        }
      }
    }, {
      key: "select",
      value: function select(message) {
        Ember.set(message, "isSelected", true);
        this.selected.pushObject(message);
      }
    }, {
      key: "deselect",
      value: function deselect(message) {
        Ember.set(message, "isSelected", false);
        this.selected.removeObject(message);
      }
    }, {
      key: "deselectAll",
      value: function deselectAll() {
        this.selected.forEach(function (message) {
          Ember.set(message, "isSelected", false);
        });

        Ember.set(this, "selected", []);
      }
    }, {
      key: "selectAll",
      value: function selectAll() {
        var _this3 = this;

        this.model.forEach(function (message) {
          Ember.set(message, "isSelected", true);
          _this3.selected.pushObject(message);
        });
      }
    }, {
      key: "removeAll",
      value: function removeAll() {
        this.selected.forEach(function (message) {
          Ember.set(message, "isDeleting", true);
          message.destroyRecord();
        });

        Ember.set(this, "selected", []);
      }
    }, {
      key: "clone",
      value: function clone(message) {
        var type = message.isVideo ? "video" : message.isImage ? "image" : message.isLink ? "link" : "text";

        var attachment = {
          url: message.link ? message.link : message.mediaUrl,
          type: type,
          image: message.mediaUrl,
          title: message.title
        };

        this.composer.setContent(message.body, attachment, message.subject);

        Ember.set(this, "composer.isOpen", true);
        this.router.transitionTo("admin");
      }
    }, {
      key: "isAllSelected",
      get: function get() {
        return this.selected.length === this.model.length;
      }
    }, {
      key: "filteredModel",
      get: function get() {
        Ember.set(this, "model.isSelected", false);

        return this.model.filter(function (m) {
          return !m.isDestroyed;
        }).filter(function (m) {
          return m.state !== "removed";
        });
      }
    }]);

    return Pages;
  }(Ember.Controller), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "router", [_service.service], {
    enumerable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "composer", [_service.service], {
    enumerable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    enumerable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "isAllSelected", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "isAllSelected"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "filteredModel", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "filteredModel"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "delete", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "delete"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "select", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "select"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deselect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "deselectAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "deselectAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "selectAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "selectAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeAll", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeAll"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "clone", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "clone"), _class.prototype)), _class));
  exports.default = Pages;
});