define("nimbus/pods/components/x-panel/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["isPadded:is-padded"],

    /**
     * Indicates if padding should be applied to the panel.
     *
     * @type {Boolean}
     */
    isPadded: true
  });
});