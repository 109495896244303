define("nimbus/router", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var location = _environment.default.locationType,
      rootURL = _environment.default.rootURL;


  var Router = Ember.Router.extend({ location: location, rootURL: rootURL });

  Router.map(function () {
    this.route("accept");
    this.route("accepted");
    this.route("connect");
    this.route("reconnect");
    this.route("setup", function () {
      this.route("done");
      this.route("password");
      this.route("pages", function () {
        this.route("connect");
      });
    });

    this.route("appsetup", function () {
      this.route("done");
      this.route("password");
      this.route("pages", function () {
        this.route("connect");
      });
    });

    this.route("media");
    this.route("reset");
    this.route("logout");
    this.route("report", { path: "/report/:id" });
    this.route("insights", { path: "/insights/:id" });
    this.route("login", function () {
      this.route("reset", function () {});
    });

    this.route("admin", function () {
      this.route("upload");
      this.route("search");
      this.route("create-post");
      this.route("stream", { path: "/" });

      this.route("messages", function () {
        this.route("calendar");

        this.route("details", { path: "/:id" }, function () {
          this.route("pages");
        });
      });

      this.route("subjects", function () {
        this.route("calendar");

        this.route("details", { path: "/:id" }, function () {
          this.route("pages");
        });
      });

      this.route("team", function () {
        this.route("roles");
        this.route("pages", function () {
          this.route("connect");
        });
        this.route("invites", function () {
          this.route("create");
        });

        this.route("groups", function () {
          this.route("create");
        });
        this.route("ghosts");
        this.route("settings");
      });

      this.route("statistics", function () {
        this.route("leaderboard");
      });

      this.route("insights", function () {
        this.route("member");
      });

      this.route("announcements", function () {
        this.route("create");
      });

      this.route("strategy", function () {
        this.route("detail");
      });

      this.route("newteamcreation");

      this.route("services");
    });

    this.route("user", { path: "/" }, function () {
      this.route("help");
      this.route("search");
      this.route("profile");
      this.route("pages", function () {
        this.route("connect");
      });
      this.route("compose");
      this.route("shares", function () {
        this.route("details", { path: "/:id" });
      });
    });

    this.route("strategywizard", function () {
      this.route("start", { path: "/" });
      this.route("role");
      this.route("goals");
      this.route("channels");
      this.route("summary");
    });

    this.route("message");

    this.route("review", function () {
      this.route("detail", { path: "/" });
    });
  });

  exports.default = Router;
});