define("nimbus/models/publish/share", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The platform the user has shared to
     *
     * @type {String}
     */
    platform: (0, _attr.default)("string"),

    /**
     * The type of the platform the user has shared to
     *
     * @type {String}
     */
    type: (0, _attr.default)("string"),

    /**
     * The member the share has been done by
     *
     * @type {module:app/models/core/member}
     */
    member: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * The variant that has been shared
     *
     * @type {module:app/models/publish/variant}
     */
    variant: (0, _relationships.belongsTo)("publish/variant", { async: false })
  });
});