define("nimbus/pods/admin/team/groups/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @public
     * @function
     * @override
     * @returns {Promise}
     */
    model: function model() {
      return Ember.RSVP.hash({
        groups: [],
        members: []
      });
    }
  });
});