define("nimbus/services/metadata", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * @private
     * @type {AjaxService}
     */
    ajax: Ember.inject.service(),

    /**
     * @public
     * @function
     * @param {String} url
     * @returns {Promise}
     */
    fetch: function fetch(url) {
      return this.ajax.request("/content/metadata", { data: { url: url } }).then(function (_ref) {
        var data = _ref.data;
        return {
          url: url,
          title: Ember.get(data, "title"),
          description: Ember.get(data, "description"),
          thumbnailUrl: Ember.get(data, "thumbnail_url")
        };
      });
    }
  });
});