define("nimbus/pods/components/x-consent/component", ["exports", "ember-ajax/errors", "nimbus/config/environment"], function (exports, _errors, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    router: Ember.inject.service(),
    ajax: Ember.inject.service(),
    session: Ember.inject.service(),

    showConsent: false,

    didInsertElement: function didInsertElement() {
      var _this = this;

      this.ajax.request(this.consentUrl(), { method: "GET", headers: this.headers() }).then(function () {
        // Do nothing, left blank
      }).catch(function (error) {
        if ((0, _errors.isNotFoundError)(error) || (0, _errors.isForbiddenError)(error)) {
          Ember.set(_this, "showConsent", true);
        }
      });
    },


    actions: {
      accept: function accept() {
        var _this2 = this;

        this.ajax.request(this.consentUrl(), {
          method: "PUT",
          headers: this.headers()
        }).then(function () {
          Ember.set(_this2, "showConsent", false);
          location.reload();
        });
      },
      decline: function decline() {
        this.router.transitionTo("logout");
      }
    },

    consentUrl: function consentUrl() {
      return _environment.default.APP.HOST + "/users/me/consent";
    },

    headers: function headers() {
      return {
        Authorization: "Bearer " + Ember.get(this, "session.data.authenticated.access_token")
      };
    }
  });
});