define("nimbus/pods/components/x-date-range-picker/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["isStandalone:standalone"],

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    didReceiveAttrs: function didReceiveAttrs() {
      Ember.set(this, "minRange", Ember.getWithDefault(this, "minRange", 1));
      Ember.set(this, "maxRange", Ember.getWithDefault(this, "maxRange", null));
    },


    actions: {
      onSelectDate: function onSelectDate(range) {
        (this.onChange || function () {})(range);
      }
    }
  });
});