define("nimbus/authenticators/token", ["exports", "ember-simple-auth/authenticators/base"], function (exports, _base) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  exports.default = _base.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {String} token
     * @returns {Promise}
     */
    authenticate: function authenticate(token) {
      var _EmberRSVPResolve;

      return Ember.RSVP.resolve((_EmberRSVPResolve = {}, _defineProperty(_EmberRSVPResolve, "expires_at", new Date(Date.now() + 604800 * 1000)), _defineProperty(_EmberRSVPResolve, "access_token", token), _EmberRSVPResolve));
    },


    /**
     * @private
     * @function
     * @override
     * @param {Object} data
     * @returns {Promise}
     */
    restore: function restore(data) {
      return Ember.RSVP.resolve(data);
    }
  });
});