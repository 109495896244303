define("nimbus/services/securemedia", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = Ember.Service.extend({
    /**
     * @public
     * @function
     * @param {String} text
     * @return {String}
     */
    secure: function secure(url) {
      return HOST + "/proxy?url=" + url;
    }
  });
});