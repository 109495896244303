define("nimbus/serializers/core/membership", ["exports", "nimbus/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {DS.Snapshot} snapshot
     * @returns {Object}
     */
    serialize: function serialize(snapshot) {
      var serialized = {};

      snapshot.eachAttribute(function (name) {
        var value = snapshot.attr(name);

        if (value != undefined) {
          serialized[name.underscore()] = value;
        }
      });

      serialized["pages_auto_approve"] = snapshot.record.hasPagesAutoApprove;
      serialized["new_content_notif"] = snapshot.record.hasNewContentNotif;

      return serialized;
    }
  });
});