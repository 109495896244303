define("nimbus/pods/components/x-composer/other/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Boolean}
     */
    isToggled: false,

    /**
     * @private
     * @type {String}
     */
    body: null,

    /**
     * @private
     * @type {Number}
     */
    counter: Ember.computed("body", function () {
      return Ember.getWithDefault(this, "body", "").length;
    }),

    hasError: Ember.computed("counter", function () {
      return this.counter > 250;
    }),

    actions: {
      toggle: function toggle() {
        Ember.set(this, "isToggled", true);
      },
      fullToggle: function fullToggle() {
        Ember.set(this, "isToggled", !this.isToggled);
      },
      keyUp: function keyUp(event) {
        this.updateTextCursorPosition(event.target);
      },
      mousedown: function mousedown(event) {
        this.updateTextCursorPosition(event.target);
      }
    },

    updateTextCursorPosition: function updateTextCursorPosition(_ref) {
      var selectionStart = _ref.selectionStart;

      Ember.set(this, "textCursor", selectionStart);
      // this.onKeyUp();
    }
  });
});