define("nimbus/services/validator", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    twitter: Ember.inject.service("validators/twitter"),
    facebook: Ember.inject.service("validators/facebook"),
    linkedin: Ember.inject.service("validators/linkedin"),
    instagram: Ember.inject.service("validators/instagram"),

    validate: function validate(config) {
      return [this.twitter, this.facebook, this.linkedin, this.instagram].reduce(function (errors, validator) {
        return errors.concat(validator.validate(config));
      }, []);
    }
  });
});