define("nimbus/pods/user/profile/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @function
     * @override
     * @returns {Promise}
     */
    model: function model() {
      return this.store.findAll("core/membership");
    }
  });
});