define("nimbus/pods/components/x-composer/uploader/progress/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Numeric}
     */
    progress: 0,

    /**
     * @private
     * @type {String}
     */
    loadProgress: Ember.computed("progress", function () {
      return Ember.String.htmlSafe("width: " + this.progress + "%");
    })
  });
});