define("nimbus/pods/components/x-report/inputs/component", ["exports", "moment", "@ember-decorators/object", "@ember-decorators/service"], function (exports, _moment, _object, _service) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _class, _descriptor;

  var Activity = (_dec = (0, _object.computed)("model.@each.{timestamp,text,image,video,other}"), _dec2 = (0, _object.computed)("model.@each.{image,video,text,other}"), _dec3 = (0, _object.computed)("model.platforms.{image,facebook,linkedin,instagram}"), _dec4 = (0, _object.computed)("interval"), (_class = function (_EmberComponent) {
    _inherits(Activity, _EmberComponent);

    function Activity() {
      var _ref;

      var _temp, _this, _ret;

      _classCallCheck(this, Activity);

      for (var _len = arguments.length, args = Array(_len), _key = 0; _key < _len; _key++) {
        args[_key] = arguments[_key];
      }

      return _ret = (_temp = (_this = _possibleConstructorReturn(this, (_ref = Activity.__proto__ || Object.getPrototypeOf(Activity)).call.apply(_ref, [this].concat(args))), _this), _initDefineProp(_this, "intl", _descriptor, _this), _temp), _possibleConstructorReturn(_this, _ret);
    }

    _createClass(Activity, [{
      key: "activity",
      get: function get() {
        var _this2 = this;

        var texts = this.model.map(function (s) {
          return { x: s.timestamp, y: s.text };
        });
        var others = this.model.map(function (s) {
          return { x: s.timestamp, y: s.other };
        });
        var images = this.model.map(function (s) {
          return { x: s.timestamp, y: s.image };
        });
        var videos = this.model.map(function (s) {
          return { x: s.timestamp, y: s.video };
        });
        var total = this.model.map(function (s) {
          return {
            x: s.timestamp,
            y: s.text + s.other + s.image + s.video
          };
        });

        var labels = [].concat(texts.map(function (s) {
          return s.x;
        }), others.map(function (s) {
          return s.x;
        }), images.map(function (s) {
          return s.x;
        }), videos.map(function (s) {
          return s.x;
        }), total.map(function (s) {
          return s.x;
        })).uniq();

        var series = [{
          name: this.intl.t("component.x-report.inputs.types.total"),
          data: total,
          color: "#6574CD"
        }, {
          name: this.intl.t("component.x-report.inputs.types.image"),
          data: images,
          color: "#38C172"
        }, {
          name: this.intl.t("component.x-report.inputs.types.video"),
          data: videos,
          color: "#3490DC"
        }];

        if (texts.filter(function (s) {
          return s.y > 0;
        }).length > 0) {
          series.pushObject({
            name: this.intl.t("component.x-report.inputs.types.text"),
            data: texts,
            color: "#F6993F"
          });
        }

        if (others.filter(function (s) {
          return s.y > 0;
        }).length > 0) {
          series.pushObject({
            name: this.intl.t("component.x-report.inputs.types.other"),
            data: others,
            color: "#E3342F"
          });
        }

        var data = { labels: labels, series: series };

        var options = {
          chartPadding: 30,
          fillWidth: true,
          axisY: {
            showGrid: true,
            onlyInteger: true
          },
          axisX: {
            showGrid: true,
            onlyInteger: true,
            labelInterpolationFnc: function labelInterpolationFnc(d) {
              return (0, _moment.default)(d).format(_this2.format);
            }
          }
        };

        return { data: data, options: options };
      }
    }, {
      key: "types",
      get: function get() {
        var _this3 = this;

        var colors = {
          text: "#F6993F",
          other: "#E3342F",
          image: "#38C172",
          video: "#3490DC"
        };

        var names = {
          text: this.intl.t("component.x-report.inputs.types.text"),
          other: this.intl.t("component.x-report.inputs.types.other"),
          image: this.intl.t("component.x-report.inputs.types.image"),
          video: this.intl.t("component.x-report.inputs.types.video")
        };

        var series = [this.intl.t("component.x-report.inputs.types.image"), this.intl.t("component.x-report.inputs.types.video"), this.intl.t("component.x-report.inputs.types.other"), this.intl.t("component.x-report.inputs.types.text")].map(function (type) {
          return {
            name: Ember.getWithDefault(names, type, ""),
            color: Ember.getWithDefault(colors, type, "rgb(200, 200, 200)"),
            value: _this3.model.reduce(function (c, s) {
              return c + Ember.getWithDefault(s, type, 0);
            }, 0)
          };
        }).filter(function (s) {
          return s.value > 0;
        });

        var total = series.reduce(function (c, v) {
          return c + v.value;
        }, 0);
        var options = {
          donut: true,
          chartPadding: 30,
          labelInterpolationFnc: function labelInterpolationFnc(v) {
            return Math.round(v / total * 100) + "%";
          }
        };

        return { series: series, options: options };
      }
    }, {
      key: "platforms",
      get: function get() {
        var _this4 = this;

        var colors = {
          image: "#1da1f2",
          facebook: "#3b5998",
          linkedin: "#0077b5",
          instagram: "#c13584"
        };

        var names = {
          image: this.intl.t("component.x-report.inputs.types.photos"),
          facebook: this.intl.t("component.x-report.inputs.types.videos"),
          instagram: this.intl.t("component.x-report.inputs.types.text"),
          linkedin: this.intl.t("component.x-report.inputs.types.other")
        };

        var series = ["image", "facebook", "linkedin", "instagram"].map(function (platform) {
          return {
            name: Ember.getWithDefault(names, platform, ""),
            color: Ember.getWithDefault(colors, platform, "rgb(200, 200, 200)"),
            value: Ember.getWithDefault(_this4.model, "platforms." + platform, 0),
            className: _this4.styleNamespace + "__chart__platforms__" + platform
          };
        }).filter(function (s) {
          return s.value > 0;
        });

        var total = series.reduce(function (c, v) {
          return c + v.value;
        }, 0);
        var options = {
          donut: true,
          chartPadding: 30,
          labelInterpolationFnc: function labelInterpolationFnc(v) {
            return Math.round(v / total * 100) + "%";
          }
        };

        return { series: series, options: options };
      }
    }, {
      key: "format",
      get: function get() {
        switch (this.interval) {
          case "daily":
            return "MMM D";
          case "weekly":
            return "[W]ww";
          case "monthly":
            return "YYYY-MM";
          case "quarterly":
            return "YYYY [Q]Q";
        }
      }
    }]);

    return Activity;
  }(Ember.Component), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    enumerable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "activity", [_dec], Object.getOwnPropertyDescriptor(_class.prototype, "activity"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "types", [_dec2], Object.getOwnPropertyDescriptor(_class.prototype, "types"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "platforms", [_dec3], Object.getOwnPropertyDescriptor(_class.prototype, "platforms"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "format", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "format"), _class.prototype)), _class));
  exports.default = Activity;
});