define("nimbus/models/social/message", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The body of the message.
     *
     * @type {String}
     */
    body: (0, _attr.default)("string"),

    /**
     * A link attached to the message.
     *
     * @type {String}
     */
    link: (0, _attr.default)("string"),

    /**
     * A title for the link attached to the message.
     *
     * @type {String}
     */
    title: (0, _attr.default)("string"),

    /**
     * A description for the link attached to the message.
     *
     * @type {String}
     */
    description: (0, _attr.default)("string"),

    /**
     * The URL for an image or video attached to the message.
     *
     * @type {String}
     */
    mediaUrl: (0, _attr.default)("string"),

    /**
     * The MIME-type of the image or video attached to the message.
     *
     * @type {String}
     */
    mimeType: (0, _attr.default)("string"),

    /**
     * The state of the message.
     *
     * @type {String}
     */
    state: (0, _attr.default)("string"),

    /**
     * The state of the message.
     *
     * @type {String}
     */
    masterMessage: (0, _attr.default)("string"),

    /**
     * The state of the message.
     *
     * @type {String}
     */
    customMailSubject: (0, _attr.default)("string"),

    /**
     * The date and time the message was scheduled for publishing.
     *
     * @type {Date}
     */
    scheduledAt: (0, _attr.default)("date"),

    /**
     * The date and time the message was published.
     *
     * @type {Date}
     */
    sentAt: (0, _attr.default)("date"),

    /**
     * The URL to send accept a message
     *
     * @type {String}
     */
    acceptUrl: (0, _attr.default)("string"),

    /**
     * The amount of likes for the message
     *
     * @type {Number}
     */
    likes: (0, _attr.default)("number"),

    /**
     * The amount of comments for the message
     *
     * @type {Number}
     */
    comments: (0, _attr.default)("number"),

    /**
     * The amount of shares for the message
     *
     * @type {Number}
     */
    shares: (0, _attr.default)("number"),

    /**
     * The member that created the message.
     *
     * @type {module:app/models/core/member}
     */
    createdBy: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * The member that removed the message.
     *
     * @type {module:app/models/core/member}
     */
    removedBy: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * The connection the message is for.
     *
     * @type {module:app/models/social/connection}
     */
    connection: (0, _relationships.belongsTo)("social/connection", { async: false }),

    /**
     * The subject the message is for.
     *
     * @type {module:app/models/publish/subject}
     */
    subject: (0, _relationships.belongsTo)("publish/subject", { async: false }),

    /**
     * The suggestions of the variant.
     *
     * @type {Array.<module:app/models/strategy/topic>}
     */
    topics: (0, _relationships.hasMany)("strategy/topic", {
      async: false
    }),

    /**
     * Indicates if the message is in the `pending` state.
     *
     * @type {Boolean}
     */
    isPending: Ember.computed.equal("state", "pending"),

    /**
     * Indicates if the message is in the `scheduled` state.
     *
     * @type {Boolean}
     */
    isScheduled: Ember.computed.equal("state", "scheduled"),

    /**
     * Indicates if the message is in the `sent` state.
     *
     * @type {Boolean}
     */
    isSent: Ember.computed.equal("state", "sent"),

    /**
     * Indicates if the message is in the `deleted` state.
     *
     * @type {Boolean}
     */
    isDeleted: Ember.computed.equal("state", "deleted"),

    /**
     * @type {Boolean}
     */
    isImage: Ember.computed("mimeType", function () {
      return this.mimeType && this.mimeType.indexOf("image") >= 0;
    }),

    /**
     * @type {Boolean}
     */
    isVideo: Ember.computed("mimeType", function () {
      return this.mimeType && this.mimeType.indexOf("video") >= 0;
    }),

    /**
     * @type {Boolean}
     */
    isLink: Ember.computed("link", function () {
      return this.link && this.link.length > 0 ? true : false;
    }),

    /**
     * @type {Boolean}
     */
    isText: Ember.computed("isImage,isVideo,isLink", function () {
      return !(this.isImage || this.isVideo || this.isLink);
    }),

    /**
     * The current timestamp of the message.
     *
     * @type {Date}
     */
    timestamp: Ember.computed("{state,updatedAt,scheduledAt,sentAt}", function () {
      switch (this.state) {
        case "sent":
          return this.sentAt;
        case "pending":
        case "scheduled":
          return this.scheduledAt;
      }

      return this.updatedAt;
    })
  });
});