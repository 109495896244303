define('nimbus/tests/mirage/mirage.lint-test', [], function () {
  'use strict';

  QUnit.module('ESLint | mirage');

  QUnit.test('mirage/config.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/config.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/connection.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/connection.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/membership.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/membership.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/pending-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/pending-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/removed-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/removed-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/scheduled-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/scheduled-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/sent-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/sent-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/team.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/team.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/factories/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/factories/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/connection.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/connection.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/membership.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/membership.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/pending-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/pending-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/removed-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/removed-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/scheduled-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/scheduled-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/sent-message.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/sent-message.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/team.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/team.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/models/user.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/models/user.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/scenarios/default.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/scenarios/default.js should pass ESLint\n\n');
  });

  QUnit.test('mirage/serializers/application.js', function (assert) {
    assert.expect(1);
    assert.ok(true, 'mirage/serializers/application.js should pass ESLint\n\n');
  });
});