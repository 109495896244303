define("nimbus/pods/admin/newteamcreation/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {AjaxService}
     */
    ajax: Ember.inject.service()
  });
});