define("nimbus/mirage/scenarios/default", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  exports.default = function (server) {
    /*
      Seed your development database using your factories.
      This data will not be loaded in your tests.
    */

    server.createList("user", 5);
    server.createList("membership", 5);
    server.createList("connection", 4);
    server.createList("pending-message", 10);
    server.createList("sent-message", 10);
    server.createList("scheduled-message", 10);
    server.createList("removed-message", 10);
  };
});