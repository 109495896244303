define("nimbus/pods/components/x-list/filter/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * The filter to apply.
     *
     * @type {String}
     */
    query: undefined,

    /**
     * The action to call when the filter changes.
     *
     * @type {Function}
     */
    onFilter: undefined,

    disabled: false,

    /**
     * @private
     * @type {Object}
     */
    actions: {
      /**
       * @private
       * @function
       * @returns {void}
       */
      onFilter: function onFilter(filter) {
        (this.onFilter || function () {})(filter);
      }
    }
  });
});