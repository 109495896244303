define("nimbus/serializers/social/message", ["exports", "nimbus/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @returns {Object}
     */
    normalizeRecord: function normalizeRecord(store, modelClass, payload) {
      var normalized = this._super.apply(this, arguments);

      normalized.attributes["mediaUrl"] = Ember.getWithDefault(payload, "image_url", undefined);

      normalized.attributes["likes"] = Ember.getWithDefault(payload, "statistics.likes", 0);

      normalized.attributes["shares"] = Ember.getWithDefault(payload, "statistics.shares", 0);

      normalized.attributes["comments"] = Ember.getWithDefault(payload, "statistics.replies", 0);

      return normalized;
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.Snapshot} snapshot
     * @returns {Object}
     */
    serialize: function serialize(snapshot) {
      var serialized = this._super.apply(this, arguments);

      if (serialized["media_url"]) {
        serialized["image_url"] = serialized["media_url"];
      }

      if (serialized["description"]) {
        serialized["description"] = serialized["description"].substring(0, 150);
      }

      serialized["connection"] = snapshot.belongsTo("connection").id;

      if (snapshot.belongsTo("subject")) {
        serialized["subject"] = snapshot.belongsTo("subject").id;
      }

      var topics = snapshot.hasMany("topics");

      if (topics.length > 0) {
        serialized["topics"] = topics.map(function (topic) {
          return topic.id;
        });
      }

      return serialized;
    }
  });
});