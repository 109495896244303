define("nimbus/pods/components/x-menu/component", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    ajax: Ember.inject.service(),
    intl: Ember.inject.service(),

    /**
     * @private
     * @type {MenuService}
     */
    menu: Ember.inject.service(),

    /**
     * @private
     * @type {ContextService}
     */
    context: Ember.inject.service(),

    router: Ember.inject.service(),

    listEmpty: false,
    notesShown: false,
    hasNew: false,

    warnings: null,
    activeWarning: null,

    releases: null,
    activeRelease: null,

    demoActive: false,

    /**
     * @private
     * @type {module:app/models/core/user}
     */
    user: Ember.computed.alias("context.user"),

    /**
     * @private
     * @type {module:app/models/core/team}
     */
    team: Ember.computed.alias("context.team"),

    /**
     * @private
     * @type {Boolean}
     */
    shouldShowMenu: Ember.computed.alias("menu.shouldShowMenu"),

    showPlaceholder: Ember.computed("releases,warnings", function () {
      return this.releases.length === 0 && this.warnings.length === 0;
    }),

    didInsertElement: function didInsertElement() {
      var _this = this;

      var demoActive = window.localStorage.getItem("demo:active") ? window.localStorage.getItem("demo:active") : false;

      Ember.set(this, "demoActive", demoActive == "true");

      var lastGift = window.localStorage.getItem("gift:last") ? window.localStorage.getItem("gift:last") : "";

      var removedWarnings = window.localStorage.getItem("warnings:removed") ? window.localStorage.getItem("warnings:removed").split(",") : [];

      var removedReleases = window.localStorage.getItem("releases:removed") ? window.localStorage.getItem("releases:removed").split(",") : [];

      this.ajax.request("https://s3-eu-west-1.amazonaws.com/cdn.apostleconnect.nl/alerts/warnings.json?" + (0, _moment.default)().unix(), {
        method: "GET"
      }).then(function (data) {
        var warnings = [];

        data.warnings.forEach(function (entry) {
          if (!removedWarnings.includes(entry.code)) {
            entry.items.forEach(function (item) {
              if (_this.user.locale.includes(item.locale)) {
                item.code = entry.code;
                item.removed = false;
                warnings.pushObject(item);
              }
            });
          }
        });

        if (data.lastupdate > lastGift && warnings.length) {
          Ember.set(_this, "hasNew", true);
        }

        Ember.set(_this, "warnings", warnings);
      });

      this.ajax.request("https://s3-eu-west-1.amazonaws.com/cdn.apostleconnect.nl/alerts/release_notes.json?" + (0, _moment.default)().unix(), {
        method: "GET"
      }).then(function (data) {
        var releases = [];

        data.releases.forEach(function (entry) {
          if (!removedReleases.includes(entry.code)) {
            entry.items.forEach(function (item) {
              if (_this.user.locale.includes(item.locale)) {
                item.code = entry.code;
                item.removed = false;

                item.notes.forEach(function (note) {
                  note.type = _this.intl.t("component.x-menu.gift.notes." + note.type);
                });

                releases.pushObject(item);
              }
            });
          }
        });

        if (data.lastupdate > lastGift && releases.length) {
          Ember.set(_this, "hasNew", true);
        }

        Ember.set(_this, "releases", releases);
      });
    },


    actions: {
      toggleDemo: function toggleDemo() {
        Ember.set(this, "demoActive", !this.demoActive);

        window.localStorage.setItem("demo:active", this.demoActive);

        if (this.router.currentRouteName == "user.index") {
          window.location = window.location;
        } else {
          /* eslint-disable */
          this.sendAction("demoRouteReset");
          /* eslint-enable */
        }
      },
      showNotes: function showNotes() {
        Ember.set(this, "notesShown", !this.notesShown);
        window.localStorage.setItem("gift:last", (0, _moment.default)().format("YYYYMMDDHHmm"));
        Ember.set(this, "hasNew", false);
      },
      showWarning: function showWarning(warning) {
        Ember.set(this, "activeWarning", warning);
      },
      showRelease: function showRelease(release) {
        Ember.set(this, "activeRelease", release);
      },
      removeWarning: function removeWarning(warning) {
        var removed = window.localStorage.getItem("warnings:removed") ? window.localStorage.getItem("warnings:removed") + "," : "";

        removed += warning.code;

        window.localStorage.setItem("warnings:removed", removed);
        Ember.set(warning, "removed", true);

        this.checkActiveItems();
      },
      removeRelease: function removeRelease(release) {
        var removed = window.localStorage.getItem("releases:removed") ? window.localStorage.getItem("releases:removed") + "," : "";

        removed += release.code;

        window.localStorage.setItem("releases:removed", removed);
        Ember.set(release, "removed", true);

        this.checkActiveItems();
      },
      closeModal: function closeModal() {
        Ember.set(this, "activeWarning", null);
        Ember.set(this, "activeRelease", null);
      }
    },

    checkActiveItems: function checkActiveItems() {
      var active = true;

      this.releases.forEach(function (release) {
        if (!release.removed) {
          active = false;
        }
      });

      this.warnings.forEach(function (warning) {
        if (!warning.removed) {
          active = false;
        }
      });

      Ember.set(this, "listEmpty", active);
    }
  });
});