define("nimbus/pods/admin/messages/details/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    model: function model(params) {
      return this.store.findRecord("publish/subject", params.id).then(function (result) {
        var suggestTotal = result.messages.length;
        var shareTotal = 0;

        result.metadata.forEach(function (data) {
          suggestTotal += data.suggestions;
          shareTotal += data.shares;
        });

        result.messages.forEach(function (message) {
          if (message.state === "sent") {
            shareTotal++;
          }
        });

        return { subject: result, suggestTotal: suggestTotal, shareTotal: shareTotal };
      });
    }
  });
});