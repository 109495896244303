define("nimbus/pods/components/x-label/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {String}
     */
    tagName: "span",

    /**
     * @private
     * @type {Array.<String>}
     */
    classNameBindings: ["type", "hasAccent:accent"],

    /**
     * The type of the label.
     *
     * @type {String}
     */
    type: null,

    /**
     * The icon to add to the label.
     *
     * @type {String}
     */
    icon: null,

    /**
     * Indicates if the label has an accent.
     *
     * @type {Boolean}
     */
    hasAccent: false
  });
});