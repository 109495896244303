define("nimbus/services/processors/base", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * @method process
     * @param {Object} config
     * This is method is intentionally left blank, as it serves like an interface for the other processors
     */
    process: function process() {}
  });
});