define("nimbus/pods/admin/search/controller", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    /**
     * @private
     * @type {Array.<String>}
     */
    queryParams: ["query"],

    /**
     * @private
     * @type {String}
     */
    query: undefined
  });
});