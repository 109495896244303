define("nimbus/services/upload", ["exports", "axios", "nimbus/config/environment"], function (exports, _axios, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Service.extend({
    /**
     * @private
     * @type {AjaxService}
     */
    ajax: Ember.inject.service(),

    /**
     * @private
     * @type {ContextService}
     */
    context: Ember.inject.service(),

    /**
     * @public
     * @function
     * @param {Object} file
     * @returns {Promise}
     */
    upload: function upload(file, progressor, context) {
      var _this = this;

      var data = new FormData();
      data.append("upload", file);
      var headers = this.context.ajax.headers;

      return new Ember.RSVP.Promise(function (resolve, reject) {
        _axios.default.post(_environment.default.APP.HOST + "/" + _this.context.domain + "/uploads", data, {
          headers: headers,
          onUploadProgress: function onUploadProgress(progressEvent) {
            progressor(context, progressEvent.loaded, progressEvent.total);
          }
        }).then(function (result) {
          resolve({
            url: Ember.get(result, "data.data.upload._links.data.href"),
            mimeType: Ember.get(result, "data.data.upload.mime_type"),
            size: file.size
          });
        }).catch(function (error) {
          reject(error);
        });
      });
    }
  });
});