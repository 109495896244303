define("nimbus/pods/components/x-text/component", ["exports", "@ember-decorators/object", "@ember-decorators/component"], function (exports, _object, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  var _dec, _dec2, _class, _desc, _value, _class2;

  var Text = (_dec = (0, _component.tagName)(""), _dec2 = (0, _object.computed)("text", "highlight", "highlight.[]"), _dec(_class = (_class2 = function (_EmberComponent) {
    _inherits(Text, _EmberComponent);

    function Text() {
      _classCallCheck(this, Text);

      return _possibleConstructorReturn(this, (Text.__proto__ || Object.getPrototypeOf(Text)).apply(this, arguments));
    }

    _createClass(Text, [{
      key: "displayText",
      get: function get() {
        var text = this.text || "";
        var highlights = this.highlight instanceof Array ? this.highlight : this.highlight ? [this.highlight] : [];

        var pattern = highlights.map(function (highlight) {
          return highlight.split(" ").filter(function (s) {
            return s.length;
          });
        }).filter(function (highlights) {
          return highlights.length;
        }).reduce(function (p, h) {
          return p.concat(h);
        }, []).join("|").replace(/[.*+?^${}()|[\]\\]/g, "\\$&");

        var className = this.styleNamespace + "__highlight";
        var replacement = "<span class=\"" + className + "\">$1</span>";

        return highlights.length === 0 ? Ember.String.htmlSafe(text) : Ember.String.htmlSafe(text.replace(new RegExp("(" + pattern + ")", "gi"), replacement));
      }
    }]);

    return Text;
  }(Ember.Component), (_applyDecoratedDescriptor(_class2.prototype, "displayText", [_dec2], Object.getOwnPropertyDescriptor(_class2.prototype, "displayText"), _class2.prototype)), _class2)) || _class);
  exports.default = Text;
});