define("nimbus/serializers/strategy/topic", ["exports", "nimbus/serializers/application"], function (exports, _application) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    /**
     * @private
     * @function
     * @override
     * @param {DS.Store} store
     * @param {DS.Model} modelClass
     * @param {Object} payload
     * @returns {Object}
     */
    normalizeRecord: function normalizeRecord(store, modelClass, payload) {
      payload.parent_id = Ember.getWithDefault(payload, "parent.uuid", "");

      return this._super.apply(this, arguments);
    },


    /**
     * @private
     * @function
     * @override
     * @param {DS.Snapshot} snapshot
     * @returns {Object}
     */
    serialize: function serialize(snapshot) {
      var serialized = {};

      snapshot.eachAttribute(function (name) {
        var value = snapshot.attr(name);

        if (value != undefined) {
          serialized[name.underscore()] = value;
        }
      });

      if (serialized["parent_id"] && serialized["parent_id"].length > 0) {
        serialized["parent"] = serialized["parent_id"];
      } else {
        serialized["parent"] = "";
      }

      serialized.groups = snapshot.record.groups.map(function (group) {
        return group.id;
      });

      return serialized;
    }
  });
});