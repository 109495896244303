define("nimbus/pods/admin/statistics/controller", ["exports", "moment", "@ember-decorators/service", "@ember-decorators/object", "@ember-decorators/object/computed", "nimbus/config/environment"], function (exports, _moment, _service, _object, _computed, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _desc, _value, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7;

  var HOST = _environment.default.APP.HOST;
  var Statistics = (_dec = (0, _computed.equal)("interval", "daily"), _dec2 = (0, _computed.equal)("interval", "weekly"), _dec3 = (0, _computed.equal)("interval", "monthly"), _dec4 = (0, _computed.equal)("interval", "quarterly"), _dec5 = (0, _object.computed)("__start", "__end"), _dec6 = (0, _object.computed)("__start", "__end", "interval", "session.data.authenticated.access_token"), (_class = function (_EmberController) {
    _inherits(Statistics, _EmberController);

    function Statistics() {
      var _shortcuts;

      _classCallCheck(this, Statistics);

      var _this = _possibleConstructorReturn(this, (Statistics.__proto__ || Object.getPrototypeOf(Statistics)).apply(this, arguments));

      _initDefineProp(_this, "session", _descriptor, _this);

      _initDefineProp(_this, "context", _descriptor2, _this);

      _initDefineProp(_this, "intl", _descriptor3, _this);

      _this.queryParams = ["start", "end", "interval"];
      _this.shouldShowDatePicker = false;
      _this.shortcuts = [];
      _this.now = (0, _moment.default)().toDate();
      _this.host = HOST;

      _initDefineProp(_this, "isDaily", _descriptor4, _this);

      _initDefineProp(_this, "isWeekly", _descriptor5, _this);

      _initDefineProp(_this, "isMonthly", _descriptor6, _this);

      _initDefineProp(_this, "isQuarterly", _descriptor7, _this);

      var shortcuts = (_shortcuts = {}, _defineProperty(_shortcuts, _this.intl.t("route.admin.statistics.shortcuts.this_week"), {
        start: (0, _moment.default)().startOf("week").toDate(),
        end: (0, _moment.default)().toDate()
      }), _defineProperty(_shortcuts, _this.intl.t("route.admin.statistics.shortcuts.last_week"), {
        start: (0, _moment.default)().startOf("week").subtract(1, "weeks").toDate(),
        end: (0, _moment.default)().endOf("week").subtract(1, "weeks").toDate()
      }), _defineProperty(_shortcuts, _this.intl.t("route.admin.statistics.shortcuts.this_month"), {
        start: (0, _moment.default)().startOf("month").toDate(),
        end: (0, _moment.default)().toDate()
      }), _defineProperty(_shortcuts, _this.intl.t("route.admin.statistics.shortcuts.last_month"), {
        start: (0, _moment.default)().subtract(1, "months").startOf("month").toDate(),
        end: (0, _moment.default)().subtract(1, "months").endOf("month").toDate()
      }), _defineProperty(_shortcuts, _this.intl.t("route.admin.statistics.shortcuts.this_year"), {
        start: (0, _moment.default)().startOf("year").toDate(),
        end: (0, _moment.default)().endOf("year").toDate()
      }), _defineProperty(_shortcuts, _this.intl.t("route.admin.statistics.shortcuts.last_days"), {
        start: (0, _moment.default)().subtract(7, "days").toDate(),
        end: (0, _moment.default)().toDate()
      }), _shortcuts);

      Ember.set(_this, "shortcuts", shortcuts);
      return _this;
    }

    _createClass(Statistics, [{
      key: "onSelect",
      value: function onSelect(event) {
        event.target.select();
      }
    }, {
      key: "onChangeRange",
      value: function onChangeRange(_ref) {
        var start = _ref.start,
            end = _ref.end;

        Ember.set(this, "__start", start);
        Ember.set(this, "__end", end);

        if (start && end) {
          Ember.set(this, "shouldShowDatePicker", false);

          this.transitionToRoute({
            queryParams: {
              start: (0, _moment.default)(this.__start).format("YYYY-MM-DD"),
              end: (0, _moment.default)(this.__end).add(1, "days").format("YYYY-MM-DD"),
              interval: this.resolveDefaultInterval(this.__start, this.__end)
            }
          });
        }
      }
    }, {
      key: "download",
      value: function download() {
        var start = this.start ? (0, _moment.default)(this.start).format("YYYY-MM-DD") : (0, _moment.default)(this.__start).format("YYYY-MM-DD");
        var end = this.end ? (0, _moment.default)(this.end).format("YYYY-MM-DD") : (0, _moment.default)(this.__end).format("YYYY-MM-DD");
        var interval = this.interval;
        var accessToken = Ember.getWithDefault(this, "session.data.authenticated.access_token", null);

        var url = HOST + "/" + this.context.domain + "/statistics/report?access_token=" + accessToken + "&start=" + start + "&end=" + end + "&interval=" + interval;

        window.open(url);
      }
    }, {
      key: "showDatePicker",
      value: function showDatePicker(position) {
        if (position === "start") {
          Ember.set(this, "__start", null);
          Ember.set(this, "__end", null);
        } else if (position === "end") {
          Ember.set(this, "__end", null);
        }

        Ember.set(this, "shouldShowDatePicker", true);
      }
    }, {
      key: "resolveDefaultInterval",
      value: function resolveDefaultInterval(start, end) {
        var duration = _moment.default.duration((0, _moment.default)(end).diff((0, _moment.default)(start)));

        if (duration.asMonths() > 6) {
          return "quarterly";
        }

        if (duration.asWeeks() > 8) {
          return "monthly";
        }

        if (duration.asDays() > 21) {
          return "weekly";
        }

        return "daily";
      }
    }, {
      key: "range",
      get: function get() {
        return {
          start: this.__start ? (0, _moment.default)(this.__start).toDate() : null,
          end: this.__end ? (0, _moment.default)(this.__end).toDate() : null
        };
      }
    }, {
      key: "shareUrl",
      get: function get() {
        var start = (0, _moment.default)(this.__start).format("YYYY-MM-DD");
        var end = (0, _moment.default)(this.__end).format("YYYY-MM-DD");
        var interval = this.interval;
        var accessToken = Ember.getWithDefault(this, "session.data.authenticated.access_token", null);

        var id = btoa(start + "." + end + "." + interval + "." + accessToken);

        return "https://" + location.hostname + "/report/" + id;
      }
    }]);

    return Statistics;
  }(Ember.Controller), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "session", [_service.service], {
    enumerable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "context", [_service.service], {
    enumerable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "intl", [_service.service], {
    enumerable: true,
    initializer: null
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "isDaily", [_dec], {
    enumerable: true,
    initializer: null
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "isWeekly", [_dec2], {
    enumerable: true,
    initializer: null
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "isMonthly", [_dec3], {
    enumerable: true,
    initializer: null
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "isQuarterly", [_dec4], {
    enumerable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "range", [_dec5], Object.getOwnPropertyDescriptor(_class.prototype, "range"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shareUrl", [_dec6], Object.getOwnPropertyDescriptor(_class.prototype, "shareUrl"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onSelect", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onSelect"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onChangeRange", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "onChangeRange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "download", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "download"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "showDatePicker", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "showDatePicker"), _class.prototype)), _class));
  exports.default = Statistics;
});