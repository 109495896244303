define("nimbus/pods/components/x-multilabel/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Array}
     */
    list: null,

    /**
     * @private
     * @type {Array}
     */
    simpleList: null,

    /**
     * @private
     * @type {Boolean}
     */
    isOpen: null,

    /**
     * @private
     * @type {Array}
     */
    visibleList: Ember.computed("list.@each", function () {
      return this.list.slice(0, 3);
    }),

    /**
     * @private
     * @type {Array}
     */
    restCount: Ember.computed("list.@each", function () {
      return this.list.length > 3 ? "+" + (this.list.length - 3) : 0;
    }),

    /**
     * @private
     * @type {Boolean}
     */
    hasRest: Ember.computed("list.@each", function () {
      return this.list.length > 3;
    }),

    /**
     * @private
     * @type {String}
     */
    restList: Ember.computed("list.@each", "hasRest", function () {
      var lister = "";

      var workingList = this.list.map(function (item) {
        return item.name;
      });

      if (this.hasRest) {
        var count = workingList.length - 3;
        var index = 0;
        workingList.splice(3, count).map(function (name) {
          lister += name;
          index++;
          if (index != count) {
            lister += ", ";
          }
        });
      }

      return lister;
    }),

    didInsertElement: function didInsertElement() {
      if (this.list.length) {
        var list = this.list.map(function (item) {
          return item.name;
        });

        Ember.set(this, "simpleList", list);
      }
    }
  });
});