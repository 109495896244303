define("nimbus/models/publish/content-attachment", ["exports", "ember-data/attr", "ember-data/model", "nimbus/config/environment"], function (exports, _attr, _model, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = _model.default.extend({
    /**
     * The description of the attachment
     *
     * @type {String}
     */
    description: (0, _attr.default)("string"),

    /**
     * The image url of the attachment
     *
     * @type {String}
     */
    image: (0, _attr.default)("string"),

    /**
     * The title of the attachment
     *
     * @type {String}
     */
    title: (0, _attr.default)("string"),

    /**
     * The type of the attachment
     *
     * @type {String}
     */
    type: (0, _attr.default)("string"),

    /**
     * The URL of the attachment
     *
     * @type {String}
     */
    url: (0, _attr.default)("string"),
    originalUrl: (0, _attr.default)("string"),

    utmSource: (0, _attr.default)("string"),
    utmMedium: (0, _attr.default)("string"),
    utmName: (0, _attr.default)("string"),
    utmTerm: (0, _attr.default)("string"),
    utmContent: (0, _attr.default)("string"),

    /**
     * The safe URL (proxied with HTTPS).
     *
     * @type {String}
     */
    safeUrl: Ember.computed("url", function () {
      return HOST + "/proxy?url=" + this.url;
    }),

    /**
     * The safe image URL (proxied with HTTPS).
     *
     * @type {String}
     */
    safeImage: Ember.computed("image", function () {
      return HOST + "/proxy?url=" + this.image;
    })
  });
});