define("nimbus/services/validators/validator", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var VALIDATION_ERROR = exports.VALIDATION_ERROR = "error";
  var VALIDATION_WARNING = exports.VALIDATION_WARNING = "warning";

  /**
   * @module
   * @augments @ember/object
   */
  exports.default = Ember.Service.extend({
    /**
     * @public
     * @function
     * @param {Object} config
     * @returns {Array}
     */
    validate: function validate() {
      return [];
    }
  });
});