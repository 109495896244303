define("nimbus/pods/logout/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @private
     * @type {SessionService}
     */
    session: Ember.inject.service(),

    trial: Ember.inject.service(),

    /**
     * @private
     * @function
     * @override
     * @returns {Promise}
     */
    beforeModel: function beforeModel() {
      var _this = this;

      this.session.invalidate().then(function () {
        if (_this.trial.isActive()) {
          location.href = "https://www.apostlesocial.com";
        } else {
          location.href = "/";
        }
      });
    }
  });
});