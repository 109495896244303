define("nimbus/pods/components/x-in-viewport/component", ["exports", "ember-in-viewport"], function (exports, _emberInViewport) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend(_emberInViewport.default, {
    /**
     * An action to invoke when the component enters the viewport.
     *
     * @type {Function}
     */
    onEnterViewport: null,

    /**
     * @private
     * @function
     * @override
     * @returns {void}
     */
    didEnterViewport: function didEnterViewport() {
      (this.onEnterViewport || function () {})();
    }
  });
});