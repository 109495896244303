define("nimbus/pods/admin/subjects/index/route", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Route.extend({
    /**
     * @public
     * @function
     * @override
     * @returns {void}
     */
    setupController: function setupController(controller) {
      controller.loadSubjects.perform();
      controller.loadMetadata.perform();
    },


    /**
     * @public
     * @function
     * @override
     * @returns {void}
     */
    resetController: function resetController(controller) {
      Ember.set(controller, "search", "");
    },


    actions: {
      demoRouteReset: function demoRouteReset() {
        var controller = Ember.get(this, "controller");
        this.resetController(controller);
        this.setupController(controller);

        if (Ember.get(controller, "showCalendar")) {
          controller.loadCalendar.perform();
        }
      }
    }
  });
});