define("nimbus/services/validators/twitter/bodylink", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    intl: Ember.inject.service(),

    /**
     * @property regex
     * @type RegExp
     */
    regex: Ember.computed(function () {
      var protocol = "(?:(?:[a-z]+:)?//)";
      var auth = "(?:\\S+(?::\\S*)?@)?";
      var host = "(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)";
      var domain = "(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*";
      var tld = "(?:\\.(?:[a-z\\u00a1-\\uffff]{2,}))\\.?";
      var port = "(?::\\d{2,5})?";
      var path = "(?:[/?#][^\\s']*)?";
      var regex = "(?:" + protocol + "|www\\.)" + auth + "(?:localhost|" + host + domain + tld + ")" + port + path;

      return new RegExp(regex, "ig");
    }),

    validate: function validate(config) {
      var type = Ember.getWithDefault(config, "variant.content.attachment.type", "none");
      var body = Ember.getWithDefault(config, "variant.content.body", "");
      var urls = (body.match(this.regex) || []).length;
      var warning = {
        platform: "twitter",
        type: _validator.VALIDATION_WARNING,
        message: this.intl.t("validation.twitter.body.nolink")
      };

      return type === "link" && urls === 0 ? [warning] : [];
    }
  });
});