define("nimbus/helpers/isempty", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.isEmpty = isEmpty;
  function isEmpty(value) {
    return value[0].trim().length === 0;
  }

  exports.default = Ember.Helper.helper(isEmpty);
});