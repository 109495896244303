define("nimbus/util/platform", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var ie = exports.ie = function ie() {
    return !!window.MSInputMethodContext && !!document.documentMode;
  };

  var ios = exports.ios = function ios() {
    return !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
  };

  var android = exports.android = function android() {
    return navigator.userAgent.toLowerCase().indexOf("android") >= 0;
  };
});