define("nimbus/services/ajax", ["exports", "ember-ajax/services/ajax", "nimbus/config/environment"], function (exports, _ajax, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _defineProperty(obj, key, value) {
    if (key in obj) {
      Object.defineProperty(obj, key, {
        value: value,
        enumerable: true,
        configurable: true,
        writable: true
      });
    } else {
      obj[key] = value;
    }

    return obj;
  }

  var HOST = _environment.default.APP.HOST;
  exports.default = _ajax.default.extend({
    /**
     * @private
     * @type {SessionService}
     */
    session: Ember.inject.service(),

    /**
     * @private
     * @type {String}
     */
    host: HOST,

    /**
     * @private
     * @type {Object}
     */
    headers: Ember.computed("session.{isAuthenticated,data.authenticated.access_token}", function () {
      return this.session.isAuthenticated ? _defineProperty({}, "Authorization", "Bearer " + this.session.data.authenticated.access_token) : {};
    })
  });
});