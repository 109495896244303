define("nimbus/tailwind/config/text-sizes", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    xxs: ".625rem", // 10px
    xs: ".75rem", // 12px
    sm: ".875rem", // 14px
    base: "1rem", // 16px
    lg: "1.125rem", // 18px
    xl: "1.25rem", // 20px
    "2xl": "1.5rem", // 24px
    "3xl": "1.875rem", // 30px
    "4xl": "2.25rem", // 36px
    "5xl": "3rem", // 48px

    "connect-12": ".75rem",
    "connect-14": ".875rem",
    "connect-16": "1rem",
    "connect-17": "1.0625rem",
    "connect-19": "1.1875rem",
    "connect-21": "1.3125rem",
    "connect-24": "1.5rem",
    "connect-29": "1.8125rem"
  };
});