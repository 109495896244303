define("nimbus/pods/admin/messages/details/index/search", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.tokenize = exports.timestamp = exports.group = exports.user = exports.rest = exports.type = exports.is = undefined;

  exports.default = function (model, query) {
    var tokens = tokenize(query);
    var handlers = {
      is: is,
      user: user,
      rest: rest,
      start: timestamp("metadata.start"),
      end: timestamp("metadata.end", "<")
    };

    return model.filter(function (model) {
      return Object.keys(tokens).every(function (token) {
        return handlers[token] ? handlers[token](model, tokens[token]) : true;
      });
    });
  };

  var is = exports.is = function is(model, properties) {
    var propertyMap = {
      active: "isActive",
      shared: "isShared",
      unshared: "isUnshared",
      pending: "isPending",
      expired: "isExpired",
      accepted: "isAccepted",
      rejected: "isRejected",
      scheduled: "isScheduled"
    };

    return properties.map(function (property) {
      return property.toLowerCase();
    }).filter(function (property) {
      return Object.keys(propertyMap).includes(property);
    }).every(function (property) {
      return !!Ember.get(model.subject, propertyMap[property]);
    });
  };

  var type = exports.type = function type(model, patterns) {
    return Ember.getWithDefault(model, "content.attachment.type", "") == patterns[0];
  };

  var rest = exports.rest = function rest(model, patterns) {
    var regex = new RegExp(patterns[0], "gi");
    var properties = ["content.body", "content.attachment.url", "content.attachment.title", "content.attachment.description"];

    return properties.any(function (property) {
      return Ember.getWithDefault(model, property, "").match(regex);
    });
  };

  var user = exports.user = function user(model, patterns) {
    var regexes = patterns.map(function (p) {
      return new RegExp(p, "gi");
    });
    var properties = ["username", "firstName", "lastName"];

    return model.suggestions.any(function (model) {
      return properties.any(function (property) {
        return regexes.any(function (regex) {
          return Ember.getWithDefault(model, "member.user." + property, "").match(regex);
        });
      });
    });
  };

  var group = exports.group = function group(model, patterns) {
    var regexes = patterns.map(function (p) {
      return new RegExp(p, "gi");
    });

    return model.suggestions.any(function (model) {
      return regexes.any(function (regex) {
        return model.member.groups.any(function (group) {
          return group.name.match(regex);
        });
      });
    });
  };

  var timestamp = exports.timestamp = function timestamp(property) {
    var defaultComparison = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : ">";

    return function (model, patterns) {
      var comparisons = [">", "<"];
      var timestamps = patterns.map(function (pattern) {
        return comparisons.includes(pattern[0]) ? { comparison: pattern[0], timestamp: (0, _moment.default)(pattern.substring(1)) } : { comparison: defaultComparison, timestamp: (0, _moment.default)(pattern) };
      });

      if (!Ember.get(model.subject, property)) {
        return true;
      }

      return timestamps.every(function (timestamp) {
        switch (timestamp.comparison) {
          case "<":
            return (0, _moment.default)(Ember.get(model, property)) <= timestamp.timestamp;
          default:
            return (0, _moment.default)(Ember.get(model, property)) >= timestamp.timestamp;
        }
      });
    };
  };

  var tokenize = exports.tokenize = function tokenize(query) {
    var tokens = query.split(" ").filter(function (token) {
      return token.indexOf(":") > 0;
    }).reduce(function (tokens, token) {
      var components = token.split(":");
      var type = components.shift();
      var rest = components.join(":");

      if (!tokens[type]) {
        tokens[type] = [];
      }

      tokens[type].push(rest);

      return tokens;
    }, {});

    var rest = query.split(" ").filter(function (token) {
      return token.indexOf(":") <= 0;
    }).join(" ");

    tokens["rest"] = [rest];

    return tokens;
  };
});