define("nimbus/models/publish/metadata", ["exports", "ember-data/attr", "ember-data/model", "nimbus/util/computed"], function (exports, _attr, _model, _computed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The date and time the variant is active
     *
     * @type {Date}
     */
    start: (0, _attr.default)("date"),

    /**
     * The date and time the variant should be inactive
     *
     * @type {Date}
     */
    end: (0, _attr.default)("date"),

    /**
     * The number of shares of the variant.
     *
     * @type {Number}
     */
    shares: (0, _attr.default)("number"),

    /**
     * The number of suggestions of the variant.
     *
     * @type {Number}
     */
    suggestions: (0, _attr.default)("number"),

    /**
     * Indicates if the variant can be shared
     *
     * @type {Boolean}
     */
    isActive: (0, _computed.pure)("start", "end", function (s, e) {
      return new Date() >= s && new Date() <= e;
    })
  });
});