define("nimbus/pods/insights/controller", ["exports", "moment"], function (exports, _moment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    _context: Ember.inject.service("context"),

    currentView: "activity",
    displayView: "users",
    sortColumnMember: "full_name",
    sortColumnDirection: "asc",
    sortColumnConnection: "name",
    sortColumnConnectionDirection: "asc",
    sortColumnLink: "url",
    sortColumnLinkDirection: "asc",
    compact: true,

    sortedMembers: Ember.computed.sort("membersWithLogin", "memberSorting"),

    sortedConnections: Ember.computed.sort("connections", "connectionSorting"),

    sortedLinks: Ember.computed.sort("links", "linkSorting"),

    activityView: Ember.computed("currentView", function () {
      return this.currentView === "activity";
    }),

    inputView: Ember.computed("currentView", function () {
      return this.currentView === "input";
    }),

    socialView: Ember.computed("currentView", function () {
      return this.currentView === "social";
    }),

    userView: Ember.computed("displayView", function () {
      return this.displayView === "users";
    }),

    pageView: Ember.computed("displayView", function () {
      return this.displayView === "pages";
    }),

    linkView: Ember.computed("displayView", function () {
      return this.displayView === "links";
    }),

    memberSorting: Ember.computed("sortColumnMember", "sortColumnDirection", function () {
      return [this.sortColumnMember + ":" + this.sortColumnDirection];
    }),

    connectionSorting: Ember.computed("sortColumnConnection", "sortColumnConnectionDirection", function () {
      return [this.sortColumnConnection + ":" + this.sortColumnConnectionDirection];
    }),

    linkSorting: Ember.computed("sortColumnLink", "sortColumnLinkDirection", function () {
      return [this.sortColumnLink + ":" + this.sortColumnLinkDirection];
    }),

    sortIcon: Ember.computed("sortColumnDirection", function () {
      return this.sortColumnDirection === "asc" ? "sort-down" : "sort-up";
    }),

    sortConnectionIcon: Ember.computed("sortColumnConnectionDirection", function () {
      return this.sortColumnConnectionDirection === "asc" ? "sort-down" : "sort-up";
    }),

    sortLinkIcon: Ember.computed("sortColumnLinkDirection", function () {
      return this.sortColumnLinkDirection === "asc" ? "sort-down" : "sort-up";
    }),

    connections: Ember.computed("model", function () {
      return this.model.connections;
    }),

    links: Ember.computed("model", function () {
      return this.model.links;
    }),

    inputsData: Ember.computed("model", function () {
      var dataText = [];
      var dataVideo = [];
      var dataImage = [];
      var dataLabels = [];

      this.model.series.inputs.forEach(function (input) {
        dataText.push(input.text);
        dataImage.push(input.image);
        dataVideo.push(input.video);
        dataLabels.push(input.label);
      });

      return {
        labels: dataLabels,
        datasets: [{
          label: "Image",
          data: dataImage,
          borderColor: "#F6993F",
          backgroundColor: "#F6993F",
          borderWidth: 2,
          fill: false
        }, {
          label: "Video",
          data: dataVideo,
          borderColor: "#F5245F",
          backgroundColor: "#F5245F",
          borderWidth: 2,
          fill: false
        }, {
          label: "Text",
          data: dataText,
          borderColor: "#784396",
          backgroundColor: "#784396",
          borderWidth: 2,
          fill: false
        }]
      };
    }),

    messagesData: Ember.computed("model", function () {
      var dataShare = [];
      var dataSuggest = [];
      var dataLabels = [];

      this.model.series.inputs.forEach(function (input) {
        dataLabels.push(input.label);
      });

      this.model.series.shares.forEach(function (share) {
        dataShare.push(share.total);
      });

      this.model.series.suggestions.forEach(function (suggestion) {
        dataSuggest.push(suggestion.total);
      });

      return {
        labels: dataLabels,
        datasets: [{
          label: "Shared",
          data: dataShare,
          borderColor: "#38C172",
          backgroundColor: "#38C172",
          borderWidth: 2,
          fill: false
        }, {
          label: "Suggested",
          data: dataSuggest,
          borderColor: "#3490DC",
          backgroundColor: "#3490DC",
          borderWidth: 2,
          fill: false
        }]
      };
    }),

    setLocale: Ember.computed("_context.user.locale", function () {
      var locale = Ember.getWithDefault(this, "_context.user.locale", "");

      if (locale.length) {
        this.intl.setLocale(locale);
      }
    }),

    membersWithLogin: Ember.computed("model", function () {
      var _this = this;

      var returnValue = [];

      this.model.members.forEach(function (member) {
        var loginDate = (0, _moment.default)(Ember.get(member, "last_login")).toDate();
        var newMember = member;
        newMember.last_datetime = loginDate;
        newMember.last_formatted = (0, _moment.default)(Ember.get(member, "last_login")).format("DD-MM-YYYY");

        _this.model.pages.forEach(function (page) {
          if (page.member === member.id) {
            newMember.page = page;
          }
        });

        if (!newMember.page) {
          newMember.page = {
            activity: {
              pending: 0,
              removed: 0,
              scheduled: 0,
              sent: 0
            },
            statistics: {
              total: {
                likes: 0,
                replies: 0,
                shares: 0
              },
              linkedin: {
                likes: 0,
                replies: 0,
                shares: 0
              },
              facebook: {
                likes: 0,
                replies: 0,
                shares: 0
              }
            }
          };
        }

        _this.model.personal.forEach(function (page) {
          if (page.member === member.id) {
            newMember.page.statistics.total.likes += page.statistics.total.likes;
            newMember.page.statistics.total.replies += page.statistics.total.replies;
            newMember.page.statistics.total.shares += page.statistics.total.shares;
            newMember.page.statistics.facebook.likes += page.statistics.facebook.likes;
            newMember.page.statistics.facebook.replies += page.statistics.facebook.replies;
            newMember.page.statistics.facebook.shares += page.statistics.facebook.shares;
            newMember.page.statistics.linkedin.likes += page.statistics.linkedin.likes;
            newMember.page.statistics.linkedin.replies += page.statistics.linkedin.replies;
            newMember.page.statistics.linkedin.shares += page.statistics.linkedin.shares;
          }
        });

        _this.model.suggestions.forEach(function (suggestion) {
          if (suggestion.member === member.id) {
            newMember.suggestion = suggestion;
          }
        });

        if (!newMember.suggestion) {
          newMember.suggestion = {
            suggestions: 0,
            removed: 0,
            open: 0,
            shares: {
              total: 0,
              facebook: 0,
              linkedin: 0,
              instagram: 0,
              twitter: 0
            }
          };
        }

        _this.model.inputs.forEach(function (input) {
          if (input.member === member.id) {
            newMember.input = input;
          }
        });

        if (!newMember.input) {
          newMember.input = {
            total: 0,
            image: 0,
            video: 0,
            text: 0
          };
        }

        returnValue.push(newMember);
      });

      return returnValue;
    }),

    actions: {
      setView: function setView(view) {
        Ember.set(this, "currentView", view);
      },
      setDisplay: function setDisplay(display) {
        Ember.set(this, "displayView", display);
      },
      setCompact: function setCompact(compact) {
        Ember.set(this, "compact", compact);
      },
      sortList: function sortList(column) {
        if (column === this.sortColumnMember) {
          Ember.set(this, "sortColumnDirection", this.sortColumnDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnMember", column);
          Ember.set(this, "sortColumnDirection", column === "full_name" ? "asc" : "desc");
        }
      },
      sortConnections: function sortConnections(column) {
        if (column === this.sortColumnConnection) {
          Ember.set(this, "sortColumnConnectionDirection", this.sortColumnConnectionDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnConnection", column);
          Ember.set(this, "sortColumnConnectionDirection", column === "name" || column === "platform" ? "asc" : "desc");
        }
      },
      sortLinks: function sortLinks(column) {
        if (column === this.sortColumnLink) {
          Ember.set(this, "sortColumnLinkDirection", this.sortColumnLinkDirection === "asc" ? "desc" : "asc");
        } else {
          Ember.set(this, "sortColumnLink", column);
          Ember.set(this, "sortColumnLinkDirection", column === "url" ? "asc" : "desc");
        }
      }
    }
  });
});