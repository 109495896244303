define("nimbus/models/publish/variant", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The state of the variant.
     *
     * @type {String}
     */
    state: (0, _attr.default)("string"),

    /**
     * The platforms the variant is for.
     *
     * @type {Array.<String>}
     */
    platforms: (0, _attr.default)("array"),

    /**
     * The URL to send a request to when sharing the variant.
     *
     * @type {String}
     */
    shareUrl: (0, _attr.default)("string"),

    /**
     * The extra text attachment of the variant.
     *
     * @type {String}
     */
    mastermessage: (0, _attr.default)("string"),

    /**
     * The extra text attachment of the variant.
     *
     * @type {String}
     */
    customMailSubject: (0, _attr.default)("string"),

    /**
     * If the variant should be selected for all
     *
     * @type {Boolean}
     */
    selectAll: (0, _attr.default)("boolean"),

    /**
     * The attachment of the variant.
     *
     * @type {module:app/models/publish/content}
     */
    content: (0, _relationships.belongsTo)("publish/content", { async: false }),

    /**
     * The shares of the variant.
     *
     * @type {Array.<module:app/models/publish/share>}
     */
    shares: (0, _relationships.hasMany)("publish/share", {
      async: false
    }),

    /**
     * The suggestions of the variant.
     *
     * @type {Array.<module:app/models/publish/suggestion>}
     */
    suggestions: (0, _relationships.hasMany)("publish/suggestion", {
      async: false,
      inverse: "variant"
    }),

    /**
     * The suggestions of the variant.
     *
     * @type {Array.<module:app/models/strategy/topic>}
     */
    topics: (0, _relationships.hasMany)("strategy/topic", {
      async: false
    }),

    /**
     * The metadata for the variant.
     *
     * @type {module:app/models/publish/metadata}
     */
    metadata: (0, _relationships.belongsTo)("publish/metadata", { async: false }),

    /**
     * The subject the variant is connected to
     *
     * @type {module:app/models/publish/subject}
     */
    subject: (0, _relationships.belongsTo)("publish/subject", { async: false }),

    /**
     * Indicates if the variant is pending review.
     *
     * @type {Boolean}
     */
    isPending: Ember.computed.equal("state", "pending"),

    /**
     * Indicates if the variant is accepted by the reviewers.
     *
     * @type {Boolean}
     */
    isAccepted: Ember.computed.equal("state", "accepted"),

    /**
     * Indicates if the variant is rejected by the reviewers.
     *
     * @type {Boolean}
     */
    isRejected: Ember.computed.equal("state", "rejected"),

    /**
     * Indicates that the variant was shared at some point in time.
     *
     * @type {Boolean}
     */
    isShared: Ember.computed.gt("shares.length", 0),

    /**
     * Indicates that the variant was not shared yet.
     *
     * @type {Boolean}
     */
    isUnshared: Ember.computed.not("isShared"),

    /**
     * Indicates if the variant is active.
     *
     * @type {Boolean}
     */
    isActive: Ember.computed("isAccepted", "metadata.{start,end}", function () {
      return this.isAccepted && this.metadata.start <= new Date() && this.metadata.end >= new Date();
    }),

    /**
     * Indicates if the variant is expired.
     *
     * @type {Boolean}
     */
    isExpired: Ember.computed("metadata.end", function () {
      return this.metadata.end < new Date();
    }),

    /**
     * Indicates if the variant is scheduled.
     *
     * @type {Boolean}
     */
    isScheduled: Ember.computed("isAccepted", "metadata.start", function () {
      return this.isAccepted && this.metadata.start > new Date();
    })
  });
});