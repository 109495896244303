define("nimbus/mirage/factories/membership", ["exports", "ember-cli-mirage"], function (exports, _emberCliMirage) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _emberCliMirage.default.Factory.extend({
    uuid: function uuid() {
      return _emberCliMirage.faker.random.uuid();
    },
    created_at: function created_at() {
      return _emberCliMirage.faker.date.past();
    },
    updated_at: function updated_at() {
      return _emberCliMirage.faker.date.recent();
    },
    is_admin: function is_admin() {
      return Math.random() >= 0.5;
    },
    last_feed_update: function last_feed_update() {
      return _emberCliMirage.faker.date.recent();
    },
    self_url: function self_url() {
      return "https://api.apostleconnect.nl/localhost/members/" + this.uuid;
    }
  });
});