define("nimbus/services/validators/instagram/video", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    /**
     * @private
     * @type {IntlService}
     */
    intl: Ember.inject.service(),

    /**
     * @public
     * @function
     * @override
     * @param {Object} config
     * @return {Array}
     */
    validate: function validate(config) {
      var type = Ember.getWithDefault(config, "variant.content.attachment.type", "none");

      var error = {
        platform: "instagram",
        type: _validator.VALIDATION_ERROR,
        message: this.intl.t("validation.instagram.video.length")
      };

      return type === "video" && config.fileData.length > 60 ? [error] : [];
    }
  });
});