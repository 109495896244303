define("nimbus/models/core/announcement", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model", "nimbus/util/computed"], function (exports, _attr, _relationships, _model, _computed) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The name of the announcement
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    /**
     * The URL of the attached media of the attachment.
     *
     * @type {String}
     */
    url: (0, _attr.default)("string"),

    /**
     * The URL of the attached media of the attachment.
     *
     * @type {String}
     */
    type: (0, _attr.default)("string"),

    /**
     * The body of the announcement
     *
     * @type {String}
     */
    body: (0, _attr.default)("string"),

    /**
     * The date and time when the announcement is active
     *
     * @type {Date}
     */
    start: (0, _attr.default)("date"),

    /**
     * The date and time when the announcement should be inactive
     *
     * @type {Date}
     */
    end: (0, _attr.default)("date"),

    /**
     * The member that created the message.
     *
     * @type {module:app/models/core/member}
     */
    members: (0, _relationships.hasMany)("core/member", { async: false }),

    /**
     * Indicates if the announcement is active.
     *
     * @type {Boolean}
     */
    isActive: (0, _computed.pure)("start", "end", function (s, e) {
      return new Date() > s && new Date() < e;
    }),

    /**
     * The state of the announcement.
     *
     * @type {String}
     */
    state: Ember.computed("start", "end", function () {
      if (this.start > new Date()) {
        return "scheduled";
      } else if (this.start < new Date() && this.end < new Date()) {
        return "expired";
      }

      return "active";
    })
  });
});