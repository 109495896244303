define("nimbus/session-stores/application", ["exports", "ember-simple-auth/session-stores/cookie", "nimbus/config/environment"], function (exports, _cookie, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var isStaging = _environment.default.isStaging;
  exports.default = _cookie.default.extend({
    cookieExpirationTime: 31536000,
    cookieName: Ember.computed(function () {
      return isStaging ? "apostle-staging-web-app" : "ApostleConnect";
    }),
    cookieDomain: Ember.computed(function () {
      if (isStaging) {
        return null;
      } else {
        var parts = location.hostname.split(".");
        parts.shift();

        return parts.join(".");
      }
    })
  });
});