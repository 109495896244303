define("nimbus/pods/components/x-composer/subject/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    trial: Ember.inject.service(),
    /**
     * @private
     * @type {String}
     */
    name: null,

    /**
     * @private
     * @type {String}
     */
    id: null,

    /**
     * @private
     * @type {String}
     */
    original: null,

    /**
     * @private
     * @type {Array.<module:app/models/publish/subject>}
     */
    subjects: null,

    sortedSubjects: Ember.computed.sort("subjects", "dataSorting"),

    dataSorting: Ember.computed("subjects", function () {
      return ["name:asc"];
    }),

    /**
     * @private
     * @type {Boolean}
     */
    subjectSelectValue: Ember.computed("id", "original", function () {
      var _this = this;

      var currentSubject = null;

      this.subjects.forEach(function (subject) {
        if (_this.id === subject.id || _this.original === subject.id) {
          currentSubject = subject;
        }
      });

      return currentSubject;
    }),

    didUpdateAttrs: function didUpdateAttrs() {
      if (this.shouldReset) {
        this.send("focusSearch");
      }
    },


    actions: {
      handleKeyDown: function handleKeyDown(select, e) {
        if (e && e.keyCode === 9) {
          select.actions.select(select.highlighted, e);
          select.actions.close();
        }
      },
      selectSubject: function selectSubject(subject) {
        this.onSelect(subject);
      },
      createSubject: function createSubject(name) {
        if (this.trial.isActive() || this.trial.isDemo()) {
          this.trial.message();
        } else {
          this.onCreate(name);
        }
      },
      setListDisplay: function setListDisplay(value) {
        var _this2 = this;

        Ember.run.later(this, function () {
          Ember.set(_this2, "showList", value);
        }, 100);
      },
      focusSearch: function focusSearch() {
        var input = this.$(".subject-input >  .ember-power-select-trigger");
        if (input) {
          input.focus();
        }
      }
    }
  });
});