define("nimbus/pods/components/x-graph/line/component", ["exports", "chart.js"], function (exports, _chart) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    data: null,

    didInsertElement: function didInsertElement() {
      var _this = this;

      var selector = "." + this.styleNamespace + "__graph";
      var element = this.element.querySelector(selector);
      var context = element.getContext("2d");

      Ember.run(function () {
        var chart = new _chart.default(context, _this.buildOptions());

        Ember.set(_this, "chart", chart);
      });
    },
    buildOptions: function buildOptions() {
      return {
        type: "line",
        data: this.data,
        options: {
          legend: {
            position: "bottom",
            labels: {
              boxWidth: 10
            }
          },
          responsive: true,
          maintainAspectRatio: false,
          tooltips: { mode: "index" },
          scales: {
            yAxes: [{
              ticks: { min: 0, precision: 0 }
            }]
          }
        }
      };
    }
  });
});