define("nimbus/pods/admin/services/controller", ["exports", "nimbus/config/environment"], function (exports, _environment) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var HOST = _environment.default.APP.HOST;
  exports.default = Ember.Controller.extend({
    intl: Ember.inject.service(),
    _context: Ember.inject.service("context"),
    ajax: Ember.inject.service(),
    emitter: Ember.inject.service(),

    formSent: false,
    mainItems: null,

    init: function init() {
      this._super();
      var paragraphs = [3, 4, 2];

      var content = [];

      for (var i = 0; i < paragraphs.length; i++) {
        var paragraph = {};
        var count = i + 1;

        paragraph.title = "route.admin.services.content.main_" + count + ".title";
        paragraph.subtitle = "route.admin.services.content.main_" + count + ".subtitle";
        paragraph.intro = "route.admin.services.content.main_" + count + ".intro";
        paragraph.toggled = false;
        paragraph.children = [];

        for (var j = 1; j <= paragraphs[i]; j++) {
          var item = {};
          item.title = "route.admin.services.content.main_" + count + ".children.child_" + j + ".title";
          item.content = "route.admin.services.content.main_" + count + ".children.child_" + j + ".content";

          paragraph.children.pushObject(item);
        }

        content.pushObject(paragraph);
      }

      Ember.set(this, "mainItems", content);
    },


    isEnabled: Ember.computed("model.{firstname,lastname,email,company,size,phone}", function () {
      return this.model.firstname.trim().length && this.model.lastname.trim().length && this.model.email.trim().length && this.model.company.trim().length && this.model.size.trim().length && this.model.phone.trim().length;
    }),

    actions: {
      toggleParagraph: function toggleParagraph(paragraph) {
        Ember.set(paragraph, "toggled", !paragraph.toggled);
      },
      submitForm: function submitForm() {
        var _this = this;

        this.ajax.request(HOST + "/team/service-request", {
          method: "POST",
          data: this.model
        }).then(function () {
          Ember.set(_this, "formSent", true);
        });
      },
      redoService: function redoService() {
        this.emitter.emit("service-refresh");
        Ember.set(this, "formSent", false);
      }
    }
  });
});