define("nimbus/pods/components/x-emoji-picker/component", ["exports", "nimbus/util/emoji"], function (exports, _emoji) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    search: null,

    /**
     * @private
     * @type {Array}
     */
    emojiHints: Ember.computed("search", function () {
      var regExp = new RegExp(this.search, "gi");

      return Object.keys(_emoji.emojiMap).filter(function (emoji) {
        return emoji.match(regExp);
      });
    }),

    /**
     * @private
     * @type {Object}
     */
    categories: Ember.computed(function () {
      return Object.keys(_emoji.emojiCategories).reduce(function (result, title) {
        var emojis = _emoji.emojiCategories[title];

        return result.concat([{ title: title, emojis: emojis }]);
      }, []);
    })
  });
});