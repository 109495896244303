define("nimbus/models/core/invite", ["exports", "ember-data/attr", "nimbus/models/model", "nimbus/util/computed/join"], function (exports, _attr, _model, _join) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The email of the invited person.
     *
     * @type {String}
     */
    email: (0, _attr.default)("string"),

    /**
     * The state of the invite.
     *
     * @type {String}
     */
    state: (0, _attr.default)("string"),

    /**
     * The message added to the invite.
     *
     * @type {String}
     */
    message: (0, _attr.default)("string"),

    /**
     * The URL to send a post request to for resending an invite
     *
     * @type {String}
     */
    resendUrl: (0, _attr.default)("string"),

    /**
     * The first (or given) name of the invited person.
     *
     * @type {String}
     */
    firstName: (0, _attr.default)("string"),

    /**
     * The last (or family) name of the invited person.
     *
     * @type {String}
     */
    lastName: (0, _attr.default)("string"),

    /**
     * The date and time the invite email was sent.
     *
     * @type {Date}
     */
    sentAt: (0, _attr.default)("date"),

    /**
     * The full name of the invited person.
     *
     * @type {String}
     */
    fullName: (0, _join.default)(" ", "{firstName,lastName}")
  });
});