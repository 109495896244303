define("nimbus/tailwind/config/min-height", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    "0": "0",
    full: "100%",
    screen: "100vh"
  };
});