define("nimbus/pods/components/x-wizard-progress/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    /**
     * @private
     * @type {Number}
     */
    total: null,

    /**
     * @private
     * @type {Number}
     */
    current: null,

    /**
     * @private
     * @type {Number}
     */
    numberDone: Ember.computed(function () {
      return this.current - 1;
    }),

    /**
     * @private
     * @type {Number}
     */
    numberToGo: Ember.computed(function () {
      return this.total - this.current;
    })
  });
});