define("nimbus/pods/components/x-emoji-picker/emoji/component", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    classNames: ["emoji"],
    attributeBindings: ["emoji:title"],

    click: function click() {
      if (this.onClick) {
        this.onClick();
      }
    },
    mouseEnter: function mouseEnter() {
      if (this.onMouseEnter) {
        this.onMouseEnter();
      }
    },
    mouseLeave: function mouseLeave() {
      if (this.onMouseLeave) {
        this.onMouseLeave();
      }
    }
  });
});