define("nimbus/services/validators/twitter/imagesize", ["exports", "nimbus/services/validators/validator"], function (exports, _validator) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _validator.default.extend({
    intl: Ember.inject.service(),

    validate: function validate(config) {
      var type = Ember.getWithDefault(config, "variant.content.attachment.type", "none");
      var error = {
        platform: "twitter",
        type: _validator.VALIDATION_ERROR,
        message: this.intl.t("validation.twitter.image.size")
      };

      return type === "image" && config.fileData.size > 3000000 ? [error] : [];
    }
  });
});