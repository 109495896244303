define("nimbus/models/core/comment", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The body of the comment.
     *
     * @type {String}
     */
    body: (0, _attr.default)("string"),

    /**
     * The member that created the comment.
     *
     * @type {module:app/models/core/member}
     */
    member: (0, _relationships.belongsTo)("core/member", { async: false }),

    /**
     * Indicates if the current user created the comment.
     *
     * @type {Boolean}
     */
    isSelf: Ember.computed("context.user", "member.user", function () {
      return Ember.get(this, "context.user") === Ember.get(this, "member.user");
    }),

    /**
     * @private
     * @type {ContextService}
     */
    context: Ember.inject.service()
  });
});