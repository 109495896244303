define("nimbus/pods/components/x-data-table/sort/component", ["exports", "@ember-decorators/component"], function (exports, _component) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var _dec, _class;

  var Sort = (_dec = (0, _component.tagName)(""), _dec(_class = function (_EmberComponent) {
    _inherits(Sort, _EmberComponent);

    function Sort() {
      _classCallCheck(this, Sort);

      return _possibleConstructorReturn(this, (Sort.__proto__ || Object.getPrototypeOf(Sort)).apply(this, arguments));
    }

    _createClass(Sort, [{
      key: "performSort",
      value: function performSort(a, b, property) {
        var order = Ember.getWithDefault(this, "order", "").toLowerCase() === "desc" ? -1 : 1;

        var values = [Ember.getWithDefault(a, property), Ember.getWithDefault(b, property)];

        if (values[0] === undefined || values[1] === undefined || values[0] == values[1]) {
          return 0;
        }

        return values[0] > values[1] ? 1 * order : -1 * order;
      }
    }, {
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        if (!this.sort) {
          Ember.set(this, "sorted", this.model);
          return;
        }

        var subkeys = this.sort.split(".").slice(0, -1);
        var observed = this.rebuildIntermediateProperties(subkeys);
        var property = observed + ".@each." + this.sort.split(".").lastObject;

        var handler = Ember.computed(property, function () {
          var _this2 = this;

          return Ember.getWithDefault(this, "model", []).map(function (m) {
            return m;
          }).sort(function (a, b) {
            return _this2.performSort(a, b, _this2.sort);
          });
        });

        Ember.defineProperty(this, "sorted", handler);
        this.notifyPropertyChange("sorted");
      }
    }, {
      key: "rebuildIntermediateProperties",
      value: function rebuildIntermediateProperties(keys) {
        var _this3 = this;

        return keys.reduce(function (parent, key, index) {
          var property = "__sort" + index;
          var observed = parent + ".@each." + key;
          var handler = Ember.computed(observed, function () {
            return Ember.getWithDefault(this, parent, []).mapBy(key);
          });

          Ember.defineProperty(_this3, property, handler);
          _this3.notifyPropertyChange(property);

          return property;
        }, "model");
      }
    }]);

    return Sort;
  }(Ember.Component)) || _class);
  exports.default = Sort;
});