define("nimbus/pods/components/x-collapse/component", ["exports", "@ember-decorators/object", "@ember-decorators/component", "nimbus/util/css"], function (exports, _object, _component, _css) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = undefined;

  function _initDefineProp(target, property, descriptor, context) {
    if (!descriptor) return;
    Object.defineProperty(target, property, {
      enumerable: descriptor.enumerable,
      configurable: descriptor.configurable,
      writable: descriptor.writable,
      value: descriptor.initializer ? descriptor.initializer.call(context) : void 0
    });
  }

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) {
    var desc = {};
    Object['ke' + 'ys'](descriptor).forEach(function (key) {
      desc[key] = descriptor[key];
    });
    desc.enumerable = !!desc.enumerable;
    desc.configurable = !!desc.configurable;

    if ('value' in desc || desc.initializer) {
      desc.writable = true;
    }

    desc = decorators.slice().reverse().reduce(function (desc, decorator) {
      return decorator(target, property, desc) || desc;
    }, desc);

    if (context && desc.initializer !== void 0) {
      desc.value = desc.initializer ? desc.initializer.call(context) : void 0;
      desc.initializer = undefined;
    }

    if (desc.initializer === void 0) {
      Object['define' + 'Property'](target, property, desc);
      desc = null;
    }

    return desc;
  }

  function _initializerWarningHelper(descriptor, context) {
    throw new Error('Decorating class property failed. Please ensure that transform-class-properties is enabled.');
  }

  var _dec, _dec2, _dec3, _dec4, _desc, _value, _class, _descriptor;

  var Collapse = (_dec = (0, _component.className)("is-collapsed", "is-open"), _dec2 = (0, _component.attribute)("style"), _dec3 = (0, _object.computed)("isOpen", "innerHeight"), _dec4 = (0, _object.computed)("alwaysRenderChildren", "isOpen", "isCollapsed"), (_class = function (_EmberComponent) {
    _inherits(Collapse, _EmberComponent);

    _createClass(Collapse, [{
      key: "style",
      get: function get() {
        return (0, _css.default)({
          height: this.isOpen ? this.innerHeight + "px" : 0
        });
      }
    }, {
      key: "shouldRenderChildren",
      get: function get() {
        if (this.alwaysRenderChildren) {
          return true;
        }

        return this.isOpen || !this.isCollapsed;
      }
    }]);

    /**
     * Constructor.
     */
    function Collapse() {
      _classCallCheck(this, Collapse);

      var _this = _possibleConstructorReturn(this, (Collapse.__proto__ || Object.getPrototypeOf(Collapse)).apply(this, arguments));

      _this.innerHeight = 0;

      _initDefineProp(_this, "isCollapsed", _descriptor, _this);

      return _this;
    }

    /**
     * @inheritdoc
     */


    _createClass(Collapse, [{
      key: "didRender",
      value: function didRender() {
        var inner = this.element.querySelector("#" + this.elementId + "__inner");

        if (!this.isCollapsed && inner !== undefined) {
          Ember.set(this, "innerHeight", inner.scrollHeight);
        }
      }
    }, {
      key: "didReceiveAttrs",
      value: function didReceiveAttrs() {
        var _this2 = this;

        var didOpen = this.isCollapsed === true && this.isOpen === true;
        var didClose = this.isCollapsed === false && this.isOpen === false;

        Ember.run(function () {
          return Ember.set(_this2, "isCollapsed", !_this2.isOpen);
        });

        if (didOpen && this.onOpen) {
          this.onOpen();
        } else if (didClose && this.onClose) {
          this.onClose();
        }
      }
    }]);

    return Collapse;
  }(Ember.Component), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "isCollapsed", [_dec], {
    enumerable: true,
    initializer: function initializer() {
      return false;
    }
  }), _applyDecoratedDescriptor(_class.prototype, "style", [_dec2, _dec3], Object.getOwnPropertyDescriptor(_class.prototype, "style"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "shouldRenderChildren", [_dec4], Object.getOwnPropertyDescriptor(_class.prototype, "shouldRenderChildren"), _class.prototype)), _class));
  exports.default = Collapse;
});