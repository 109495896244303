define("nimbus/models/core/team", ["exports", "ember-data/attr", "ember-data/relationships", "nimbus/models/model"], function (exports, _attr, _relationships, _model) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _model.default.extend({
    /**
     * The name of the team.
     *
     * @type {String}
     */
    name: (0, _attr.default)("string"),

    /**
     * The URL of the logo of the team.
     *
     * @type {String}
     */
    logo: (0, _attr.default)("string"),

    /**
     * The domain of the team.
     *
     * @type {String}
     */
    domain: (0, _attr.default)("string"),

    /**
     * Can users login to this team.
     *
     * @type {Boolean}
     */
    isUnlocked: (0, _attr.default)("boolean"),

    /**
     * Time in hours for auto approve page messages
     *
     * @type {Numeric}
     */
    autoApproveThreshold: (0, _attr.default)("number"),

    /**
     * The theme used by the team.
     *
     * @type {module:app/models/core/team-theme}
     */
    theme: (0, _relationships.belongsTo)("core/team-theme", { async: false })
  });
});